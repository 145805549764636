<template>
    <div class="tooltip-metric">
        <div v-if="isActive" class="overlay" @click="isActive = !isActive"></div>
        <span class="icon cursor-pointer" @click="isActive = !isActive">
            <i class="em-icon info is-size-8"></i>
        </span>
        <div v-if="isActive" class="tooltip-container" :class="{ 'is-active': isActive }">
            <span class="icon is-small close-tooltip" @click="isActive = !isActive">
                <i class="em-icon cancel is-size-9"></i>
            </span>
            <slot name="tooltip-content">
                <p class="has-text-centered mb-4">
                    <b>{{ metricName }}</b>
                </p>
                <p class="mb-4">{{ description }}</p>
                <p class="has-text-centered">
                    <a :href="helpLink" class="button is-primary is-fullwidth" target="blank" rel="noopener">Learn More</a>
                </p>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MetricTooltip',
    props: {
        metricName: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        helpLink: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isActive: false
        };
    },
    beforeMount() {
        window.addEventListener('keydown', this.onKeyPress);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeyPress);
    },
    methods: {
        onKeyPress(e) {
            if (e.key === 'Escape') {
                this.isActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.overlay {
    position: fixed;
    background-color: rgba(41, 49, 68, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
}

.tooltip-metric {
    display: inline-block;
    position: relative;

    .tooltip-container {
        visibility: hidden;
        background-color: #fff;
        position: absolute;
        width: 300px;
        left: -138px;
        z-index: 31;
        padding: 2rem;
        border-radius: $radius;

        &.is-active {
            visibility: visible;
        }

        &::after {
            content: ' ';
            position: absolute;
            bottom: 100%; /* At the bottom of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #fff transparent;
        }

        .close-tooltip {
            top: 0.75rem;
            right: 0.75rem;
            position: absolute;
            cursor: pointer;
        }
    }
}
</style>
