<template>
    <div id="team-management" class="columns is-multiline">
        <div class="column is-full has-text-centered relative">
            <h2 class="title is-3">Team Management</h2>
            <router-link class="button is-primary is-medium add-members-btn" to="/settings/team-management/grow">
                <span class="icon">
                    <i class="em-icon user-add"></i>
                </span>
                <span>Add Members</span>
            </router-link>
        </div>
        <template v-if="team">
            <div class="column is-full">
                <h5 class="title is-5 has-text-weight-normal">Team Members ({{ team.members.length }})</h5>
                <div class="box">
                    <table v-if="team.members.length > 0" id="members-table" class="table is-hoverable is-fullwidth">
                        <tbody>
                            <tr v-for="member in team.members" :key="member.id">
                                <td class="has-text-weight-bold">{{ member.fullName }}</td>
                                <td>{{ member.email }}</td>
                                <td>
                                    <div class="is-flex">
                                        <template v-if="member.status === 'A'">
                                            <span class="icon">
                                                <i class="em-icon ok-circle has-text-success"></i>
                                            </span>
                                            Active member
                                        </template>
                                        <template v-else-if="member.status === 'I'">
                                            <span class="icon">
                                                <i class="em-icon error-circle has-text-danger"></i>
                                            </span>
                                            Lost access
                                        </template>
                                        <template v-else-if="member.status === 'L'">
                                            <span class="icon">
                                                <i class="em-icon clock has-text-grey-dark"></i>
                                            </span>
                                            Loading data
                                        </template>
                                        <template v-else-if="member.status === 'D'">
                                            <span class="icon">
                                                <i class="em-icon cancel-circle has-text-danger"></i>
                                            </span>
                                            Member removed ({{ member.expiresAt | formatDate }})
                                        </template>
                                    </div>
                                </td>
                                <td class="has-text-right">
                                    <a v-if="member.status !== 'D'" class="button is-transparent has-text-danger" @click="deleteMember(member)">
                                        <span class="icon is-small">
                                            <i class="em-icon trash"></i>
                                        </span>
                                        <span>Delete member</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else>You don't have any Team Members yet.</p>
                </div>
            </div>
            <div class="column is-full">
                <hr />
            </div>
            <div class="column is-full">
                <h5 class="title is-5 has-text-weight-normal">Invitations Pending ({{ pendingInvitations.length }})</h5>
                <div class="box">
                    <table v-if="pendingInvitations.length > 0" id="invitations-table" class="table is-hoverable is-fullwidth">
                        <tbody>
                            <tr v-for="invitation in pendingInvitations" :key="invitation.id">
                                <td class="has-text-weight-bold">{{ invitation.sentTo }}</td>
                                <td>
                                    <div class="is-flex">
                                        <template v-if="invitation.status === 'P'">
                                            <span class="icon">
                                                <i class="em-icon clock has-text-grey-dark"></i>
                                            </span>
                                            Invitation pending
                                        </template>
                                        <template v-else-if="invitation.status === 'R'">
                                            <span class="icon">
                                                <i class="em-icon error-circle has-text-danger"></i>
                                            </span>
                                            Invitation declined
                                        </template>
                                    </div>
                                </td>
                                <td class="has-text-right">
                                    <a class="button is-transparent has-text-primary mr-6" @click="resendInvitation(invitation.id)">
                                        <span class="icon">
                                            <i class="em-icon reload"></i>
                                        </span>
                                        <span>Resend invitation</span>
                                    </a>
                                    <a class="button is-transparent has-text-danger" @click="cancelInvitation(invitation)">
                                        <span class="icon">
                                            <i class="em-icon cancel"></i>
                                        </span>
                                        <span v-if="invitation.status === 'R'">Remove</span>
                                        <span v-else>Cancel invitation</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else>You don't have any Invitations yet.</p>
                </div>
            </div>
        </template>
        <div v-else class="is-flex flex-1 items-center justify-center">
            <spinner></spinner>
        </div>
    </div>
</template>

<script>
import TeamApi from '../../api/team_api';
import { INVITATION_STATUS } from '../../models/invitation';
import PAGE_NAMES from '../../router/pages';
import MODALS_ENUM from '../../utils/modals_enum';
import Spinner from '../base/spinner.vue';
import settingsHelper from './helpers/permissions';

export default {
    name: 'TeamManagement',
    beforeRouteEnter(to, from, next) {
        next(vm => {
            settingsHelper.canNavigateToTeamManagement(vm, next);
        });
    },
    beforeRouteUpdate(to, from, next) {
        settingsHelper.canNavigateToTeamManagement(this, next);
        next();
    },
    components: {
        Spinner
    },
    computed: {
        team() {
            return this.$store.getters['team/firstTeam'];
        },
        pendingInvitations() {
            return this.team.invitations.filter(i => i.status !== INVITATION_STATUS.accepted);
        }
    },
    methods: {
        goToAddMembers() {
            this.$router.push({
                name: PAGE_NAMES.settings,
                params: {
                    section: 'team-management',
                    subSection: 'grow'
                }
            });
        },
        deleteMember(member) {
            this.$bus.emit(this.$bus.events.showModal, MODALS_ENUM.teamDeleteMember, {
                teamMember: member,
                teamId: this.team.id
            });
        },
        resendInvitation(invitationId) {
            TeamApi.resendInvitation(invitationId)
                .then(() => {
                    this.$store.dispatch('team/fetchInvitations');
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: 'Sent! You’ve resent this message successfully.'
                    });
                })
                .catch(() => {
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
        },
        cancelInvitation(invitation) {
            this.$bus.emit(this.$bus.events.showModal, MODALS_ENUM.teamCancelInvitation, { invitation });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

.add-members-btn {
    @include desktop {
        position: absolute;
        right: 0;
        top: 0.5rem;
        margin-right: 0.75rem;
    }

    @include mobile {
        margin-top: 1rem;
    }
}

.table {
    th,
    td {
        border: unset;
        vertical-align: middle;

        @include mobile {
            display: flex;
            justify-content: center;
            padding: 0.3em 0.75em;
        }
    }

    @include mobile {
        tr:not(:last-child) {
            border-bottom: 2px solid $white-ter;

            td:last-child {
                margin-bottom: 0.7rem;
            }
        }
    }

    @include mobile {
        tr:not(:first-child) {
            td:first-child {
                margin-top: 0.7rem;
            }
        }
    }
}
</style>
