import Vue from 'vue';

import UserApi from '../api/user_api';
import { BATCH_KINDS } from '../models/batch';

export default {
    namespaced: true,
    state: {
        batches: []
    },
    getters: {
        onboardingBatch: state => {
            return state.batches.find(({ kind }) => kind === BATCH_KINDS.onboarding);
        },
        premiumBatch: state => {
            return state.batches.find(({ kind }) => [BATCH_KINDS.premium, BATCH_KINDS.premiumTeam].includes(kind));
        }
    },
    mutations: {
        setBatches(state, batches) {
            state.batches = batches;
        },
        updateBatch(state, batch) {
            const index = state.batches.findIndex(({ id }) => id === batch.id);
            Vue.set(state.batches, index, batch);
        }
    },
    actions: {
        fetchBatches(context) {
            return UserApi.getAllBatches().then(batches => {
                context.commit('setBatches', batches);
            });
        },
        pollBatchProgress(context, { kind, timelapse }) {
            return UserApi.getBatchProgress(kind, timelapse, data => {
                context.commit('updateBatch', data);
            });
        }
    }
};
