<template>
    <div class="is-flex-mobile flex-col items-center">
        <div class="is-flex justify-center">
            <img class="mb-6" :src="illustration" />
        </div>
        <h6 class="title is-6 mb-2">{{ title }}</h6>
        <p class="has-text-grey-darker">{{ body }}</p>
        <a class="has-text-primary is-flex" target="_blank" :href="knowMoreLink">
            Know more
            <span class="icon">
                <i class="em-icon chevron-right"></i>
            </span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        illustration: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        body: {
            type: String,
            required: true
        },
        knowMoreLink: {
            type: String,
            required: true
        }
    }
};
</script>
