<template>
    <base-filter id="date-filter" icon="em-icon date" header="DATE RANGE">
        <template #description>
            <p>
                Select any month between <b>{{ getMonthStart.format('MMM, YYYY') }}</b> and <b>{{ getMonthEnd.format('MMM, YYYY') }}</b
                >.
                <premium-cta></premium-cta>
            </p>
        </template>
        <template #buttons>
            <button class="button is-small" :class="{ 'is-primary': isLastMonthSelected, 'is-light': !isLastMonthSelected }" @click="selectLastMonth">Last month</button>
        </template>
        <template #filter>
            <datepicker
                ref="calendar"
                minimum-view="month"
                maximum-view="month"
                :language="translation"
                :inline="true"
                :use-utc="true"
                wrapper-class="calendar"
                calendar-class="monthly-calendar"
                :disabled-dates="{ customPredictor: disableDate }"
                @input="dateSelected"
            ></datepicker>
        </template>
    </base-filter>
</template>

<script>
import dayjs from 'dayjs';
import enLocale from 'dayjslib/locale/en-gb';
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex';

import dateHelpers from '../../utils/date_helpers';
import BaseFilter from './base_filter.vue';
import filtersMixin from './mixins/filters_mixin';
import PremiumCta from './premium_cta.vue';

export default {
    name: 'MonthlyFilter',
    components: {
        BaseFilter,
        Datepicker,
        PremiumCta
    },
    mixins: [filtersMixin],
    data() {
        return {
            translation: {
                months: enLocale.monthsShort,
                monthsAbbr: enLocale.monthsShort,
                days: enLocale.weekdays,
                yearSuffix: ''
            },
            currentSpan: {
                from: null,
                to: null
            },
            initialized: false
        };
    },
    computed: {
        ...mapState('user', ['user']),
        isLastMonthSelected() {
            const lastMonthSpan = dateHelpers.getPeriodSpan(this.getMonthEnd, 'month');
            return dayjs(this.currentSpan.from).unix() === lastMonthSpan.timestampFrom && dayjs(this.currentSpan.to).unix() === lastMonthSpan.timestampTo;
        },
        getMonthStart() {
            if (this.dataSpan.timestampFrom > this.dataSpan.timestampFrom.clone().startOf('month')) {
                return this.dataSpan.timestampFrom
                    .clone()
                    .add(1, 'month')
                    .startOf('month');
            } else {
                return this.dataSpan.timestampFrom.clone();
            }
        },
        getMonthEnd() {
            return dateHelpers.getLastPeriod(this.dataSpan.timestampTo.unix(), 'month');
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.changeMonth(
                dayjs
                    .unix(this.$store.getters['filter/currentPageFilters'].dateFilter.timestampFrom)
                    .utc()
                    .toDate()
            );
        });
    },
    methods: {
        selectLastMonth() {
            const selectedMonth = dateHelpers.getLastPeriod(this.dataSpan.timestampTo.unix(), 'month');
            this.changeMonth(selectedMonth);
        },
        disableDate(d) {
            return dayjs(d).isBefore(this.getMonthStart) || dayjs(d).isAfter(this.getMonthEnd);
        },
        dateSelected(d) {
            const selectedMonth = dateHelpers.getPeriodSpan(d, 'month');
            this.currentSpan = {
                from: new Date(selectedMonth.timestampFrom * 1000),
                to: new Date(selectedMonth.timestampTo * 1000)
            };
            if (this.initialized) this.emitToParent(this.currentSpan);
            else this.initialized = true;
        },
        changeMonth(d) {
            this.$refs.calendar.setDate(d);
        }
    }
};
</script>

<style lang="scss" scoped>
#date-filter::v-deep {
    .monthly-calendar {
        width: 100%;

        .cell {
            &.month {
                width: 16.667%;
            }
        }
    }
}
</style>
