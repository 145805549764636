<template>
    <div class="top-notification has-text-white is-flex justify-center items-center p-3 w-full has-background-primary">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="mr-4" v-html="text"></span>
        <a class="button is-small is-white  is-outlined" target="_blank" :href="link">{{ buttonText }}</a>
    </div>
</template>

<script>
export default {
    name: 'TopBarNotification',
    data() {
        return {
            text: "Secure. <b>Purpose-built for Enterprise.</b> Take data-driven decisions on your company's email",
            buttonText: 'Request a Demo',
            link: 'https://emailmeter.com/enterprise?utm_source=product&utm_medium=secure&utm_campaign=topbar'
        };
    }
};
</script>

<style lang="scss" scoped>
.top-notification {
    position: fixed;
    top: 0;
    z-index: 10;
}
</style>
