<template>
    <div v-if="typeof value === 'number'" class="previous-period-chip" :class="chipColor">
        <span class="chip-text">
            <i class="chip-arrow em-icon" :class="`${arrowColor} ${arrowValue}`"></i>
            {{ Math.abs(value) }} %
        </span>
    </div>
</template>

<script>
export default {
    name: 'PreviousPeriodChip',
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {
            required: true
        },
        inverseColor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        arrowColor() {
            if (this.value === 0) return 'has-background-grey-dark';
            return this.isGood ? 'has-background-success' : 'has-background-danger';
        },
        chipColor() {
            if (this.value === 0) return 'is-grey';
            return this.isGood > 0 ? 'is-success' : 'is-danger';
        },
        arrowValue() {
            if (this.value === 0) return 'same';
            return this.value > 0 ? 'arrow-up' : 'arrow-down';
        },
        isGood() {
            let res = this.value > 0;
            if (this.inverseColor) res = !res;
            return res;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.previous-period-chip {
    border-radius: 20px;
    font-size: 0.7rem;
    min-height: 28px;
    padding-right: 10px;
    color: rgba(0, 0, 0, 0.7);
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
}

.chip-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 10px;
    font-weight: bold;
}

.chip-arrow {
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    margin: 2px 0;
    -webkit-transform: translate(-8px);
    transform: translate(-8px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    color: $white;
}

.is-success {
    background-color: rgba($success, 0.2);
    color: $success;
}

.is-grey {
    background-color: rgba($grey-dark, 0.2);
    color: $grey-darker;
}

.is-danger {
    background-color: rgba($danger, 0.1);
    color: $danger;
}
</style>
