/* eslint-disable camelcase,no-unused-vars */

const FLAGS = {};

const SWITCHES = {
    'help_button': false,
    'monthly_report_notification': false,
    'delete_account_oauth': false,
    'good_news': false,
    'summary_comparison': false,
    'advanced_filters': false,
    'business_hours': false,
    'extra_advanced_filters': false,
    'referral_program': false,
    'subject_automated_filters': false,
    'top_navbar_active': false,
    'delegated_inboxes': false
};

const SAMPLES = {};

export default {
    flag_is_active(flag_name) {
        return FLAGS[flag_name];
    },
    switch_is_active(switch_name) {
        return SWITCHES[switch_name];
    },
    sample_is_active(sample_name) {
        return SAMPLES[sample_name];
    },
    'FLAGS': FLAGS,
    'SWITCHES': SWITCHES,
    'SAMPLES': SAMPLES
};
