<!-- eslint-disable max-lines -->
<template>
    <div class="columns filter">
        <div class="column">
            <div class="notification p-2" :class="{ 'is-primary-light': isValid, 'is-danger-light': !isValid }">
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="filter-select field is-narrow">
                            <div class="select is-small">
                                <select :value="selectedFilter" @input="updateFilter">
                                    <option :value="null" selected disabled hidden>Select...</option>
                                    <option v-for="(item, index) of filterList" :key="index" :value="item.key">{{ item.name }}</option>
                                </select>
                            </div>
                        </div>
                        <template v-if="Boolean(selectedFilter)">
                            <div v-if="showTarget" class="filter-target field is-narrow">
                                <div class="select is-small">
                                    <select v-model="selectedTarget">
                                        <option :value="null" selected disabled hidden>select...</option>
                                        <option v-for="(item, index) in targetOptions" :key="index" :value="item.key">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="showDisabledIsSelector" class="filter-exclusion field is-narrow">
                                <div class="select is-small">
                                    <select disabled>
                                        <option value="is">is</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="showExclusion" class="filter-exclusion field is-narrow">
                                <div class="select is-small">
                                    <select v-model="selectedExclusion">
                                        <option :value="null" selected disabled hidden>select...</option>
                                        <option v-for="(item, index) in excludedOptions" :key="index" :value="item.key">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <template v-if="showValue">
                                <div v-if="showSelectorInput" class="filter-select-value field is-fullwidth">
                                    <div class="select is-small">
                                        <select v-model="inputValues[0]">
                                            <option :value="undefined" selected disabled hidden>select...</option>
                                            <option v-for="(item, index) in valueOptions" :key="index" :value="item.key">{{ item.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-else class="filter-text-value field is-fullwidth">
                                    <vue-tags-input
                                        ref="tagsInput"
                                        v-model="currentTag"
                                        placeholder
                                        :tags="inputValues"
                                        :validation="inputTagValidation"
                                        :allow-edit-tags="true"
                                        @tags-changed="newTags => (inputValues = newTags)"
                                    ></vue-tags-input>
                                </div>
                            </template>
                        </template>
                        <div class="field is-flex items-center is-narrow ml-auto remove-btn" @click="$emit('remove')">
                            <a class="button is-small is-transparent has-text-primary">
                                <span class="icon is-small">
                                    <i class="em-icon cancel is-size-9"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createTags, VueTagsInput } from '@johmun/vue-tags-input';

import AdvancedFilters from '../../models/filters/advanced_filters';

let FILTER_KIND = {
    [AdvancedFilters.DomainFilter.id]: {
        filter: AdvancedFilters.DomainFilter,
        name: 'Domain'
    },
    [AdvancedFilters.AddressFilter.id]: {
        filter: AdvancedFilters.AddressFilter,
        name: 'Address'
    }
};

const PRE_RELEASE_FILTER_KIND = {
    [AdvancedFilters.IntradomainFilter.id]: {
        filter: AdvancedFilters.IntradomainFilter,
        name: 'Intradomain'
    },
    [AdvancedFilters.LabelsFilter.id]: {
        filter: AdvancedFilters.LabelsFilter,
        name: 'Label'
    },
    [AdvancedFilters.StatusFilter.id]: {
        filter: AdvancedFilters.StatusFilter,
        name: 'Status'
    },
    [AdvancedFilters.GmailTabFilter.id]: {
        filter: AdvancedFilters.GmailTabFilter,
        name: 'Gmail Tab'
    }
};

const SUBJECT_AUTOMATED_FILTERS = {
    [AdvancedFilters.AutomatedFilter.id]: {
        filter: AdvancedFilters.AutomatedFilter,
        name: 'Automated'
    },
    [AdvancedFilters.SubjectFilter.id]: {
        filter: AdvancedFilters.SubjectFilter,
        name: 'Subject'
    }
};

export default {
    name: 'AdvancedFilterItem',
    components: { VueTagsInput },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        filter: {
            required: true
        },
        isValid: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentTag: '', // Unused but needed variable
            selectedFilter: null,
            selectedTarget: null,
            selectedExclusion: null,
            inputValues: []
        };
    },
    computed: {
        inputTagValidation() {
            return [
                {
                    classes: 'is-valid',
                    rule: ({ text }) => {
                        if (['is', '-is'].includes(this.selectedExclusion)) {
                            return !this.filterKind.filter.validator(text);
                        } else {
                            return false;
                        }
                    }
                }
            ];
        },
        selectedValue() {
            return this.inputValues.map(t => (t.text ? t.text : t));
        },
        showTarget() {
            return this.filterKind.filter.prototype instanceof AdvancedFilters.TargetedAdvancedFilter;
        },
        showExclusion() {
            const showExclusionAfterTarget = this.showTarget && this.selectedTarget;
            const showExclusionWithoutTarget = !this.showTarget && this.filterKind.filter.prototype instanceof AdvancedFilters.ExclusiveAdvancedFilter;
            return showExclusionAfterTarget || showExclusionWithoutTarget;
        },
        showValue() {
            if (Object.getPrototypeOf(this.filterKind.filter) === AdvancedFilters.ExclusiveAdvancedFilter) {
                return this.selectedExclusion !== null;
            } else {
                return (this.showTarget && this.selectedExclusion !== null) || Object.getPrototypeOf(this.filterKind.filter) === AdvancedFilters.AdvancedFilter;
            }
        },
        showSelectorInput() {
            return (this.valueOptions && this.selectedExclusion !== null) || Object.getPrototypeOf(this.filterKind.filter) === AdvancedFilters.AdvancedFilter;
        },
        showDisabledIsSelector() {
            return Object.getPrototypeOf(this.filterKind.filter) === AdvancedFilters.AdvancedFilter;
        },
        excludedOptions() {
            return this._processOptions(this.filterKind.filter.excludedOptions);
        },
        targetOptions() {
            return this._processOptions(this.filterKind.filter.targetOptions);
        },
        valueOptions() {
            return this._processOptions(this.filterKind.filter.valueOptions);
        },
        filterKind() {
            return FILTER_KIND[this.selectedFilter];
        },
        filterList() {
            return Object.keys(FILTER_KIND).map(key => {
                return {
                    key: key,
                    name: FILTER_KIND[key].name
                };
            });
        },
        filterData() {
            return {
                value: this.selectedValue,
                excluded: this.selectedExclusion,
                target: this.selectedTarget,
                name: this.selectedFilter
            };
        }
    },
    watch: {
        filterData: {
            deep: true,
            handler(newVal) {
                this.$emit('update', new this.filterKind.filter(newVal)); // eslint-disable-line new-cap
            }
        }
    },
    beforeMount() {
        if (this.$waffle.switch_is_active('extra_advanced_filters')) {
            FILTER_KIND = Object.assign({}, FILTER_KIND, PRE_RELEASE_FILTER_KIND);
        }
        if (this.$waffle.switch_is_active('subject_automated_filters')) {
            FILTER_KIND = Object.assign({}, FILTER_KIND, SUBJECT_AUTOMATED_FILTERS);
        }
        if (this.filter) {
            this.selectedFilter = this.filter.name;
            this.selectedTarget = this.filter.target;
            this.selectedExclusion = this.filter.excluded;
            if (!this.showSelectorInput) this.inputValues = createTags(this.filter.value);
            else if (this.showSelectorInput) this.inputValues = this.filter.value.slice(0);
        }
    },
    mounted() {
        if (this.filter && this.$refs.tagsInput) {
            this.$refs.tagsInput.$refs.newTagInput.focus();
        }
    },
    methods: {
        updateFilter(newFilter) {
            this.selectedFilter = newFilter.srcElement.value;
            this.resetFilterFields();
        },
        resetFilterFields() {
            this.selectedTarget = null;
            this.selectedExclusion = null;
            this.inputValues = [];
        },
        _processOptions(options) {
            if (!options) return null;
            return Object.keys(options).map(k => {
                return {
                    key: k,
                    name: options[k]
                };
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.select select {
    width: 130px;
    background-color: #fff;
    border-color: #fff;
}

.input {
    background-color: #fff;
}

.filter {
    margin: -0.25rem -0.25rem 0.25rem -0.25rem;

    .column {
        padding: 0.25rem;
    }

    .field:not(:last-child) {
        margin-bottom: 0;

        @include touch {
            margin-bottom: 0.25rem;
        }
    }

    .delete {
        background-color: transparent;

        &::after,
        &::before {
            background-color: $primary;
        }
    }
}
</style>

<style lang="scss" scoped>
@import '../../styles/variables';

.filter-text-value::v-deep .vue-tags-input {
    border-radius: $radius;

    & > .ti-input {
        border: $radius;
        min-height: 32px;
        height: auto;

        & > ul.ti-tags {
            & > .ti-tag.ti-valid {
                background-color: $primary;
            }

            & > .ti-tag.ti-invalid {
                background-color: $danger;
            }

            & > .ti-tag.ti-deletion-mark {
                background-color: $danger;
            }
        }
    }
}
</style>
