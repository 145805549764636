<template>
    <div class="level">
        <div class="level-item">
            <div class="w-full">
                <div class="is-flex items-center mb-4">
                    <span class="icon mr-2">
                        <i class="is-size-5" :class="icon"></i>
                    </span>
                    <h6 class="title is-6">{{ title }}</h6>
                </div>
                <p class="subtitle is-7 has-text-grey-darker">{{ subtitle }}</p>
            </div>
        </div>
        <div class="level-item">
            <slot name="field"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LevelSetting',
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

.level.is-mobile {
    .level-item:nth-child(1) {
        @include touch {
            flex-shrink: 1;
        }
    }

    .level-item:nth-child(2) {
        justify-content: flex-end;
    }
}
</style>
