<template>
    <div id="time-before-first-response">
        <apexchart type="bar" width="100%" height="300px" :options="chartOptions" :series="chartData"></apexchart>
    </div>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { BAR_CHART_OPTIONS } from './chart_options';

export default {
    name: 'TimeBeforeFirstResponse',
    mixins: [ReportMetricMixin],
    computed: {
        timeBeforeFirstResponse() {
            return this.componentMetrics.timeBeforeFirstResponse.value;
        },
        chartOptions() {
            return Object.assign({}, BAR_CHART_OPTIONS, {
                colors: this.chartColors,
                xaxis: { categories: Object.keys(this.timeBeforeFirstResponse) }
            });
        },
        chartData() {
            return [
                {
                    name: 'First Responses',
                    data: Object.values(this.timeBeforeFirstResponse)
                }
            ];
        }
    }
};
</script>
