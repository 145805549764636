import querystring from 'querystring';

import utils from '../../utils/utils';

export default class Filter {
    constructor(value) {
        this.name = this.constructor.id; // static id defined filter name
        this.value = value;
    }

    clone() {
        const cls = Object.getPrototypeOf(this);
        const properties = Object.getOwnPropertyNames(this);
        const filteredProperties = properties.filter(k => k !== '__ob__').reduce((acc, k) => Object.assign({}, acc, { [k]: this[k] }), {});
        return new cls.constructor(filteredProperties);
    }

    isValid() {
        return true;
    }

    toObject() {
        return {
            [this.name]: this.value
        };
    }

    serialize() {
        return this._serializeObject(this.toObject());
    }

    bqiSerialize() {
        return this.serialize();
    }

    parse(value) {
        this.value = this._parseValue(value);
    }

    _parseValue(value) {
        const values = value.split(',');
        return values;
    }

    _serializeObject(obj) {
        const filteredObject = Object.keys(obj).reduce((acc, k) => {
            if (obj[k] !== undefined && obj[k] !== null && obj[k] !== '') {
                let val = obj[k];
                if (Array.isArray(val)) {
                    if (val.length === 0) return acc;
                    val = val.join(',');
                }
                acc[k] = val;
            }
            return acc;
        }, {});
        return querystring.stringify(filteredObject);
    }

    getHash() {
        return utils.b64EncodeUnicode(JSON.stringify(this));
    }
}
