<template>
    <base-heatmap id="sent-heatmap" type="sent" :color="greenColors" :required-metrics="requiredMetrics" :insight-text="insightText"></base-heatmap>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import BaseHeatmap from './base_heatmap.vue';

export default {
    name: 'SentHeatmap',
    components: {
        BaseHeatmap
    },
    mixins: [ReportMetricMixin],
    methods: {
        insightText(hour, weekday) {
            return `Your productivity peak happens around ${hour} on ${weekday}s.`;
        }
    }
};
</script>
