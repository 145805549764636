<template>
    <transition name="fade">
        <div v-if="display" class="modal is-active" :class="{ 'is-large': modalData.isLarge }">
            <div class="modal-background" @click="display = false"></div>
            <div class="modal-content">
                <div class="box">
                    <button class="modal-close is-large" aria-label="close" @click="display = false"></button>
                    <extension-modal v-if="activeModal === MODALS_ENUM.chromeExtension" :modal-data="modalData"></extension-modal>
                    <account-delete-confim v-if="activeModal === MODALS_ENUM.accountDeleteConfirm" :modal-data="modalData"></account-delete-confim>
                    <enterprise-bait v-if="activeModal === MODALS_ENUM.enterpriseBait" :modal-data="modalData"></enterprise-bait>
                    <edit-billing-info v-if="activeModal === MODALS_ENUM.editBillingInfo" :modal-data="modalData"></edit-billing-info>
                    <edit-payment-method v-if="activeModal === MODALS_ENUM.editPaymentMethod" :modal-data="modalData"></edit-payment-method>
                    <reactivate-plan v-if="activeModal === MODALS_ENUM.reactivatePlan" :modal-data="modalData"></reactivate-plan>

                    <team-cancel-invitation v-if="activeModal === MODALS_ENUM.teamCancelInvitation" :modal-data="modalData"></team-cancel-invitation>
                    <team-confirm-invitations v-if="activeModal === MODALS_ENUM.teamConfirmInvitations" :modal-data="modalData"></team-confirm-invitations>
                    <team-delete-member v-if="activeModal === MODALS_ENUM.teamDeleteMember" :modal-data="modalData"></team-delete-member>
                    <team-user-invitation v-if="activeModal === MODALS_ENUM.teamUserInvitation" :modal-data="modalData"></team-user-invitation>

                    <paywall-hub v-if="activeModal === MODALS_ENUM.paywall" :modal-data="modalData"></paywall-hub>

                    <referral-invitations v-if="activeModal === MODALS_ENUM.referralInvitations" :modal-data="modalData"></referral-invitations>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import MODALS_ENUM from '../../utils/modals_enum';
import AccountDeleteConfim from '../modals/delete_account_confirm.vue';
import EditBillingInfo from '../modals/edit_billing_info.vue';
import EditPaymentMethod from '../modals/edit_payment_method.vue';
import EnterpriseBait from '../modals/enterprise_bait.vue';
import ExtensionModal from '../modals/extension_modal.vue';
import PaywallHub from '../modals/paywall_hub.vue';
import ReactivatePlan from '../modals/reactivate_plan.vue';
import ReferralInvitations from '../modals/referral_invitations.vue';
import TeamCancelInvitation from '../modals/team/cancel_invitation.vue';
import TeamConfirmInvitations from '../modals/team/confirm_team_invitations.vue';
import TeamDeleteMember from '../modals/team/delete_member.vue';
import TeamUserInvitation from '../modals/team/user_invitation.vue';

export default {
    name: 'Modals',
    components: {
        AccountDeleteConfim,
        EditBillingInfo,
        EditPaymentMethod,
        EnterpriseBait,
        PaywallHub,
        ReactivatePlan,
        ReferralInvitations,
        TeamCancelInvitation,
        TeamConfirmInvitations,
        TeamDeleteMember,
        TeamUserInvitation,
        ExtensionModal
    },
    data() {
        return {
            display: false,
            activeModal: undefined,
            MODALS_ENUM: MODALS_ENUM,
            modalData: {}
        };
    },
    beforeMount() {
        this.$bus.on(this.$bus.events.showModal, this.openModal);
        this.$bus.on(this.$bus.events.closeModal, this.closeModal);
        window.addEventListener('keydown', this.onKeyPress);
    },
    beforeDestroy() {
        this.$bus.off(this.$bus.events.showModal, this.openModal);
        this.$bus.off(this.$bus.events.closeModal, this.closeModal);
        window.removeEventListener('keydown', this.onKeyPress);
    },
    methods: {
        openModal(modal, modalData) {
            if (modalData) this.modalData = modalData;
            this.activeModal = modal;
            this.display = true;
        },
        closeModal() {
            this.modalData = {};
            this.display = false;
            this.activeModal = null;
        },
        onKeyPress(e) {
            if (e.key === 'Escape') {
                this.closeModal();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

.modal {
    &.is-large > .modal-content .box {
        padding-top: 3.75rem;
        padding-bottom: 2.5rem;
    }

    .modal-content {
        .box {
            padding: 3.75rem;

            .modal-close {
                position: absolute;

                &::before,
                &::after {
                    background-color: rgba(0, 0, 0, 0.55);
                }
            }
        }
        @include mobile {
            .box {
                margin: 0 10px;
            }
        }
    }
}
</style>

<style lang="scss">
// We will apply these styles to every modal, shared styles will live here to avoid duplication
.modal-image {
    display: flex;
    margin: 1rem auto;
}

.modal-action-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    a,
    button {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}
</style>
