<template>
    <multi-form :forms="questions" @form-dismissed="onFormDismissed" @form-selected-choice="onFormSelectedChoice">
        <template v-slot:header>
            <h1 class="title is-3 has-text-white mb-2">Welcome to Email Meter!</h1>
            <p class="has-text-white">We’re preparing your first report — this will take a few minutes.</p>
        </template>
        <template v-slot:back-button-text>PREVIOUS QUESTION</template>
        <template v-slot:next-button-text>SKIP THIS QUESTION</template>
        <template v-slot:hint-footer>
            <p class="has-text-weight-bold">We're nearly finished</p>
            <p class="is-size-7">Email Meter is now collecting your email stats.</p>
            <progress v-if="onboardingBatch" class="progress is-small is-primary" :value="onboardingBatch.progress" max="100">{{ onboardingBatch.progress }}</progress>
        </template>
    </multi-form>
</template>

<script>
import { mapGetters } from 'vuex';

import UserApi from '../../api/user_api';
import MultiForm from '../onboarding/multi_form.vue';
import { questions } from './form_data';

export default {
    name: 'OnboardingForm',
    components: { MultiForm },
    data() {
        return { questions };
    },
    computed: {
        ...mapGetters('batch', ['onboardingBatch'])
    },
    methods: {
        onFormDismissed() {
            this.$emit('form-dismissed');
        },
        onFormSelectedChoice(numQuestion, choiceValue) {
            const data = {};
            data[this.questions[numQuestion].field] = choiceValue;
            UserApi.updateOnboardingProfile(data).catch(() => undefined);
        }
    }
};
</script>
