<template>
    <div class="box is-child flex-1">
        <div class="level items-stretch">
            <div class="level-item">
                <i class="em-icon email is-size-4 has-text-metric-sent"></i>
                <div>
                    <span>{{ METRICS_SETUP[MetricType.totalEmailsSent].metricName }}</span>
                    <metric-tooltip v-bind="METRICS_SETUP[MetricType.totalEmailsSent]"></metric-tooltip>
                </div>
                <span v-if="totalEmailsSent.status !== METRIC_STATUS.LOADING" class="metric">{{ totalEmailsSent.value }}</span>
                <div v-else class="spinner-wrapper is-flex flex-1 items-center justify-center">
                    <spinner></spinner>
                </div>
                <previous-period-chip v-if="isSummaryDiffLoaded" :value="summaryDiff[MetricType.totalEmailsSent]"></previous-period-chip>
            </div>
            <div class="level-item">
                <i class="em-icon user is-size-4 has-text-metric-sent"></i>
                <div>
                    <span>{{ METRICS_SETUP[MetricType.totalUniqueRecipients].metricName }}</span>
                    <metric-tooltip v-bind="METRICS_SETUP[MetricType.totalUniqueRecipients]"></metric-tooltip>
                </div>
                <span v-if="totalUniqueRecipients.status !== METRIC_STATUS.LOADING" class="metric">{{ totalUniqueRecipients.value }}</span>
                <div v-else class="spinner-wrapper is-flex flex-1 items-center justify-center">
                    <spinner></spinner>
                </div>
                <previous-period-chip v-if="isSummaryDiffLoaded" :value="summaryDiff[MetricType.totalUniqueRecipients]"></previous-period-chip>
            </div>
            <div v-if="shouldShowAverageResponseTime" class="level-item">
                <i class="em-icon response-time is-size-4 has-text-metric-sent"></i>
                <div>
                    <span>{{ METRICS_SETUP[MetricType.averageResponseTime].metricName }}</span>
                    <metric-tooltip v-bind="METRICS_SETUP[MetricType.averageResponseTime]"></metric-tooltip>
                </div>
                <time-display v-if="avgResponseTime.status === METRIC_STATUS.LOADED" class="my-2" :seconds="avgResponseTime.value === null ? 0 : avgResponseTime.value"></time-display>
                <div v-else-if="avgResponseTime.status === METRIC_STATUS.LOADING" class="spinner-wrapper is-flex flex-1 items-center justify-center">
                    <spinner></spinner>
                </div>
                <previous-period-chip v-if="isSummaryDiffLoaded" :value="summaryDiff[MetricType.averageResponseTime]" :inverse-color="true"></previous-period-chip>
            </div>
            <div class="level-item">
                <i class="em-icon email is-size-4 has-text-metric-received"></i>
                <div>
                    <span>{{ METRICS_SETUP[MetricType.totalEmailsReceived].metricName }}</span>
                    <metric-tooltip v-bind="METRICS_SETUP[MetricType.totalEmailsReceived]"></metric-tooltip>
                </div>
                <span v-if="totalEmailsReceived.status !== METRIC_STATUS.LOADING" class="metric">{{ totalEmailsReceived.value }}</span>
                <div v-else class="spinner-wrapper is-flex flex-1 items-center justify-center">
                    <spinner></spinner>
                </div>
                <previous-period-chip v-if="isSummaryDiffLoaded" :value="summaryDiff[MetricType.totalEmailsReceived]"></previous-period-chip>
            </div>
            <div class="level-item">
                <i class="em-icon user is-size-4 has-text-metric-received"></i>
                <div>
                    <span>{{ METRICS_SETUP[MetricType.totalUniqueSenders].metricName }}</span>
                    <metric-tooltip v-bind="METRICS_SETUP[MetricType.totalUniqueSenders]"></metric-tooltip>
                </div>
                <span v-if="totalUniqueSenders.status !== METRIC_STATUS.LOADING" class="metric">{{ totalUniqueSenders.value }}</span>
                <div v-else class="spinner-wrapper is-flex flex-1 items-center justify-center">
                    <spinner></spinner>
                </div>
                <previous-period-chip v-if="isSummaryDiffLoaded" :value="summaryDiff[MetricType.totalUniqueSenders]"></previous-period-chip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { STATUS } from '../../../api/parsers/bqi_parser';
import PreviousPeriodChip from '../../base/previous_period_chip.vue';
import Spinner from '../../base/spinner.vue';
import ReportMetricMixin from '../mixins/report_metric_mixin';
import MetricTooltip from './metric_tooltip.vue';
import TimeDisplay from './time_display.vue';

export default {
    name: 'ReportSummary',
    components: {
        MetricTooltip,
        Spinner,
        PreviousPeriodChip,
        TimeDisplay
    },
    mixins: [ReportMetricMixin],
    data() {
        return {
            METRIC_STATUS: STATUS
        };
    },
    computed: {
        ...mapGetters('filter', ['summaryDiff']),
        totalEmailsSent() {
            return this.metrics[this.MetricType.totalEmailsSent];
        },
        totalUniqueRecipients() {
            return this.metrics[this.MetricType.totalUniqueRecipients];
        },
        avgResponseTime() {
            return this.metrics[this.MetricType.averageResponseTime];
        },
        totalEmailsReceived() {
            return this.metrics[this.MetricType.totalEmailsReceived];
        },
        totalUniqueSenders() {
            return this.metrics[this.MetricType.totalUniqueSenders];
        },
        isSummaryDiffLoaded() {
            return this.$waffle.switch_is_active('summary_comparison') && this.summaryDiff;
        },
        shouldShowAverageResponseTime() {
            return this.avgResponseTime.status !== STATUS.ERROR || (this.avgResponseTime.status === STATUS.LOADED && this.avgResponseTime.value !== null);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/mixins';
@import '../../../styles/variables';

.level-item {
    flex-direction: column;

    &:not(:last-child) {
        @include mobile {
            padding-bottom: 0.75rem;
            border-bottom: 1px solid #eeeeee;
        }

        @include tablet {
            border-right: 1px solid #eeeeee;
        }
    }
}

.metric {
    font-size: 2rem;
    font-weight: bold;
    color: #444444;
    margin: auto;
    display: inline-block;
}

.spinner {
    width: 2em;
    height: 2em;
}

.spinner-wrapper {
    padding-top: 0.7em;
}
</style>
