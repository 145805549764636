<template>
    <div class="columns is-multiline">
        <div class="column is-full has-text-centered">
            <h2 class="title is-3">{{ title }}</h2>
            <h3>{{ subtitle }}</h3>
        </div>
        <div class="column is-full">
            <div v-for="invitation in modalData.modalContent" :key="invitation.email" class="level has-separator mb-3 pb-3">
                <div class="level-left">
                    <div class="level-item">
                        <div class="w-full">
                            <span>{{ invitation.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item justify-end">
                        <div class="tag is-medium px-4 py-6 sm:w-full" :class="buttonColor[consumedKey(invitation)]">
                            <span class="icon is-size-8 mr-2">
                                <i class="em-icon" :class="buttonIcon[consumedKey(invitation)]"></i>
                            </span>
                            {{ buttonText[consumedKey(invitation)] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'ReferralInvitationsModal',
    mixins: [ModalMixin],
    data() {
        return {
            disabledResendButtons: {},
            title: {
                acceptedInvitations: 'Accepted invitations',
                pendingInvitations: 'Pending invitations',
                acceptedPurchases: 'Purchased Premium'
            }[this.modalData.CONTENT_TYPE],
            subtitle: {
                acceptedInvitations: 'Friends that signed up through your invitation or referral link.',
                pendingInvitations: "Friends that have received your referral link or invitation but haven't signed up yet.",
                acceptedPurchases: 'Friends that signed up using your invitation or referral link and later purchased Premium.'
            }[this.modalData.CONTENT_TYPE],
            buttonText: {
                acceptedInvitations: { notConsumed: 'Signed up to Email Meter', consumed: 'Premium reward already used' },
                pendingInvitations: { notConsumed: 'Waiting for them to sign up to Email Meter' },
                acceptedPurchases: { notConsumed: 'Purchased Email Meter Premium', consumed: 'Premium reward already used' }
            }[this.modalData.CONTENT_TYPE],
            buttonIcon: {
                acceptedInvitations: { notConsumed: 'star-filled has-text-success', consumed: 'ok-circle' },
                pendingInvitations: { notConsumed: 'response-time has-text-grey-dark' },
                acceptedPurchases: { notConsumed: 'star-filled has-text-success', consumed: 'ok-circle' }
            }[this.modalData.CONTENT_TYPE],
            buttonColor: {
                acceptedInvitations: { notConsumed: 'has-background-green-light has-text-success', consumed: 'is-green' },
                pendingInvitations: { notConsumed: 'is-grey-light has-text-grey-darker' },
                acceptedPurchases: { notConsumed: 'has-background-green-light has-text-success', consumed: 'is-green' }
            }[this.modalData.CONTENT_TYPE],
            showResend: {
                acceptedInvitations: false,
                pendingInvitations: true,
                acceptedPurchases: false
            }[this.modalData.CONTENT_TYPE]
        };
    },
    methods: {
        resend(email) {
            if (this.disabledResendButtons[email]) return;
            this.$set(this.disabledResendButtons, email, true);
            this.$bus.emit(this.$bus.events.resendReferralEmail, email);
        },
        consumedKey(invitation) {
            return invitation.consumed ? 'consumed' : 'notConsumed';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

@include mobile {
    .level-left + .level-right {
        margin-top: 0.75rem;
    }
}

.has-separator:not(:last-child) {
    border-bottom: 1px solid $grey-light;
}

.has-background-green-light {
    background-color: rgba($success, 0.2);
}

.resend-disabled {
    color: $grey;
    cursor: not-allowed;
    * {
        pointer-events: none;
    }
}
</style>
