import { STRIPE_PRICING } from '../../../models/billing';
import MODALS_ENUM from '../../../utils/modals_enum';
import AddMembersInput from '../add_members_input.vue';

export default {
    components: {
        AddMembersInput
    },
    data() {
        return {
            emails: [],
            STRIPE_PRICING: STRIPE_PRICING
        };
    },
    computed: {
        isContinueEnabled() {
            return this.emails.length > 0;
        }
    },
    methods: {
        onListChange(emails) {
            this.emails = emails;
        },
        showConfirmModal() {
            this.$bus.emit(this.$bus.events.showModal, MODALS_ENUM.teamConfirmInvitations, { emails: this.emails });
        }
    }
};
