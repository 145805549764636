import VueRouter from 'vue-router';

import GenericPaywall from '../components/paywall/generic_paywall.vue';
import SettingsGeneral from '../components/settings/general.vue';
import SettingsTeamManagementGrow from '../components/settings/team_grow.vue';
import SettingsTeamManagement from '../components/settings/team_management.vue';
import Account from '../pages/account.vue';
import Cancelled from '../pages/cancelled.vue';
import Checkout from '../pages/checkout.vue';
import ComingSoon from '../pages/coming_soon.vue';
import ReferralMain from '../pages/referral_main.vue';
import Report from '../pages/report.vue';
import Settings from '../pages/settings.vue';
import Team from '../pages/team.vue';
import PAGE_NAMES from './pages';

// Patch for NavigationDuplicated error
// see https://stackoverflow.com/a/58439497/933059
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (!err || err.name !== 'NavigationDuplicated') {
            throw err;
        }
    });
};

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: {
                name: 'report',
                params: {
                    section: 'monthly'
                }
            }
        },
        {
            path: '/report/:section',
            name: PAGE_NAMES.report,
            component: Report
        },
        {
            path: '/get-premium/',
            redirect: {
                name: PAGE_NAMES.getPremium,
                params: {
                    step: 'select-product'
                }
            }
        },
        {
            path: '/get-premium/paywall',
            name: PAGE_NAMES.genericPaywall,
            component: GenericPaywall
        },
        {
            path: '/get-premium/:step?',
            name: PAGE_NAMES.getPremium,
            component: Checkout
        },
        {
            path: '/referral',
            name: PAGE_NAMES.referral,
            component: ReferralMain
        },
        {
            path: '/settings',
            redirect: '/settings/general',
            component: Settings,
            children: [
                {
                    path: 'general',
                    component: SettingsGeneral
                },
                {
                    path: 'team-management',
                    component: SettingsTeamManagement
                },
                {
                    path: 'team-management/grow',
                    component: SettingsTeamManagementGrow
                }
            ]
        },
        {
            path: '/account',
            name: PAGE_NAMES.account,
            component: Account
        },
        {
            path: '/coming-soon',
            name: PAGE_NAMES.comingSoon,
            component: ComingSoon
        },
        {
            path: '/cancelled',
            name: PAGE_NAMES.cancelled,
            component: Cancelled
        },
        {
            path: '/team/:section?',
            name: PAGE_NAMES.team,
            component: Team
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    }
});
