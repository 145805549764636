<template>
    <div v-if="showInviteFriends" id="invite-friends-widget" class="invite-friends">
        <div class="referral-dropdown-trigger" @click="dropDownActive = !dropDownActive">
            <div class="referral-dropdown-title">
                Invite friends that haven't discovered Email Meter yet and get Premium free
            </div>
            <div class="referral-dropdown-chevron">
                <span class="icon is-small">
                    <i v-if="!dropDownActive" class="em-icon chevron-down" aria-hidden="true"></i>
                    <i v-else class="em-icon chevron-up" aria-hidden="true"></i>
                </span>
            </div>
        </div>
        <div id="dropdown-referral" class="referral-dropdown-menu">
            <transition name="fade" tag="div">
                <div v-if="dropDownActive" id="suggestions-box" class="referral-dropdown-content">
                    <suggestion
                        v-for="(suggestion, index) in fiveOwnSuggestions"
                        :key="suggestion.empty ? 'empty' : suggestion.email"
                        :is-small="true"
                        :empty="suggestion.empty"
                        :name="suggestion.name"
                        :email="suggestion.email"
                        @send-invitation="sendSuggestedInvitation(suggestion.email, index)"
                        @remove-suggestion="removeSuggestion(index)"
                    ></suggestion>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import InviteSuggestionMixin from './invite/mixins/referral_invite_suggestions_mixin';
import Suggestion from './invite/suggestion.vue';
import ReferralSendInvitationMixin from './mixins/referral_send_invitation_mixin';

export default {
    name: 'InviteFriendsPopup',
    components: {
        Suggestion
    },
    mixins: [ReferralSendInvitationMixin, InviteSuggestionMixin],
    data() {
        return {
            dropDownActive: true
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapState('referral', ['suggestions']),
        showInviteFriends() {
            return this.fiveOwnSuggestions.length > 0;
        }
    },
    methods: {
        sendSuggestedInvitation(email, index) {
            this.sendInvitation(email);
            this.removeSuggestion(index);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

.invite-friends {
    @include desktop {
        position: absolute;
        top: 150px;
        left: 150px;
        z-index: 1;
        box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.2);

        width: 330px;
        padding-bottom: 0px;
        padding-top: 0px;
        border-radius: 4px;
    }

    @include mobile {
        padding: 0.75rem;
    }
}

.referral-dropdown-menu {
    padding-left: 0.75rem;
    background-color: white;
    border-radius: 0px 0px 4px 4px;
}

.invite-friends .referral-dropdown-trigger {
    display: flex;
    border-radius: 4px;
    border-color: rgb(236, 244, 249);
    background-color: rgb(236, 244, 249);
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    line-height: 1.25;
    color: $primary;
    padding: 0.4rem;
    justify-content: space-between;

    .referral-dropdown-title {
        flex-basis: 90%;
        padding: 0.35rem;
    }

    .referral-dropdown-chevron .icon {
        opacity: 1;
        background-color: rgb(222, 240, 249);
        border-radius: 40px;
        padding: 0.25rem;
    }
}
</style>
