<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal">You're about to remove this invitation</h2>
        <img class="modal-image" src="../../../../resources/static/images/modals/user_red.svg" />

        <p>
            <b>Are you sure you want to remove the invitation you sent to {{ teamInvitation.sentTo }}?</b>
        </p>
        <p>
            <i class="has-text-grey-dark">
                The invitation will be removed from your list. You'll only be charged for the days the invitation was live. We'll discount the remaining amount on a pro rata basis from your next
                invoice.
            </i>
        </p>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary" :disabled="requestLoading" @click="closeModal()">Keep invitation</button>
            <button class="button is-primary is-outlined" :class="{ 'is-loading': requestLoading }" :disabled="requestLoading" @click="cancelInvitation(teamInvitation.id)">
                Remove
            </button>
        </div>
    </div>
</template>

<script>
import ModalMixin from '../mixins/modal_mixin';

export default {
    name: 'CancelInvitation',
    mixins: [ModalMixin],
    data() {
        return {
            requestLoading: false
        };
    },
    computed: {
        teamInvitation() {
            return this.modalData.invitation;
        }
    },
    methods: {
        cancelInvitation() {
            this.requestLoading = true;
            this.$store
                .dispatch('team/deleteInvitation', {
                    teamId: this.teamInvitation.teamId,
                    invitationId: this.teamInvitation.id
                })
                .then(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: 'Canceled! You’ve canceled this invitation successfully.'
                    });
                })
                .catch(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
        }
    }
};
</script>
