<template>
    <div id="quickest-response">
        <time-display v-if="quickestResponseTime !== null" :seconds="quickestResponseTime"></time-display>
        <div v-else class="is-flex flex-1 items-center">
            <span class="has-text-centered">You didn't have any responses for this period</span>
        </div>
    </div>
</template>

<script>
import reportMetricMixin from '../mixins/report_metric_mixin';
import TimeDisplay from './time_display.vue';

export default {
    name: 'QuickestResponse',
    components: {
        TimeDisplay
    },
    mixins: [reportMetricMixin],
    computed: {
        quickestResponseTime() {
            return this.componentMetrics.minReplyTime.value;
        }
    }
};
</script>
