<template>
    <div id="direct-messages">
        <div class="columns flex-1">
            <div class="column is-flex flex-col justify-end items-center">
                <div class="is-flex flex-1 flex-col">
                    <text-with-tag text="Direct Messages" :value="componentMetrics.totalDirectMessages.value" :color="blueColors3Slices[0]"></text-with-tag>
                    <text-with-tag text="CC" :value="componentMetrics.totalIndirectMessages.value" :color="blueColors3Slices[1]"></text-with-tag>
                    <text-with-tag text="Others" :value="componentMetrics.totalOtherMessages.value" :color="blueColors3Slices[2]"></text-with-tag>
                </div>
                <div>
                    <apexchart
                        type="donut"
                        width="180px"
                        height="180px"
                        :options="chartOptions"
                        :series="[componentMetrics.totalDirectMessages.value, componentMetrics.totalIndirectMessages.value, componentMetrics.totalOtherMessages.value]"
                    ></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { DONUT_CHART_OPTIONS } from './chart_options';
import TextWithTag from './text_with_tag.vue';

export default {
    name: 'DirectMessages',
    components: {
        TextWithTag
    },
    mixins: [ReportMetricMixin],
    computed: {
        chartOptions() {
            return Object.assign({}, DONUT_CHART_OPTIONS, { labels: ['Direct Messages', 'CC', 'Others'] }, { colors: this.blueColors3Slices });
        }
    }
};
</script>
