<template>
    <section id="report" class="section">
        <div class="columns">
            <div class="column has-text-centered relative">
                <h2 class="title is-capitalized is-2 mb-2">{{ formattedTitle }}</h2>
                <a
                    v-if="displayReport && hasCurrentSpan"
                    ref="downloadCsv"
                    class="button is-outlined is-primary export-btn"
                    :class="{ 'is-loading': exporting }"
                    :disabled="!metrics"
                    @click="exportToCsv()"
                >
                    <span>CSV Export</span>
                </a>
            </div>
        </div>
        <div class="tile is-ancestor is-vertical">
            <div class="tile is-parent">
                <filter-wrapper @show-content="shouldDisplayReport"></filter-wrapper>
            </div>
            <template v-if="displayReport && hasCurrentSpan && Boolean(metrics)">
                <div class="tile is-parent">
                    <report-summary></report-summary>
                </div>
                <div v-if="Boolean(summaryDiff)" class="tile">
                    <good-news-card></good-news-card>
                </div>
                <div class="tile is-inline-block-tablet-only">
                    <div class="tile" :class="{ 'is-6': !$store.getters.isMobileOrTablet }">
                        <div class="tile is-parent is-6">
                            <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.sentHeatmap]" metric-component-name="SentHeatmap"></metric-wrapper>
                        </div>
                        <div class="tile is-parent is-6">
                            <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.receivedHeatmap]" metric-component-name="ReceivedHeatmap"></metric-wrapper>
                        </div>
                    </div>
                    <div class="tile is-vertical" :class="{ 'is-6': !$store.getters.isMobileOrTablet }">
                        <div class="tile">
                            <div class="tile is-parent is-12">
                                <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.sentHistogram]" metric-component-name="SentHistogram"></metric-wrapper>
                            </div>
                        </div>
                        <div class="tile">
                            <div class="tile is-parent is-12">
                                <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.receivedHistogram]" metric-component-name="ReceivedHistogram"></metric-wrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="canBusinessHours && $waffle.switch_is_active('business_hours')" class="tile is-parent">
                    <p class="tile is-child">
                        <span class="icon mr-2">
                            <i v-if="user.config.businessHoursEnabled" class="em-icon working-hours is-size-5"></i>
                            <i v-else class="em-icon working-hours-disabled is-size-5"></i>
                        </span>
                        <template v-if="user.config.businessHoursEnabled">
                            We take into account your work hours when calculating Response Time metrics. To customize or disable this feature,&nbsp;
                            <router-link to="/settings">go to settings</router-link>.
                        </template>
                        <template v-else>
                            We're not taking into account your work hours when calculating Response Time metrics. To customize or enable this feature,&nbsp;
                            <router-link to="/settings">go to settings</router-link>.
                        </template>
                    </p>
                </div>
                <div class="tile">
                    <div class="tile is-parent is-8">
                        <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.timeBeforeFirstResponse]" metric-component-name="TimeBeforeFirstResponse"></metric-wrapper>
                    </div>
                    <div class="tile is-vertical is-4">
                        <div class="tile is-parent">
                            <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.minReplyTime]" metric-component-name="QuickestResponse"></metric-wrapper>
                        </div>
                        <div class="tile is-parent">
                            <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.averageFirstResponseTime]" metric-component-name="AverageFirstResponse"></metric-wrapper>
                        </div>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile is-parent is-6">
                        <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.emailsReplied]" metric-component-name="EmailsReplied"></metric-wrapper>
                    </div>
                    <div class="tile is-parent is-6">
                        <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.internalExternal]" metric-component-name="InternalExternal"></metric-wrapper>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile is-parent is-3">
                        <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.threads]" metric-component-name="NewThreads"></metric-wrapper>
                    </div>
                    <div class="tile is-parent is-3">
                        <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.directMessages]" metric-component-name="DirectMessages"></metric-wrapper>
                    </div>
                    <div class="tile is-parent is-6">
                        <div class="tile is-child box has-background-primary-lighter is-flex items-center">
                            <div class="columns">
                                <div class="column is-flex items-center">
                                    <figure class="image">
                                        <img src="../../../resources/static/images/enterprise_cta.svg" />
                                    </figure>
                                </div>
                                <div class="column">
                                    <h3 class="title is-3 has-text-secondary">Looking for company-wide analytics?</h3>
                                    <p class="has-text-secondary mb-6">Gain insights into your team's email statistics with your company specific metrics in one centralized dashboard.</p>
                                    <a class="button is-secondary" @click="onEnterpriseCTA">Request a Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tile is-parent">
                    <metric-wrapper v-bind="METRICS_SETUP[METRIC_CARDS.topInteractions]" metric-component-name="TopInteractions"></metric-wrapper>
                </div>
            </template>
            <spinner v-else-if="displayReport && hasCurrentSpan && !Boolean(metrics)" class="mx-auto mt-6"></spinner>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import UserApi from '../../api/user_api';
import FilterUtils from '../../models/filters/filter_utils';
import Log from '../../utils/log';
import modalsEnum from '../../utils/modals_enum';
import utils from '../../utils/utils';
import Spinner from '../base/spinner.vue';
import FilterWrapper from '../filter_navbar/filter_wrapper.vue';
import METRIC_CARDS from '../report/metrics/metric_cards';
import METRICS_SETUP from '../report/metrics/metrics_setup';
import GoodNewsCard from './metrics/good_news_card.vue';
import MetricWrapper from './metrics/metric_wrapper.vue';
import ReportSummary from './metrics/report_summary.vue';

export default {
    components: {
        MetricWrapper,
        FilterWrapper,
        ReportSummary,
        GoodNewsCard,
        Spinner
    },
    data() {
        return {
            displayReport: true,
            METRICS_SETUP,
            METRIC_CARDS,
            exporting: false
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('team', ['firstTeam']),
        ...mapState('filter', ['metrics']),
        ...mapGetters('filter', ['summaryDiff', 'currentPageFilters']),
        canBusinessHours() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canBusinessHours);
        },
        formattedTitle() {
            let pageTitle = `${this.$route.params.section} report`;

            if (!this.firstTeam || this.firstTeam.members.length === 0) {
                pageTitle = `my ${pageTitle}`;
            }
            return pageTitle;
        },
        currentFilters() {
            return FilterUtils.flattenFiltersBqi(this.$store.getters['filter/currentPageFilters']);
        },
        hasCurrentSpan() {
            if (!this.currentPageFilters) return false;
            return Boolean(utils.getValueOrNull(this.currentPageFilters, 'dateFilter.timestampTo'));
        }
    },
    methods: {
        exportToCsv() {
            window.dataLayer.push({
                event: 'reportCSVButton'
            });
            if (this.user.permissions(this.$em.constants.USER_PERMISSIONS.canExportToCsv)) {
                try {
                    this.exporting = true;
                    UserApi.csvExport(this.currentFilters).then(res => {
                        this.exporting = false;
                        if (res.cached === 'no') {
                            this.$bus.emit(this.$bus.events.notification, {
                                bgColor: 'is-success',
                                text: 'Great! We are preparing a CSV file with your data which will be emailed to you shortly!',
                                timeLapse: 0
                            });
                        } else {
                            this.$bus.emit(this.$bus.events.notification, {
                                bgColor: 'is-warning',
                                text: "Hey! It seems you're trying to export the same CSV again. Check your email or contact us if the problem continues.",
                                timeLapse: 0
                            });
                        }
                    });
                } catch (err) {
                    this.exporting = false;
                    Log.error(err);
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Wait a few minutes and try again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                }
            } else {
                this.$bus.emit(this.$bus.events.showModal, modalsEnum.paywall, { type: 'exportReport', isLarge: true });
            }
        },
        shouldDisplayReport(val) {
            this.displayReport = val;
        },
        onEnterpriseCTA() {
            window.dataLayer.push({
                event: 'enterprise_cta'
            });
            window.location = 'https://pipedrivewebforms.com/form/f5c22f48690e9f3212c2728aff812ac94360301';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

.export-btn {
    @include desktop {
        position: absolute;
        right: 0;
        top: 0.75rem;
        margin-right: 0.75rem;
    }

    @include mobile {
        margin-top: 1rem;
    }
}
</style>
