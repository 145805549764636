import qs from 'querystring';

export default class SocialShare {
    constructor(options, endpoint = '') {
        this.options = options;
        this.endpoint = {
            referral: '/referral',
            socialShare: ''
        }[endpoint];
    }

    getLink(provider, extraParams, msg) {
        const cbUrl = encodeURIComponent(this._getCallbackUrl(provider, extraParams));
        const message = encodeURI(msg);
        return {
            twitter: `https://twitter.com/intent/tweet?text=${message}&url=${cbUrl}`,
            linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${cbUrl}`,
            facebook: `https://www.facebook.com/sharer/sharer.php?u=${cbUrl}`
        }[provider];
    }

    _getCallbackUrl(source, extraParams) {
        const urlQs = qs.stringify(Object.assign({}, this.options, { source }, extraParams));
        return `${process.env.ENDPOINT_FRONTEND}/share${this.endpoint}?${urlQs}`;
    }
}
