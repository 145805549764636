import Filter from './filter';

export default class DateFilter extends Filter {
    constructor(timestampFrom, timestampTo) {
        super();
        // TIMESTAMP IN SECONDS
        this.timestampFrom = timestampFrom;
        this.timestampTo = timestampTo;
    }

    static get id() {
        return 'date';
    }

    toObject() {
        return {
            'timestamp_from': this.timestampFrom,
            'timestamp_to': this.timestampTo
        };
    }
}
