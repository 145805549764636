<template>
    <div v-if="hasCustomDomain">
        <div class="columns is-flex-desktop flex-1">
            <div class="column is-flex flex-col justify-end items-center">
                <div class="is-flex flex-1 flex-col">
                    <p class="is-uppercase is-size-8 has-text-centered">Sent</p>
                    <text-with-tag text="Internal messages" :value="componentMetrics.totalInternalMessagesSent.value" :color="greenColors[0]"></text-with-tag>
                    <text-with-tag text="External messages" :value="componentMetrics.totalExternalMessagesSent.value" :color="greenColors[1]"></text-with-tag>
                </div>
                <div>
                    <apexchart
                        type="donut"
                        width="180px"
                        height="180px"
                        :options="Object.assign({}, chartOptions, { colors: greenColors })"
                        :series="[componentMetrics.totalInternalMessagesSent.value, componentMetrics.totalExternalMessagesSent.value]"
                    ></apexchart>
                </div>
            </div>
            <div class="column is-flex flex-col justify-end items-center">
                <div class="is-flex flex-1 flex-col">
                    <p class="is-uppercase is-size-8 has-text-centered">Received</p>
                    <text-with-tag text="Internal messages" :value="componentMetrics.totalInternalMessagesReceived.value" :color="blueColors[0]"></text-with-tag>
                    <text-with-tag text="External messages" :value="componentMetrics.totalExternalMessagesReceived.value" :color="blueColors[1]"></text-with-tag>
                </div>
                <div>
                    <apexchart
                        type="donut"
                        width="180px"
                        height="180px"
                        :options="Object.assign({}, chartOptions, { colors: blueColors })"
                        :series="[componentMetrics.totalInternalMessagesReceived.value, componentMetrics.totalExternalMessagesReceived.value]"
                    ></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { DONUT_CHART_OPTIONS } from './chart_options';
import TextWithTag from './text_with_tag.vue';

export default {
    name: 'InternalExternal',
    components: {
        TextWithTag
    },
    mixins: [ReportMetricMixin],
    data() {
        return {
            chartOptions: Object.assign({}, DONUT_CHART_OPTIONS, { labels: ['Internal', 'External'] })
        };
    },
    computed: {
        hasCustomDomain() {
            return this.$store.state.user.user.isGSuite();
        }
    }
};
</script>
