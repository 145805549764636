<template>
    <div v-if="!$store.getters.isMobileOrTablet" id="report-navigation" class="tabs mb-0">
        <ul>
            <router-link tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.monthly) }" active-class="is-active">
                <a>Monthly</a>
            </router-link>
            <router-link tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.weekly) }" active-class="is-active">
                <a>Weekly</a>
            </router-link>
            <router-link tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.custom) }" active-class="is-active">
                <a>Custom Dates</a>
            </router-link>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('filter', ['pathToFilteredPath'])
    }
};
</script>
