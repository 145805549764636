<template>
    <div v-if="Boolean(goodNewsType)" id="share-metrics-card" class="tile is-parent">
        <div class="box is-child flex-1  has-background-primary-light">
            <div class="columns">
                <div class="column is-flex items-center is-3">
                    <figure class="image mx-auto social-share-illustration">
                        <img src="../../../../resources/static/images/illustration_goodnews.svg" />
                    </figure>
                </div>
                <div class="column is-6">
                    <h2 class="title">{{ title }}</h2>
                    <span v-html="message"></span> <!-- eslint-disable-line -->
                </div>
                <div class="column is-3 is-flex items-center justify-center">
                    <div class="buttons flex-col">
                        <a class="button is-fullwidth twitter-button" @click="openPopup(twitterLink)">
                            <span class="icon is-small mr-2">
                                <img src="../../../../resources/static/images/icons/twitter_icon.svg" />
                            </span>
                            <span class="mx-auto">Share on Twitter</span>
                        </a>
                        <a class="button is-fullwidth linkedin-button" @click="openPopup(linkedInLink)">
                            <span class="icon is-small mr-2">
                                <img src="../../../../resources/static/images/icons/linkedin_icon.svg" />
                            </span>
                            <span class="mx-auto">Share on LinkedIn</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, mapState } from 'vuex';

import SocialShare from '../../../api/social_share';
import dateHelpers from '../../../utils/date_helpers';

const IMAGES = {
    responseTime: 'response_time_card.jpg',
    recipients: 'recipients_card.jpg',
    received: 'received_card.jpg',
    sent: 'sent_card.jpg',
    senders: 'senders_card.jpg'
};

const GOOD_NEWS_CARDS = {
    responseTime: {
        title: 'Boost your email productivity',
        description: 'How long do you take to reply to emails? What time of the day are you most productive? Get these insights and more, for free, from Email Meter.',
        image: IMAGES.responseTime
    },
    sent: {
        title: 'Boost your email productivity',
        description: 'How many emails do you send each day? What time of the day are you most productive? Get these insights and more, for free, from Email Meter.',
        image: IMAGES.sent
    },
    received: {
        title: 'Boost your email productivity',
        description: 'How many emails do you receive each day? What time of the day are you most productive? Get these insights and more, for free, from Email Meter.',
        image: IMAGES.received
    },
    recipients: {
        title: 'Boost your email productivity',
        description: 'How many people do you email each day? What time of the day are you most productive? Get these insights and more, for free, from Email Meter.',
        image: IMAGES.recipients
    },
    senders: {
        title: 'Boost your email productivity',
        description: 'How many people email you each day? What time of the day are you most productive? Get these insights and more, for free, from Email Meter.',
        image: IMAGES.senders
    }
};

const GOOD_NEWS_TYPE = {
    responseTime: 'responseTime',
    sent: 'sent',
    received: 'received',
    senders: 'senders',
    recipients: 'recipients'
};

export default {
    name: 'GoodNewsCard',
    computed: {
        ...mapState('filter', ['metrics']),
        ...mapGetters('filter', ['summaryDiff']),
        /* eslint-disable complexity */
        goodNewsType() {
            const diff = this.summaryDiff;
            if (!diff) return null;
            if (diff.averageResponseTime <= -5 && this.metrics.averageResponseTime.value > 0) {
                return GOOD_NEWS_TYPE.responseTime;
            } else if (diff.totalEmailsSent >= 10) {
                return GOOD_NEWS_TYPE.sent;
            } else if (diff.totalEmailsReceived >= 10) {
                return GOOD_NEWS_TYPE.received;
            } else if (diff.totalUniqueRecipients >= 10) {
                return GOOD_NEWS_TYPE.recipients;
            } else if (diff.averageResponseTime < 0 && this.metrics.averageResponseTime.value > 0) {
                return GOOD_NEWS_TYPE.responseTime;
            } else if (diff.totalEmailsSent > 3) {
                return GOOD_NEWS_TYPE.sent;
            } else if (diff.totalEmailsReceived > 3) {
                return GOOD_NEWS_TYPE.received;
            } else if (diff.totalUniqueSenders > 10) {
                return GOOD_NEWS_TYPE.senders;
            } else {
                return null;
            }
        },
        /* eslint-enable complexity */

        /* eslint-disable max-len */
        message() {
            const diff = this.summaryDiff;
            const timestampFrom = this.$store.getters['filter/currentPageFilters'].dateFilter.timestampFrom;
            const lastMonth = dayjs
                .unix(timestampFrom)
                .utc()
                .subtract(1, 'month')
                .format('MMMM');
            switch (this.goodNewsType) {
                case GOOD_NEWS_TYPE.responseTime:
                    return `<p>You took ${this.formattedAverageResponseTime()} on average to reply to your emails this month – <strong>that’s ${Math.abs(
                        diff.averageResponseTime
                    )}% less than you took in ${lastMonth}.</strong></p>
                            <p>Celebrate your rapid replying by sharing it on Twitter and Linkedin.</p>`;
                case GOOD_NEWS_TYPE.sent:
                    return `<p>You sent ${this.metrics.totalEmailsSent.value} emails in total – <strong>an impressive ${diff.totalEmailsSent}% increase compared to ${lastMonth}.</strong></p>
                            <p>Celebrate your improved productivity by sharing it on Twitter and Linkedin.</p>`;
                case GOOD_NEWS_TYPE.received:
                    return `<p>You received ${this.metrics.totalEmailsReceived.value} emails this month – <strong>a ${diff.totalEmailsReceived}% increase compared to ${lastMonth}.</strong></p>
                            <p>Celebrate your hard work by sharing it on Twitter and Linkedin.</p>`;
                case GOOD_NEWS_TYPE.recipients:
                    return `<p>You sent emails to ${this.metrics.totalUniqueRecipients.value} different people – <strong>a ${diff.totalUniqueRecipients}% increase compared to ${lastMonth}.</strong></p>
                            <p>Celebrate your boost in productivity by sharing it on Twitter and Linkedin.</p>`;
                case GOOD_NEWS_TYPE.senders:
                    return `<p>You received emails from ${this.metrics.totalUniqueSenders.value} different people this month – <strong>that’s a ${diff.totalUniqueSenders}% increase compared to ${lastMonth}.</strong></p>
                            <p>Celebrate another month of hard work by sharing it on Twitter and Linkedin.</p>`;
                default:
                    return null;
            }
        },
        /* eslint-enable max-len */

        title() {
            switch (this.goodNewsType) {
                case GOOD_NEWS_TYPE.responseTime:
                    return 'You’re getting faster!';
                case GOOD_NEWS_TYPE.sent:
                    return 'What a month!';
                case GOOD_NEWS_TYPE.received:
                    return 'Good hustle!';
                case GOOD_NEWS_TYPE.recipients:
                    return 'Another month, another improvement!';
                case GOOD_NEWS_TYPE.senders:
                    return 'You’re in demand!';
                default:
                    return null;
            }
        },
        twitterLink() {
            const msg = this.getTwitterMessage();
            const message = `${msg}\n\n📈 Get your own email stats at @emailmeter:`;
            const shareNews = this._generateSharedNews();
            return shareNews.getLink('twitter', { 'news_name': this.goodNewsType }, message);
        },
        linkedInLink() {
            const shareNews = this._generateSharedNews();
            return shareNews.getLink('linkedin', { 'news_name': this.goodNewsType });
        }
    },
    methods: {
        /* eslint-disable max-len */
        getTwitterMessage() {
            const diff = this.summaryDiff;
            switch (this.goodNewsType) {
                case GOOD_NEWS_TYPE.responseTime:
                    return `An efficient month! I took an average of just ${this.formattedAverageResponseTime()} to reply to emails – that’s ${Math.abs(
                        diff.averageResponseTime
                    )}% less than I took last month 💪`;
                case GOOD_NEWS_TYPE.sent:
                    return `A very productive month! I sent ${this.metrics.totalEmailsSent.value} emails in total – that’s ${diff.totalEmailsSent}% more than last month 💪`;
                case GOOD_NEWS_TYPE.received:
                    return `That was a busy month! I received ${this.metrics.totalEmailsReceived.value} emails in total – that’s ${diff.totalEmailsReceived}% more than last month 💪`;
                case GOOD_NEWS_TYPE.recipients:
                    return `Another productive month! I sent emails to ${this.metrics.totalUniqueRecipients.value} different people – that’s ${diff.totalUniqueRecipients}% more than last month 💪`;
                case GOOD_NEWS_TYPE.senders:
                    return `That was a hectic month! I received emails from ${this.metrics.totalUniqueSenders.value} different people – that’s ${diff.totalUniqueSenders}% more than last month 💪`;
                default:
                    return null;
            }
        },
        /* eslint-enable max-len */
        formattedAverageResponseTime() {
            const parsedTime = dateHelpers.parseTime(this.metrics.averageResponseTime.value);
            const format = dateHelpers.formatTime;
            if (parsedTime.days) {
                return `${parsedTime.days}d ${format(parsedTime.hours)}h`;
            } else if (parsedTime.hours) {
                return `${parsedTime.hours}h ${format(parsedTime.minutes)}m`;
            } else {
                return `${parsedTime.minutes}m ${format(parsedTime.seconds)}s`;
            }
        },
        openPopup(link) {
            window.open(link, 'popup', 'width=600,height=600');
        },
        _generateSharedNews() {
            return new SocialShare(
                {
                    title: GOOD_NEWS_CARDS[this.goodNewsType].title,
                    image: GOOD_NEWS_CARDS[this.goodNewsType].image,
                    description: GOOD_NEWS_CARDS[this.goodNewsType].description
                },
                'socialShare'
            );
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/mixins';
@import '../../../styles/variables';

$blue-social-twitter: #1da1f2;
$blue-social-twitter-accent: #006dbf;
$blue-social-linkedin: #0077b5;
$blue-social-linkedin-accent: #005e93;

.social-share-illustration {
    width: 70%;
}

@mixin share-button($color, $color-accent) {
    background-color: $color;
    color: #fff;
    border: none;

    &:hover {
        background-color: $color-accent;
    }
}

.twitter-button {
    @include share-button($blue-social-twitter, $blue-social-twitter-accent);
}
.linkedin-button {
    @include share-button($blue-social-linkedin, $blue-social-linkedin-accent);
}
</style>
