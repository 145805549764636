/*
Poller
Polls a given path until the fetch is ok and customCheck is true, returns the result of the fetch and postprocess in a callback-like fashion with then and catch
*/

import ApiBase from './api_base';

class Poll {
    constructor(path, customCheck, postprocess, timelapse) {
        this.path = path;
        this.customCheck = customCheck;
        this.timelapse = timelapse;
        this.cancelled = false;
        this.postprocess = postprocess;
    }

    start() {
        this.promise = this._poll();
    }

    cancel() {
        this.cancelled = true;
    }

    then(...args) {
        return this.promise.then(...args);
    }

    catch(...args) {
        return this.promise.catch(...args);
    }

    _poll() {
        if (this.cancelled) return Promise.reject('Poll cancelled');
        return this._fetchAndCheck().catch(() => {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(this._poll());
                }, this.timelapse);
            });
        });
    }

    _fetchAndCheck() {
        return ApiBase.fetchData(this.path).then(data => {
            if (this.customCheck && !this.customCheck(data)) return Promise.reject();
            else return Promise.resolve(this.postprocess(data));
        });
    }
}

export default function poll(path, customCheck, postprocess, timelapse = 500) {
    const p = new Poll(path, customCheck, postprocess, timelapse);
    p.start();
    return p;
}
