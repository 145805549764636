/* eslint-disable max-len */
import utils from '../../utils/utils';

module.exports = {
    questions: [
        {
            field: 'team_size',
            title: 'How many accounts do you want to measure?',
            choices: [
                { value: '1', label: 'Only one account', icon: 'icon-one-account' },
                { value: '2_15', label: 'Between 2 and 15 accounts', icon: 'icon-between-2-15' },
                { value: 'plus15', label: 'Over 15 accounts', icon: 'icon-company' }
            ],
            hint: {
                header: 'Email Meter works great for a single account, teams with several accounts, or even entire companies.',
                subheader:
                    'If you have a large number of accounts  or shared inboxes to monitor, get in touch with us <a href="https://pipedrivewebforms.com/form/935d2ca52e1a3a21153accc120cbab814360301" target="_blank">here</a>.',
                picture: 'finder_guy'
            },
            choicesClass: 'button-selector-with-icon'
        },
        {
            field: 'role',
            title: 'What is your job role?',
            choices: utils
                .shuffle([
                    { value: 'it', label: 'IT' },
                    { value: 'sales_rep', label: 'Sales Rep' },
                    { value: 'sales_manager', label: 'Sales Manager' },
                    { value: 'hr', label: 'HR' },
                    { value: 'c_level', label: 'C-Level' },
                    { value: 'upper_management', label: 'Upper Management' },
                    { value: 'engineering_data', label: 'Engineering/Data' },
                    { value: 'marketing', label: 'Marketing' },
                    { value: 'customer_service_rep', label: 'Customer Service Rep' },
                    { value: 'operations', label: 'Operations' },
                    { value: 'legal', label: 'Legal' },
                    { value: 'customer_service_manager', label: 'Customer Service Manager' }
                ])
                .concat([{ value: 'other', label: 'Other' }]),
            hint: {
                header: 'Do you reply faster than other people in the same position/department?',
                subheader: 'In the future this information will enable us to give you <b>customized insights</b>.',
                picture: 'people_picking_up_boxes'
            },
            choicesClass: 'button-selector-rounded-inline'
        },
        {
            field: 'company_size',
            title: 'What’s your company size?',
            choices: [
                { value: '1_10', label: 'Between 1 and 10 employees' },
                { value: '11_50', label: '11 to 50 employees' },
                { value: '51_200', label: '51 to 200 employees' },
                { value: 'plus_200', label: 'More than 200 employees' }
            ],
            hint: {
                header: 'Knowing your company size will help us build suggestions and insights that are unique to you.',
                subheader: '',
                picture: 'illustration_onboarding_batch_happy'
            },
            choicesClass: 'button-selector-rounded'
        },
        {
            field: 'industry',
            title: 'What’s your main industry?',
            choices: utils
                .shuffle([
                    { value: 'computer_software', label: 'Computer Software' },
                    { value: 'financial_service', label: 'Financial Service' },
                    { value: 'ngo', label: 'NGO' },
                    { value: 'it', label: 'IT' },
                    { value: 'logistics', label: 'Logistics and Supply Chain' },
                    { value: 'manufactoring', label: 'Manufacturing' },
                    { value: 'hospitality', label: 'Hospitality' },
                    { value: 'management_consultancy', label: 'Management Consultancy' },
                    { value: 'communications', label: 'Communications' },
                    { value: 'healthcare', label: 'Healthcare' },
                    { value: 'legal', label: 'Legal' },
                    { value: 'education', label: 'Education' },
                    { value: 'higher_education', label: 'Higher Education' }
                ])
                .concat([{ value: 'other', label: 'Other' }]),
            hint: {
                header: 'No two industries use email the same way.',
                subheader: 'With your answers, on top of building custom insights, you’ll also get <b>curated success stories relevant to your industry</b>.',
                picture: 'people_holding_key'
            },
            choicesClass: 'button-selector-rounded-inline'
        },
        {
            field: 'metrics_to_improve',
            title: 'What would best describe your main goal?',
            choices: utils
                .shuffle([
                    { value: 'reduce_usage', label: 'I want to spend less time with email', icon: 'icon-less-time' },
                    { value: 'respond_quicker', label: 'I want to respond to messages quicker', icon: 'icon-respond-quicker' },
                    { value: 'workload', label: 'I want to measure workload', icon: 'icon-measure-workload' }
                ])
                .concat([{ value: 'other', label: 'Other', icon: 'icon-other' }]),
            hint: {
                header: 'This is our last question.<br><b>You’re almost there!</b>',
                subheader: 'If you share your goal with us, we will do <b>our best</b> to help you achieve it.',
                picture: 'people_holding_check'
            },
            choicesClass: 'button-selector-with-icon'
        }
    ]
};
