<!-- eslint-disable max-lines -->
<template>
    <div id="my-team" class="columns is-multiline">
        <div class="column is-full has-text-centered relative">
            <h2 class="title is-2 mb-2">My Team</h2>
            <div class="right-buttons">
                <router-link class="button is-primary mr-2" to="/settings/team-management">Manage Team</router-link>
                <a v-if="Boolean(metrics) && metrics.teamStats" ref="downloadCsv" class="button is-primary is-outlined" @click="exportToCSV()">
                    <span>CSV Export</span>
                </a>
            </div>
        </div>
        <div class="column is-full">
            <filter-wrapper @show-content="shouldDisplayContent"></filter-wrapper>
        </div>
        <div v-if="isLoading" class="is-flex flex-1 justify-center items-center mt-6">
            <spinner></spinner>
        </div>
        <template v-if="Boolean(metrics)">
            <div v-if="displayContent" class="column is-full">
                <template v-if="teamStats && teamStats.length > 0">
                    <div v-if="!$store.getters.isMobile" class="box">
                        <v-client-table :options="options" :columns="columns" :data="tableData">
                            <template #h__name>
                                <div></div>
                            </template>
                            <template #h__received>
                                <div class="header-title">
                                    <i class="em-icon email is-size-4 has-text-grey-dark"></i>
                                    Received messages
                                </div>
                            </template>
                            <template #h__sent>
                                <div class="header-title">
                                    <i class="em-icon email is-size-4 has-text-grey-dark"></i>
                                    Sent messages
                                </div>
                            </template>
                            <template #h__responseRate>
                                <div class="header-title">
                                    <i class="em-icon reply is-size-4 has-text-grey-dark"></i>
                                    Response rate
                                </div>
                            </template>
                            <template #h__avgResponse>
                                <div class="header-title">
                                    <i class="em-icon response-time is-size-4 has-text-grey-dark"></i>
                                    Avg Response Time
                                </div>
                            </template>
                            <template #name="props">
                                <div class="user-field">
                                    <!-- <figure class="image is-flex items-center mr-3" v-if="props.row.id !== 'avg'">
                                        <img src="https://placehold.it/50x50" class="is-rounded">
                                    </figure> -->
                                    <div class="is-flex flex-col">
                                        <span class="name">{{ props.row.fullName }}</span>
                                        <span class="email">{{ props.row.email }}</span>
                                    </div>
                                </div>
                            </template>
                            <template #received="props">
                                <metric-field :metric-value="props.row.received" :metric-average="getAveragePerColumn.received"></metric-field>
                            </template>
                            <template #sent="props">
                                <metric-field :metric-value="props.row.sent" :metric-average="getAveragePerColumn.sent"></metric-field>
                            </template>
                            <template #responseRate="props">
                                <metric-field :metric-value="props.row.responseRate" :metric-text="`${props.row.responseRate}%`" :metric-average="getAveragePerColumn.responseRate"></metric-field>
                            </template>
                            <template #avgResponse="props">
                                <metric-field :metric-value="props.row.avgResponse" :metric-text="props.row.avgResponse | formatTime" :metric-average="getAveragePerColumn.avgResponse"></metric-field>
                            </template>
                        </v-client-table>
                    </div>
                    <div v-else>
                        <div v-for="(row, index) in tableData" :key="index" class="box mb-4" :class="{ 'row-avg': row.id === 'avg' }">
                            <div class="user-field mb-3">
                                <!-- <figure class="image is-flex items-center mr-3" v-if="row.id !== 'avg'">
                                    <img src="https://placehold.it/50x50" class="is-rounded">
                                </figure> -->
                                <div class="is-flex flex-col">
                                    <span class="name">{{ row.fullName }}</span>
                                    <span class="email">{{ row.email }}</span>
                                </div>
                            </div>
                            <div class="is-flex justify-between mb-3">
                                Received messages
                                <metric-field :metric-value="row.received" :metric-average="getAveragePerColumn.received"></metric-field>
                            </div>
                            <div class="is-flex justify-between mb-3">
                                Sent messages
                                <metric-field :metric-value="row.sent" :metric-average="getAveragePerColumn.sent"></metric-field>
                            </div>
                            <div class="is-flex justify-between mb-3">
                                Response ratio
                                <metric-field :metric-value="row.responseRate" :metric-text="`${row.responseRate}%`" :metric-average="getAveragePerColumn.responseRate"></metric-field>
                            </div>
                            <div class="is-flex justify-between mb-3">
                                Avg response time
                                <metric-field :metric-value="row.avgResponse" :metric-text="row.avgResponse | formatTime" :metric-average="getAveragePerColumn.avgResponse"></metric-field>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import csvExport from '../../api/csv_export';
import { STATUS } from '../../api/parsers/bqi_parser';
import METRIC_TYPE from '../../models/metric_type';
import dateHelpers from '../../utils/date_helpers';
import Log from '../../utils/log';
import utils from '../../utils/utils';
import Spinner from '../base/spinner.vue';
import FilterWrapper from '../filter_navbar/filter_wrapper.vue';
import metricMixin from '../report/mixins/report_metric_mixin';
import MetricField from './common/metric_field.vue';

export default {
    name: 'MyTeam',
    components: {
        FilterWrapper,
        MetricField,
        Spinner
    },
    filters: {
        formatTime(val) {
            return dateHelpers.formatDate(val);
        }
    },
    mixins: [metricMixin],
    data() {
        return {
            displayContent: true,
            columns: ['name', 'received', 'sent', 'responseRate', 'avgResponse'],
            options: {
                rowClassCallback(row) {
                    if (row.id) return `row-${row.id}`;
                },
                filterable: false,
                sortable: ['received', 'sent', 'responseRate', 'avgResponse'],
                sortIcon: {
                    base: 'mi',
                    up: 'arrow_upward',
                    down: 'arrow_downward',
                    is: 'swap_vert'
                },
                skin: 'table is-fullwidth',
                texts: {
                    count: ''
                },
                orderBy: {
                    column: 'received'
                },
                perPage: 50
            }
        };
    },
    computed: {
        teamStats() {
            if (this.metrics && this.metrics[METRIC_TYPE.TEAM.teamStats].status === STATUS.LOADED) return this.metrics[METRIC_TYPE.TEAM.teamStats].value;
            else return undefined;
        },
        tableData() {
            const teamStats = [...this.teamStats];

            teamStats.unshift({
                id: 'avg',
                fullName: 'Team average',
                email: 'Average calculated for team users',
                ...this.getAveragePerColumn
            });

            return teamStats;
        },
        getAveragePerColumn() {
            return {
                sent: this.getAverage(this.teamStats, 'sent', 0),
                received: this.getAverage(this.teamStats, 'received', 0),
                responseRate: this.getAverage(this.teamStats, 'responseRate', 2),
                avgResponse: this.getAverage(this.teamStats, 'avgResponse', 2)
            };
        },
        hasAnyFilter() {
            const currentPageFilters = this.$store.state.filter.filters[this.$em.constants.PAGE_PATHS.team];
            if (!currentPageFilters) return false;
            return Boolean(utils.getValueOrNull(currentPageFilters, 'dateFilter.timestampTo'));
        },
        isLoading() {
            return [null, undefined].includes(this.teamStats) && this.hasAnyFilter;
        }
    },
    methods: {
        getAverage(data, field, decimals) {
            const average =
                data.reduce((acc, e) => {
                    acc += e[field];
                    return acc;
                }, 0) / data.length;

            return Math.round(average * 10 ** decimals) / 10 ** decimals;
        },
        shouldDisplayContent(val) {
            this.displayContent = val;
            if (this.hasAnyFilter && this.displayContent && this.metrics === null) {
                this.$store.commit('filter/updateMetric', { [METRIC_TYPE.TEAM.teamStats]: undefined });
            }
        },
        exportToCSV() {
            window.dataLayer.push({
                event: 'teamCSVButton'
            });
            try {
                const url = csvExport(METRIC_TYPE.TEAM, this.metrics);

                this.$refs.downloadCsv.href = url;
                this.$refs.downloadCsv.target = '_blank';
                this.$refs.downloadCsv.download = 'Email Meter - My Team Data.csv';
            } catch (error) {
                Log.error(error);
                this.$bus.emit(this.$bus.events.notification, {
                    bgColor: 'is-danger',
                    text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                    timeLapse: 0
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

.right-buttons {
    @include desktop {
        position: absolute;
        right: 0;
        top: 0.75rem;
        margin-right: 0.75rem;
    }
}

#my-team::v-deep {
    .row-avg {
        background-color: $primary-lighter;

        .name {
            color: $primary;
        }

        .metric-field {
            color: $primary;
            .compare {
                display: none;
            }
        }
    }

    .user-field {
        display: flex;

        img {
            width: 50px;
            height: 50px;
        }

        .name {
            font-size: 1.125rem;
            font-weight: bold;
        }

        .email {
            font-size: 0.875rem;
        }
    }

    .VueTables__table {
        th {
            border: unset;
            text-align: center;
        }
        td {
            vertical-align: middle;
            border: unset;
        }
    }

    .VueTables__sortable {
        cursor: pointer;
    }

    .VueTables__heading {
        .header-title {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: normal;
        }
    }

    span.VueTables__sort-icon {
        margin-left: 0.5rem;
    }

    .VueTables__sort-icon.mi {
        float: unset !important;

        &:after {
            font-family: 'em-icons';
            font-weight: normal;
            top: 5px;
        }

        &.arrow_downward:after {
            content: '\f103';
        }
        &.arrow_upward:after {
            content: '\f104';
        }
        &.swap_vert:after {
            content: '\f145';
        }
    }

    .VuePagination {
        display: none !important;
    }
}
</style>
