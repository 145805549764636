<template>
    <button class="button is-fullwidth is-white has-text-weight-bold mb-4 shadow hover:shadow-3 justify-start" :class="{ 'is-primary': isSelected }" @click="$emit('click')">
        <span class="icon" :class="iconClass"></span>
        <span>{{ label }}</span>
    </button>
</template>

<script>
export default {
    name: 'OnboardingOptionButton',
    props: {
        isSelected: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        iconClass: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.button {
    height: 4.5rem;
    padding-top: 0;
    padding-bottom: 0;

    .icon {
        height: 4.5rem;
        width: 4.5rem;
        margin-right: 3rem;
    }

    &.is-primary {
        .icon {
            margin-top: -1px;
        }

        .icon-one-account {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 0;
        }

        .icon-between-2-15 {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 432px;
        }

        .icon-company {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 360px;
        }

        .icon-less-time {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 288px;
        }

        .icon-respond-quicker {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 216px;
        }

        .icon-measure-workload {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 144px;
        }

        .icon-other {
            background: url('../../../resources/static/images/onboarding/main_sprite.svg') 72px 72px;
        }
    }
}

.icon-one-account {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 0;
}

.icon-between-2-15 {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 432px;
}

.icon-company {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 360px;
}

.icon-less-time {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 288px;
}

.icon-respond-quicker {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 216px;
}

.icon-measure-workload {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 144px;
}

.icon-other {
    background: url('../../../resources/static/images/onboarding/main_sprite.svg') 0 72px;
}
</style>
