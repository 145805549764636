<template>
    <button class="button" :class="`${provider}-button`" @click="$emit('social-click', provider)">
        <span class="icon is-size-6">
            <i :class="provider" class="em-icon"></i>
        </span>
    </button>
</template>

<script>
export default {
    name: 'SocialButton',
    props: {
        provider: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
$blue-social-twitter: #1da1f2;
$blue-social-twitter-accent: #006dbf;
$blue-social-linkedin: #0077b5;
$blue-social-linkedin-accent: #005e93;
$blue-social-facebook: #4267b2;
$blue-social-facebook-accent: #314b82;

@mixin share-button($color, $color-accent) {
    background-color: $color;
    color: #fff;
    border: none;

    &:hover {
        background-color: $color-accent;
    }
}

.twitter-button {
    @include share-button($blue-social-twitter, $blue-social-twitter-accent);
}
.linkedin-button {
    @include share-button($blue-social-linkedin, $blue-social-linkedin-accent);
}
.facebook-button {
    @include share-button($blue-social-facebook, $blue-social-facebook-accent);
}
</style>
