<template>
    <div id="onboarding-batch-loader" class="section is-flex items-center mx-auto">
        <div class="container is-flex flex-col items-center">
            <img class="mb-6" src="../../../resources/static/images/onboarding/illustration_onboarding_batch_happy.png" width="300px" alt="Email Meter" />
            <div class="columns is-centered">
                <div class="column is-two-thirds">
                    <h1 class="is-size-2 has-text-centered mb-6">Thank you!</h1>
                    <p class="has-text-grey-darker has-text-centered mb-6">We're nearly finished! Email Meter is now collecting your email stats.</p>
                    <p class="has-text-grey-darker has-text-centered mb-6">
                        You can track our progress in the bar below and we'll also send you an email to let you know when we're done. You can close this page or wait a few more minutes.
                    </p>
                </div>
            </div>
            <p v-if="onboardingBatch.progress < 25" class="has-text-secondary has-text-weight-bold mb-8">Just counting how many emails you've sent…</p>
            <p v-else-if="onboardingBatch.progress < 50" class="has-text-secondary has-text-weight-bold mb-8">Now calculating your average reply time…</p>
            <p v-else-if="onboardingBatch.progress < 75" class="has-text-secondary has-text-weight-bold mb-8">Almost done now, just working out when you're most productive…</p>
            <p v-else class="has-text-secondary has-text-weight-bold mb-8">Adding the finishing touches…</p>
            <progress class="progress is-small is-secondary" :value="onboardingBatch.progress" max="100">{{ onboardingBatch.progress }}</progress>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'OnboardingBatchLoader',
    computed: {
        ...mapGetters('batch', ['onboardingBatch'])
    }
};
</script>
