import MetricType from '../../../models/metric_type';
import MetricCards from './metric_cards';

export default {
    [MetricCards.totalEmailsSent]: {
        metricName: 'Messages Sent',
        description: 'Total emails you sent during the selected period.',
        helpLink: 'http://help.emailmeter.com/articles/3016805-sent-emails',
        requiredMetrics: [MetricType.REPORT.totalEmailsSent]
    },
    [MetricCards.totalUniqueRecipients]: {
        metricName: 'Recipients',
        description: 'Total number of different email addresses you sent emails to during the selected period.',
        helpLink: 'http://help.emailmeter.com/articles/3017217-recipients',
        requiredMetrics: [MetricType.REPORT.totalUniqueRecipients]
    },
    [MetricCards.averageResponseTime]: {
        metricName: 'Avg Response Time',
        description: 'Average time you took to reply to emails you received during the selected period. If you reply outside the period to an email you received during the period, we’ll count it.',
        helpLink: 'http://help.emailmeter.com/articles/3023639-average-response-time',
        requiredMetrics: [MetricType.REPORT.averageResponseTime]
    },
    [MetricCards.totalEmailsReceived]: {
        metricName: 'Messages Received',
        description: 'Total number of emails you received during the selected period. We don’t count spam.',
        helpLink: 'http://help.emailmeter.com/articles/3017196-received',
        requiredMetrics: [MetricType.REPORT.totalEmailsReceived]
    },
    [MetricCards.totalUniqueSenders]: {
        metricName: 'Senders',
        description: 'Total number of different email addresses that sent you an email during the selected period.',
        helpLink: 'http://help.emailmeter.com/articles/3023477-senders',
        requiredMetrics: [MetricType.REPORT.totalUniqueSenders]
    },
    [MetricCards.sentHeatmap]: {
        metricName: 'Busiest Hours',
        description: 'The number of sent messages for each hour of the week, the more messages the darker the color. You can highlight each hour for specific numbers.',
        helpLink: 'https://help.emailmeter.com/en/articles/3136074-busiest-hours',
        requiredMetrics: [MetricType.REPORT.sentHeatmap]
    },
    [MetricCards.receivedHeatmap]: {
        metricName: 'Busiest Hours',
        description: 'The number of received messages for each hour of the week, the more messages the darker the color. You can highlight each hour for specific numbers.',
        helpLink: 'https://help.emailmeter.com/en/articles/3136074-busiest-hours',
        requiredMetrics: [MetricType.REPORT.receivedHeatmap]
    },
    [MetricCards.sentHistogram]: {
        metricName: 'Messages Sent by Day',
        description: 'The number of sent messages for each day of the selected period. You can highlight each day for specific numbers.',
        helpLink: 'https://help.emailmeter.com/en/articles/3137221-messages-sent-by-day-messages-received-by-day',
        requiredMetrics: [MetricType.REPORT.sentHistogram]
    },
    [MetricCards.receivedHistogram]: {
        metricName: 'Messages Received by Day',
        description: 'The number of received messages for each day of the selected period. You can highlight each day for specific numbers.',
        helpLink: 'https://help.emailmeter.com/en/articles/3137221-messages-sent-by-day-messages-received-by-day',
        requiredMetrics: [MetricType.REPORT.receivedHistogram]
    },
    [MetricCards.timeBeforeFirstResponse]: {
        metricName: 'Time Before First Response',
        description: 'The time you took to respond to opening emails (the 1st email in a thread) during the selected period. We don’t count spam.',
        helpLink: 'http://help.emailmeter.com/articles/3037313-time-before-first-response',
        requiredMetrics: [MetricType.REPORT.timeBeforeFirstResponse]
    },
    [MetricCards.minReplyTime]: {
        metricName: 'Quickest Response Time',
        description: 'The least amount of time you took to respond to an email during the selected period.',
        helpLink: 'http://help.emailmeter.com/articles/3037338-quickest-response-time',
        requiredMetrics: [MetricType.REPORT.minReplyTime]
    },
    [MetricCards.averageFirstResponseTime]: {
        metricName: 'Average First Response Time',
        description: 'The average time you took to respond to opening emails (the 1st email in a thread) during the selected period. We don’t count spam.',
        helpLink: 'http://help.emailmeter.com/articles/3037354-average-first-response-time',
        requiredMetrics: [MetricType.REPORT.averageFirstResponseTime]
    },
    [MetricCards.emailsReplied]: {
        metricName: 'Emails Replied',
        description: 'Total number of received emails you replied to, and total number of sent emails you received a reply for.',
        helpLink: 'http://help.emailmeter.com/articles/3037405-emails-replied',
        requiredMetrics: [MetricType.REPORT.totalSentAndAnswered, MetricType.REPORT.totalEmailsSent, MetricType.REPORT.totalReceivedAndAnswered, MetricType.REPORT.totalEmailsReceived]
    },
    [MetricCards.internalExternal]: {
        metricName: 'Internal vs. External',
        description: `Total number of emails you received from and sent to a different domain (External),
                        and the total number of emails received from and sent to the same domain (Internal).`,
        helpLink: 'http://help.emailmeter.com/articles/3055188-internal-vs-external',
        requiredMetrics: [
            MetricType.REPORT.totalInternalMessagesSent,
            MetricType.REPORT.totalExternalMessagesSent,
            MetricType.REPORT.totalInternalMessagesReceived,
            MetricType.REPORT.totalExternalMessagesReceived
        ]
    },
    [MetricCards.threads]: {
        metricName: 'Sent breakdown',
        description: 'Sent emails broken down into emails that were the first in a thread, or sent emails that were replies to existing emails.',
        helpLink: 'http://help.emailmeter.com/articles/3077536-new-threads',
        requiredMetrics: [MetricType.REPORT.totalNewThreads, MetricType.REPORT.totalNewThreadsReplies]
    },
    [MetricCards.directMessages]: {
        metricName: 'Received breakdown',
        description: `Total number of emails you received, broken down into those for which you were a direct recipient, emails where you were 
                        in the "CC" (carbon copy) field, and rest of emails (such as "BCC" and received through a mailing list).`,
        helpLink: 'http://help.emailmeter.com/articles/3057520-direct-messages',
        requiredMetrics: [MetricType.REPORT.totalDirectMessages, MetricType.REPORT.totalIndirectMessages, MetricType.REPORT.totalOtherMessages]
    },
    [MetricCards.topInteractions]: {
        metricName: 'Top Interactions',
        description: 'Addresses/Domains you exchanged the most emails with and a breakdown of the stats for each of those Addresses/Domains.',
        helpLink: 'https://help.emailmeter.com/en/articles/3100211-top-interactions',
        requiredMetrics: [MetricType.REPORT.topInteractions, MetricType.REPORT.topInteractionsByDomain]
    }
};
