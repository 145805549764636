const TITLE_FEATURES = ['Create Custom Reports', 'Export data to CSV', '2 years of email history', 'Advanced data filtering', 'Business Hours', 'Team and individual performance reports'];

import footer1Paywall from '../../../resources/static/images/footer_1_paywall.svg';
import footer2Paywall from '../../../resources/static/images/footer_2_paywall.svg';
import footer3Paywall from '../../../resources/static/images/footer_3_paywall.svg';

const FOOTER_FEATURES = [
    {
        illustration: footer1Paywall,
        title: 'Create Custom Reports',
        body: 'Get email stats for any combination of dates, team members and filters.',
        knowMoreLink: 'https://emailmeter.com/premium'
    },
    {
        illustration: footer2Paywall,
        title: 'Access your email history',
        body: 'See the last 2 years of your Gmail activity to understand trends and improvements.',
        knowMoreLink: 'https://emailmeter.com/premium'
    },
    {
        illustration: footer3Paywall,
        title: 'Easily download data',
        body: 'Analyze your data in any format you want from our CSV exports.',
        knowMoreLink: 'https://emailmeter.com/premium'
    }
];

export { TITLE_FEATURES, FOOTER_FEATURES };
