import BillingApi from '../api/billing_api';
import utils from '../utils/utils';

export default {
    namespaced: true,
    state: {
        customer: null,
        invoices: null,
        plans: null
    },
    mutations: {
        setCustomer(state, customer) {
            state.customer = customer;
        },
        setInvoices(state, invoices) {
            state.invoices = invoices;
        },
        setPlans(state, plans) {
            state.plans = plans;
        }
    },
    getters: {
        subscriptionPeriodEnd: state => {
            if (state.customer) return state.customer.subscription.currentPeriodEnd;
            return null;
        },
        planByName: state => planName => {
            return state.plans.find(plan => plan.name === planName);
        },
        pricingString: state => (quantity, decimals = 0) => {
            const subscription = state.customer.subscription;
            if (quantity === undefined) quantity = subscription.quantity;
            return `${utils.formatCurrency(subscription.amount * quantity, decimals)}/${subscription.interval}`;
        }
    },
    actions: {
        fetchCustomer(context) {
            return BillingApi.getCustomer().then(customer => {
                context.commit('setCustomer', customer);
            });
        },
        fetchInvoices(context) {
            return BillingApi.getInvoices().then(invoices => {
                context.commit('setInvoices', invoices);
            });
        },
        fetchPlans(context) {
            return BillingApi.getPlans().then(plans => {
                context.commit('setPlans', plans);
            });
        },
        checkout(context, checkoutData) {
            return BillingApi.checkout(checkoutData).then(async () => {
                await context.dispatch('team/fetchTeams', null, { root: true });
                const requests = [context.dispatch('fetchCustomer'), context.dispatch('fetchInvoices'), context.dispatch('user/fetchUser', null, { root: true })];
                if (checkoutData.emails.length > 0) requests.push(context.dispatch('team/fetchInvitations', null, { root: true }));
                return Promise.all(requests);
            });
        },
        updateSubscription(context, { subscriptionId, action }) {
            return BillingApi.updateSubscription(subscriptionId, action).then(() => {
                return Promise.all([context.dispatch('user/fetchUser', null, { root: true }), context.dispatch('fetchCustomer')]);
            });
        },
        updateCustomer(context, data) {
            return BillingApi.updateCustomerInfo(data).then(customer => {
                context.commit('setCustomer', customer);
            });
        }
    }
};
