<template>
    <div class="columns is-multiline">
        <div class="column is-full">
            <hr />
        </div>
        <div class="column is-full has-text-centered">
            <h2 class="title is-2 is-size-5-mobile">Invoices</h2>
        </div>
        <div v-if="invoices !== null" class="column is-full">
            <template v-if="invoices.length">
                <template v-if="$store.getters.isMobile">
                    <div v-for="invoice in invoices" :key="invoice.invoiceNumber" class="box">
                        <p>
                            Date
                            <span class="has-text-weight-bold is-pulled-right">{{ invoice.date | formatDate('MM/DD/YYYY') }}</span>
                        </p>
                        <p>
                            Invoice
                            <span class="has-text-weight-bold is-pulled-right">{{ invoice.invoiceNumber }}</span>
                        </p>
                        <p class="is-flex items-center">
                            Description
                            <span class="has-text-weight-bold pl-6 ml-auto">{{ invoice.description }}</span>
                        </p>
                        <p>
                            Total
                            <span class="has-text-weight-bold is-pulled-right">{{ invoice.total | formatCurrency }}</span>
                        </p>
                        <div class="is-flex items-center">
                            PDF
                            <a class="button is-primary is-outlined is-small ml-auto" :href="invoice.pdf" target="_blank">
                                <span class="icon">
                                    <i class="em-icon download"></i>
                                </span>
                                <span>Download</span>
                            </a>
                        </div>
                    </div>
                </template>
                <div v-else class="box">
                    <table class="table is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th class="has-text-weight-bold">Date</th>
                                <th class="has-text-weight-bold">Invoice</th>
                                <th class="has-text-weight-bold">Description</th>
                                <th class="has-text-weight-bold">Total</th>
                                <th class="has-text-weight-bold">PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoice in invoices" :key="invoice.invoiceNumber">
                                <td class="has-text-weight-bold">{{ invoice.date | formatDate('MM/DD/YYYY') }}</td>
                                <td>{{ invoice.invoiceNumber }}</td>
                                <td>{{ invoice.description }}</td>
                                <td>{{ invoice.total | formatCurrency }}</td>
                                <td>
                                    <a class="button is-transparent has-text-primary" :href="invoice.pdf" target="_blank">
                                        <span class="icon">
                                            <i class="em-icon download"></i>
                                        </span>
                                        <span>Download</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <p v-else class="has-text-centered">No invoices yet.</p>
        </div>
        <div v-else class="is-flex flex-1 items-center justify-center">
            <spinner></spinner>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import Spinner from '../../components/base/spinner.vue';

export default {
    components: {
        Spinner
    },
    computed: {
        ...mapState('billing', ['invoices'])
    }
};
</script>

<style lang="scss" scoped>
.table {
    td,
    tr {
        border: 0;
    }

    td {
        vertical-align: middle;
    }
}
</style>
