<template>
    <div v-if="!empty" class="is-flex py-2">
        <div class="flex-1 is-flex flex-col justify-center mr-3 overflow-hidden">
            <h2 class="has-text-weight-bold truncate">{{ name || email }}</h2>
            <h3 v-if="name" class="is-size-9 has-text-grey-darker truncate">{{ email }}</h3>
        </div>
        <button class="button is-primary self-center" :class="{ 'is-small': isSmall }" @click="$emit('send-invitation')">
            <span class="icon">
                <i class="em-icon user-add"></i>
            </span>
            <span>Invite</span>
        </button>
        <a class="button is-transparent has-text-primary self-center" @click="$emit('remove-suggestion')">
            <span class="icon">
                <i class="em-icon cancel"></i>
            </span>
        </a>
    </div>
    <span v-else></span>
</template>

<script>
export default {
    name: 'Suggestion',
    props: {
        isSmall: {
            type: Boolean,
            default: false
        },
        empty: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            required: true
        }
    }
};
</script>
