<template>
    <div>
        <div class="is-flex items-center is-capitalized is-size-8"><i class="dot" :class="`has-background-metric-${type}`"></i>{{ type }} messages</div>
        <apexchart type="heatmap" width="100%" height="500px" :options="chartOptions" :series="chartData"></apexchart>
        <hr class="mt-0" />
        <p class="has-text-grey-dark is-size-9 mb-0 text-insight">{{ $props.insightText(formatMaxHeatmapHour, formatMaxHeatmapWeekday) }}</p>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import bqiParserHelper from '../../../api/parsers/bqi_parser_helper';
import utils from '../../../utils/utils';
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { HEATMAP_CHART_OPTIONS } from './chart_options';

export default {
    name: 'BaseHeatmap',
    mixins: [ReportMetricMixin],
    props: {
        type: {
            type: String,
            required: true
        },
        color: {
            type: Array,
            required: true
        },
        insightText: {
            type: Function,
            required: true
        }
    },
    computed: {
        currentMetric() {
            return this.$props.requiredMetrics[0];
        },
        chartOptions() {
            return utils.deepMergeObjects(HEATMAP_CHART_OPTIONS, {
                chart: { id: `${this.type}-heatmap`, group: 'heatmap' },
                colors: [this.color[0]]
            });
        },
        chartData() {
            return Object.values(bqiParserHelper.heatmapFormat(this.componentMetrics[this.currentMetric].value));
        },
        maxEmailsHeatmap() {
            return this.getMaxHeatmapValue(this.componentMetrics[this.currentMetric].value);
        },
        formatMaxHeatmapWeekday() {
            return this.formatWeekday(this.maxEmailsHeatmap);
        },
        formatMaxHeatmapHour() {
            return this.formatHour(this.maxEmailsHeatmap);
        }
    },
    methods: {
        getMaxHeatmapValue(metric) {
            return metric.reduce((prev, current) => {
                return prev.count > current.count ? prev : current;
            });
        },
        formatWeekday(metric) {
            const days = dayjs.en.weekdays;
            return days[metric.weekday];
        },
        formatHour(metric) {
            return dayjs()
                .hour(metric.hour)
                .format('h A');
        }
    }
};
</script>

<style scoped>
.dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
</style>
