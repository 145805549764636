<template>
    <div class="is-flex flex-col flex-1">
        <report-navigation></report-navigation>
        <invite-friends-popup v-if="showInviteFriendsPopup"></invite-friends-popup>
        <section v-if="showCustomReportsPaywall" id="custom-report-paywall" class="section">
            <paywall-screen title="Would you like to create a Custom Report?" :illustration="illustrationPaywall"></paywall-screen>
        </section>
        <card-batch-loading v-else-if="showPremiumBatch"></card-batch-loading>
        <report-content v-else></report-content>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import illustrationCustomReportPaywall from '../../resources/static/images/illustration_custom_report_paywall.svg';
import PaywallScreen from '../components/paywall/paywall_screen.vue';
import InviteFriendsPopup from '../components/referral/invite_friends_popup.vue';
import CardBatchLoading from '../components/report/card_batch_loading.vue';
import ReportContent from '../components/report/report_content.vue';
import ReportNavigation from '../components/report/report_navigation.vue';
import FilterUtils from '../models/filters/filter_utils';
import METRIC_TYPE from '../models/metric_type';
import dateHelpers from '../utils/date_helpers';

function setWeeklyDefault(vm) {
    const timestampTo = vm.$store.state.filter.dataSpan.timestampTo;
    const selectedWeek = dateHelpers.getLastPeriod(timestampTo, 'week');
    const dateFilter = dateHelpers.getPeriodSpan(selectedWeek, 'week');

    if (!vm.$store.state.filter.filters[vm.$em.constants.PAGE_PATHS.weekly]) vm.$store.commit('filter/setPageFilters', { page: vm.$em.constants.PAGE_PATHS.weekly, filters: { dateFilter } });
}

function setMonthlyDefault(vm) {
    const timestampTo = vm.$store.state.filter.dataSpan.timestampTo;
    const selectedMonth = dateHelpers.getLastPeriod(timestampTo, 'month');
    const dateFilter = dateHelpers.getPeriodSpan(selectedMonth, 'month');

    if (!vm.$store.state.filter.filters[vm.$em.constants.PAGE_PATHS.monthly]) vm.$store.commit('filter/setPageFilters', { page: vm.$em.constants.PAGE_PATHS.monthly, filters: { dateFilter } });
}

/* eslint-disable complexity */
function handleUrlChange(vm, from, to, next) {
    vm.showCustomReportsPaywall = false;
    const user = vm.$store.state.user.user;
    const pageName = to.params.section;

    if (!Object.keys(vm.$em.constants.PERIOD_SPAN_KINDS).includes(pageName)) {
        next(vm.$em.constants.PAGE_PATHS.monthly);
        return;
    }

    if (pageName === vm.$em.constants.PERIOD_SPAN_KINDS.custom && !user.permissions(vm.$em.constants.USER_PERMISSIONS.canCustomReport)) {
        vm.showCustomReportsPaywall = true;
        window.dataLayer.push({ event: 'paywallCustomReport' });
        return;
    }

    const parsedFilters = FilterUtils.parseUrl(to.query);

    if (parsedFilters.dateFilter !== null) {
        const periodKind = dateHelpers.getSpanKind(to.query.timestamp_from, to.query.timestamp_to);
        if (pageName !== periodKind) {
            let query = to.query;
            if (periodKind === vm.$em.constants.PERIOD_SPAN_KINDS.custom && !user.permissions(vm.$em.constants.USER_PERMISSIONS.canCustomReport)) {
                query = {};
            }
            next({ path: vm.$em.constants.PAGE_PATHS[periodKind], query: query });
            return;
        }
        vm.$store.dispatch('filter/changeFilters', {
            page: to.path,
            filters: parsedFilters,
            metrics: METRIC_TYPE.REPORT
        });
    } else if (to.params.section === vm.$em.constants.PERIOD_SPAN_KINDS.custom) {
        vm.$store.commit('filter/setPageFilters', { page: vm.$em.constants.PAGE_PATHS.custom, filters: {} });
        vm.$store.commit('filter/resetMetrics', METRIC_TYPE.REPORT);
    } else {
        let path = to.path;
        if (!to.params.section) path = vm.$em.constants.PAGE_PATHS.monthly;
        next(vm.$store.getters['filter/pathToFilteredPath'](path));
    }
}
/* eslint-enable complexity */

function setShowInviteFriendsPopup(vm) {
    const user = vm.$store.state.user.user;
    vm.showInviteFriendsPopup = user.permissions(vm.$em.constants.USER_PERMISSIONS.canReferralProgram) && vm.$waffle.switch_is_active('referral_program');
}

export default {
    name: 'Report',
    components: {
        CardBatchLoading,
        PaywallScreen,
        ReportContent,
        ReportNavigation,
        InviteFriendsPopup
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            setMonthlyDefault(vm);
            setWeeklyDefault(vm);
            setShowInviteFriendsPopup(vm);
            handleUrlChange(vm, from, to, next);
        });
    },
    beforeRouteUpdate(to, from, next) {
        handleUrlChange(this, from, to, next);
        next();
    },
    data() {
        return {
            showCustomReportsPaywall: false,
            showInviteFriendsPopup: false,
            illustrationPaywall: illustrationCustomReportPaywall
        };
    },
    computed: {
        ...mapGetters('batch', ['premiumBatch']),
        showPremiumBatch() {
            return this.$route.path === this.$em.constants.PAGE_PATHS.custom && this.premiumBatch && !this.premiumBatch.isComplete();
        }
    }
};
</script>
