<template>
    <div id="referral-features" class="column is-full">
        <div class="box">
            <section class="section">
                <div class="columns">
                    <div class="column is-flex flex-col items-center">
                        <h3 class="title is-3 is-size-4-mobile">What you'll get</h3>
                        <p>Secure your free month of Premium and take your productivity to the next level with:</p>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-7-tablet is-12-mobile is-flex justify-center">
                        <img class="mx-auto" src="../../../resources/static/images/illustration_referral_features.svg" />
                    </div>
                    <div class="column is-flex sm:justify-center">
                        <features class="is-flex flex-col has-text-primary" :features="features"></features>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-7-tablet is-12-mobile is-flex items-center justify-center">
                        <span class="is-size-6 has-text-weight-bold">Want to keep Premium once your free month is over?</span>
                    </div>
                    <div class="column is-flex-mobile items-center justify-center">
                        <router-link tag="button" class="button is-primary" to="/get-premium/select-product">Buy Premium</router-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Features from '../base/features';

export default {
    name: 'ReferralFeatures',
    components: {
        Features
    },
    data() {
        return {
            features: ['Custom reports', 'Advanced filters', 'Business hours', 'Export to CSV', '2 years of email history']
        };
    }
};
</script>
