import dayjs from 'dayjs';
import querystring from 'querystring';

import AdvancedFilters from './advanced_filters';
import DateFilter from './date_filter';
import UsersFilter from './users_filter';

const AdvancedFiltersList = Object.keys(AdvancedFilters).reduce((acc, el) => {
    const newEl = el === undefined ? {} : { [AdvancedFilters[el].id]: AdvancedFilters[el] };
    return Object.assign({}, acc, newEl);
}, {});

// TODO: Move this file to other path

export default class FilterUtils {
    static parseUrl(query) {
        return {
            dateFilter: this._parseDateFilter(query),
            usersFilter: this._parseUserFilter(query),
            advancedFilters: this._parseAdvancedFilters(query)
        };
    }

    static flattenFilters(filters) {
        return this.baseFlattenFilters('serialize', filters);
    }

    static flattenFiltersBqi(filters) {
        return this.baseFlattenFilters('bqiSerialize', filters);
    }

    static _parseDateFilter(parsedQs) {
        const tsFrom = dayjs.unix(parsedQs.timestamp_from);
        const tsTo = dayjs.unix(parsedQs.timestamp_to);
        if (!tsFrom.isValid() || !tsTo.isValid()) return null;
        else return new DateFilter(tsFrom.unix(), tsTo.unix());
    }

    static _parseUserFilter(parsedQs) {
        const usersIds = parsedQs[UsersFilter.id];
        if (!usersIds || usersIds.length === 0) return null;
        const filter = new UsersFilter();
        filter.parse(usersIds);
        return filter;
    }

    static _parseElementName(element) {
        const parts = element.split('_');
        if (Object.keys(AdvancedFilters.ExclusiveAdvancedFilter.excludedOptions).includes(parts[0])) {
            return parts[1];
        } else {
            return parts[0];
        }
    }

    static _parseAdvancedFilters(rawElements) {
        return Object.keys(rawElements)
            .map(el => {
                const elementName = this._parseElementName(el);
                if (AdvancedFiltersList[elementName]) {
                    const filter = new AdvancedFiltersList[elementName]({});
                    filter.parse(rawElements[el], el);
                    return filter;
                } else return null;
            })
            .filter(f => Boolean(f));
    }

    static baseFlattenFilters(flattenMethodName, filters) {
        const rawQuery = [filters.dateFilter, filters.usersFilter, ...(filters.advancedFilters || [])]
            .filter(Boolean)
            .map(f => f[flattenMethodName]())
            .filter(Boolean)
            .join('&');

        const parsedQuery = querystring.parse(rawQuery);

        const cleanQuery = Object.keys(parsedQuery).reduce((acc, elem) => {
            if (parsedQuery[elem] instanceof Array) {
                const allElements = parsedQuery[elem].join(',').split(',');
                const deduplicated = new Set(allElements);
                acc[elem] = Array.from(deduplicated).join(',');
            } else acc[elem] = parsedQuery[elem];
            return acc;
        }, {});
        return querystring.stringify(cleanQuery);
    }
}
