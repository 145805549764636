function canNavigateToTeamManagement(vm, next) {
    const user = vm.$store.state.user.user;
    const team = vm.$store.getters['team/firstTeam'];

    if (team) {
        if (!user.permissions(vm.$em.constants.USER_PERMISSIONS.canManageTeams) || (team.members.length === 0 && team.invitations.length === 0)) {
            next('/settings');
        }
    } else {
        next('/settings');
    }
}

export default {
    canNavigateToTeamManagement
};
