import dayjs from 'dayjs';

import { INVITATION_STATUS } from '../../app/models/referral';
import ReferralApi from '../api/referral_api';

export default {
    namespaced: true,
    state: {
        invitations: {},
        purchases: {},
        premiumTimestampEnd: null,
        suggestions: [],
        premiumJustEnded: false
    },
    getters: {
        isPremiumReferral(state) {
            return !(state.premiumTimestampEnd === null || dayjs().diff(dayjs(state.premiumTimestampEnd)) > 0);
        },
        premiumReferralRemainingSeconds(state, { isPremiumReferral }) {
            return () => {
                if (!isPremiumReferral) return 0;
                return dayjs(new Date(state.premiumTimestampEnd)).diff(new Date(), 'seconds');
            };
        }
    },
    mutations: {
        setPremiumJustEnded(state, newVal) {
            state.premiumJustEnded = newVal;
        },
        resetTimer(state) {
            // TODO: after referral release: this may be not needed anymore
            const sameVal = state.premiumTimestampEnd;
            state.premiumTimestampEnd = 10;
            state.premiumTimestampEnd = sameVal;
        },
        setReferralStatus(state, referralStatus) {
            state.invitations = referralStatus.invitations;
            state.purchases = referralStatus.purchases;
            state.premiumTimestampEnd = referralStatus.premiumTimestampEnd;
        },
        setSuggestions(state, suggestions) {
            state.suggestions = suggestions;
        },
        addNewInvitation(state, email) {
            state.invitations = Object.assign({}, state.invitations, {
                [INVITATION_STATUS.PENDING]: [email, ...state.invitations[INVITATION_STATUS.PENDING]]
            });
        }
    },
    actions: {
        fetchStatus({ commit }) {
            return ReferralApi.getStatus().then(referralStatus => {
                commit('setReferralStatus', referralStatus);
            });
        },
        fetchSuggestions({ commit }) {
            return ReferralApi.getSuggestions().then(suggestions => {
                commit('setSuggestions', suggestions);
            });
        }
    }
};
