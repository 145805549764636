<template>
    <div>
        <p v-for="featureName in features" :key="featureName" class="is-inline-flex mr-4">
            <span class="icon mr-2">
                <i class="em-icon tick-list"></i>
            </span>
            {{ featureName }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        features: {
            type: Array,
            required: true
        }
    }
};
</script>
