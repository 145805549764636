<template>
    <apexchart class="w-full" width="100%" height="230px" :options="chartOptions" :series="chartData"></apexchart>
</template>

<script>
import utils from '../../../utils/utils';
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { LINE_CHART_OPTIONS } from './chart_options';

export default {
    name: 'BaseHistogram',
    mixins: [ReportMetricMixin],
    props: {
        type: {
            type: String,
            required: true
        },
        color: {
            type: Array,
            required: true
        }
    },
    computed: {
        currentMetric() {
            return this.$props.requiredMetrics[0];
        },
        chartOptions() {
            const maxValue = Math.max(...this.chartData[0].data);
            const yaxis = {};
            if (maxValue < 5 && maxValue > 0) {
                yaxis.tickAmount = maxValue;
                yaxis.max = maxValue;
                yaxis.labels = {
                    formatter: val => val.toFixed(0)
                };
            }

            const dates = Object.keys(this.componentMetrics[this.currentMetric].value).map(d => Number.parseInt(d));

            return utils.deepMergeObjects(LINE_CHART_OPTIONS, {
                chart: { id: `${this.type}-histogram`, group: 'histogram' },
                colors: this.color,
                xaxis: { categories: dates },
                yaxis
            });
        },
        chartData() {
            return [
                {
                    name: this.$props.type,
                    type: 'area',
                    data: Object.values(this.componentMetrics[this.currentMetric].value)
                }
            ];
        }
    }
};
</script>
