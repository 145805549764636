import Cookies from 'js-cookie';
import url from 'url';

const API_BASE_URL = process.env.ENDPOINT_BACKEND;

async function generateErrorData(headers, response, postPayload = {}) {
    const resText = await response.clone().text();
    const responseInfo = {
        responseText: resText,
        status: response.status,
        url: response.url
    };

    let query;
    try {
        const urlParsed = url.parse(response.url, true);
        query = urlParsed.query;
    } catch (error) {
        query = {};
    }

    let resJSON;
    try {
        resJSON = await response.json();
    } catch (error) {
        resJSON = { error: 'Internal Server Error' };
    }
    return { resJSON, debug: { responseInfo, headers, query, postPayload } };
}

function unauthenticatedEmit(response) {
    if (response.status === 401) {
        // Unauthorized
        const unauthenticatedEvent = new Event('unauthenticated');
        window.dispatchEvent(unauthenticatedEvent);
    }
}

function getHeaders(additionalHeaders) {
    const userToken = window.USER_TOKEN;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
    if (userToken) headers.Authorization = `Token ${userToken}`;
    return Object.assign({}, headers, additionalHeaders);
}

export default class ApiBase {
    static fetchData(path, endpoint = API_BASE_URL, cacheInfo = false) {
        const headers = getHeaders();
        return fetch(endpoint + path, {
            headers: headers,
            credentials: 'include',
            mode: 'cors'
        }).then(async res => {
            if (!res.ok) {
                unauthenticatedEmit(res);
                throw await generateErrorData(headers, res);
            }
            if (cacheInfo) {
                return { response: res.json(), cached: res.headers.get('cached') };
            } else {
                return res.json();
            }
        });
    }

    static postData(path, data, method = 'POST') {
        const csrfToken = {
            'X-CSRFToken': Cookies.get('emcsrftoken')
        };
        const headers = getHeaders(csrfToken);
        const nullBodyStatusCodes = [101, 204, 205, 304];
        return fetch(API_BASE_URL + path, {
            headers: headers,
            method: method,
            credentials: 'include',
            mode: 'cors',
            body: JSON.stringify(data)
        }).then(async res => {
            if (nullBodyStatusCodes.includes(res.status)) return null;
            if (!res.ok) {
                unauthenticatedEmit(res);
                throw await generateErrorData(headers, res, data);
            }
            return res.json();
        });
    }

    static redirectTo(link) {
        window.location = link;
    }
}
