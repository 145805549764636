<template>
    <div class="text-value">
        <p class="text">{{ text }}</p>
        <span class="tag has-text-weight-semibold" :style="{ 'background-color': color, 'color': textColor }">{{ value }}</span>
    </div>
</template>

<script>
export default {
    name: 'TextWithTag',
    props: {
        text: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            default: '#08B545'
        }
    },
    computed: {
        textColor() {
            return this.getContrastYIQ(this.color);
        }
    },
    methods: {
        getContrastYIQ(hexcolor) {
            hexcolor = hexcolor.substr(1);
            const r = parseInt(hexcolor.substr(0, 2), 16);
            const g = parseInt(hexcolor.substr(2, 2), 16);
            const b = parseInt(hexcolor.substr(4, 2), 16);
            const yiq = (r * 299 + g * 587 + b * 114) / 1000;
            return yiq >= 128 ? 'black' : 'white';
        }
    }
};
</script>

<style lang="scss" scoped>
.text-value {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-basis: auto;
    margin-bottom: 0.75rem;

    .text {
        text-align: end;
        margin-right: 0.5rem;
        margin-bottom: 0;
    }

    .tag {
        color: #ffffff;
    }
}
</style>
