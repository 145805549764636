const DONUT_CHART_OPTIONS = {
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    plotOptions: {
        pie: {
            offsetY: 33,
            size: 80,
            donut: {
                size: '60%'
            }
        }
    },
    states: {
        hover: {
            filter: {
                type: 'none'
            }
        }
    }
};
const LINE_CHART_OPTIONS = {
    chart: {
        id: null,
        group: null,
        toolbar: {
            show: true,
            tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
            }
        }
    },
    xaxis: {
        type: 'datetime',
        categories: null
    },
    yaxis: {
        labels: {
            minWidth: 45
        }
    },
    tooltip: {
        y: {
            formatter: val => val.toFixed(0)
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: 2,
        curve: 'smooth'
    },
    fill: {
        opacity: 0.35
    },
    colors: null,
    states: {
        hover: {
            filter: {
                type: 'none'
            }
        }
    }
};
const BAR_CHART_OPTIONS = {
    dataLabels: {
        enabled: false
    },
    states: {
        hover: {
            filter: {
                type: 'none'
            }
        }
    }
};
const HEATMAP_CHART_OPTIONS = {
    xaxis: {
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        }
    },
    dataLabels: {
        enabled: false
    },
    tooltip: {
        y: {
            formatter: val => val.toFixed(0)
        }
    },
    plotOptions: {
        heatmap: {
            radius: 3
        }
    },
    grid: {
        padding: {
            left: 0,
            right: 0
        }
    },
    yaxis: {
        labels: {
            minWidth: 45
        }
    }
};

export { DONUT_CHART_OPTIONS, LINE_CHART_OPTIONS, BAR_CHART_OPTIONS, HEATMAP_CHART_OPTIONS };
