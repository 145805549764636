import { USER_PERMISSIONS } from '../../app/constants/em';

class User {
    constructor(email, data) {
        this.email = email;
        this.picture = data.picture;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.firstLog = data.firstLog;
        this.googleUserId = data.googleUserId;
        this.shortId = data.shortId;
        this.config = data.config;
        this.client = data.client;
        this.isTeamMember = data.isTeamMember;
        this._permissions = data.permissions;
    }

    fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    isGSuite() {
        return this.email.indexOf('@gmail.com') === -1 && this.email.indexOf('@googlemail.com') === -1;
    }

    permissions(permissionName) {
        return this._permissions[permissionName];
    }
}

class UserConfig {
    constructor(data) {
        this.batchDone = data.batchDone;
        this.timezone = data.timezone;
        this.emailMonthly = data.emailMonthly;
        this.emailWeekly = data.emailWeekly;
        this.businessHoursEnabled = data.businessHoursEnabled;
        this.businessHours = data.businessHours;
    }
}

class UserClient {
    constructor(data) {
        this.productName = data.productName;
        this.hasActiveSubscription = data.hasActiveSubscription;
        this.hasStripeCustomer = data.hasStripeCustomer;
    }
}

export { User, UserConfig, UserClient, USER_PERMISSIONS };
