<template>
    <div class="column mx-auto">
        <div class="box">
            <h6 class="title is-6">Who do you want Email Meter for?</h6>
            <div class="columns is-variable is-1 is-multiline is-mobile">
                <div class="column is-half">
                    <div class="plan-box" :class="{ 'active-plan': selectedProdcut === PRODUCT.premium }" @click="selectProduct(PRODUCT.premium)">
                        <p>For me</p>
                        <img src="../../../resources/static/images/illustration_individual.svg" />
                    </div>
                </div>
                <div class="column is-half">
                    <div class="plan-box" :class="{ 'active-plan': selectedProdcut === PRODUCT.team }" @click="selectProduct(PRODUCT.team)">
                        <p>For me & my Team</p>
                        <img src="../../../resources/static/images/illustration_team.svg" />
                    </div>
                </div>
                <div v-if="selectedProdcut === PRODUCT.premium" class="column is-full">
                    <button class="button is-primary is-medium is-fullwidth" @click="$emit('press-continue', PRODUCT.premium, emails)">Continue</button>
                </div>
            </div>
        </div>
        <div v-if="selectedProdcut === PRODUCT.team" id="create-team" class="box">
            <h6 class="title is-6">Create a Team</h6>
            <p class="mb-6">Great, now enter the email address of each Team member you’d like to add. You’ll be charged ${{ toCharge }} per Team member per {{ selectedPlan.noun }}.</p>
            <add-members-input @list-change="onListChange"></add-members-input>
            <button class="button is-primary is-medium is-fullwidth" :disabled="emails.length === 0" @click="$emit('press-continue', PRODUCT.team, emails)">Continue</button>
        </div>
    </div>
</template>

<script>
import { STRIPE_PLANS, STRIPE_PRICING } from '../../models/billing';
import AddMembersInput from '../team/add_members_input.vue';

export default {
    name: 'SelectProducts',
    components: {
        AddMembersInput
    },
    props: {
        preSelectedProduct: {
            type: String,
            default: null
        },
        selectedPlan: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            selectedProdcut: null,
            emails: [],
            PRODUCT: {
                premium: 'Premium',
                team: 'Team'
            },
            STRIPE_PLANS
        };
    },
    computed: {
        toCharge() {
            let billedMonths = 1;
            if (this.selectedPlan.id === STRIPE_PLANS.yearlyJune2019.id) billedMonths = 12;
            return STRIPE_PRICING[this.selectedPlan.id] * billedMonths;
        }
    },
    beforeMount() {
        this.selectedProdcut = this.preSelectedProduct;
    },
    methods: {
        selectProduct(product) {
            this.selectedProdcut = product;
            this.emails = [];
            this.$emit('select-product', product);
            this.$emit('emails-updated', this.emails);
        },
        onListChange(emails) {
            this.emails = emails;
            this.$emit('emails-updated', emails);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.plan-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-radius: $radius;
    border: 2px solid $grey-light;

    &:hover {
        cursor: pointer;
    }

    p {
        text-align: center;
        font-weight: $weight-bold;
        color: $grey-darker;
        font-size: $size-8;
    }
}

.active-plan {
    border: 2px solid $primary;

    p {
        color: $primary;
    }
}
</style>
