import ApiBase from './api_base';
import ReferralParser from './parsers/referral_parser';

const ENDPOINT_BQIP = process.env.ENDPOINT_BQIP;

export default class ReferralApi extends ApiBase {
    static getStatus() {
        return this.fetchData('/api/referral/status/').then(data => {
            return ReferralParser.parseStatus(data);
        });
    }

    static getSuggestions() {
        return this.fetchData('/bqi/suggestions', ENDPOINT_BQIP);
    }

    static createReferralInvitation(email) {
        return this.postData('/api/referral/invitations/', {
            sent_to: email // eslint-disable-line camelcase
        });
    }

    static redeemPremium() {
        return this.postData('/api/referrals/invitations/redeem/', {});
    }
}
