<template>
    <div class="columns is-multiline">
        <div class="column is-full has-text-centered">
            <h2 class="title is-2 is-size-5-mobile mb-2">My Account</h2>
        </div>
        <div v-if="customer !== null" class="column is-full">
            <div class="tile is-ancestor">
                <div class="tile">
                    <div class="tile is-4 is-parent mx-auto">
                        <account-card id="plan" title="Your Plan">
                            <template v-slot:card-content>
                                <div class="flex-1">
                                    <p>{{ subscription.planName }}</p>
                                    <template v-if="teamData">
                                        <p v-if="teamData.active">{{ teamData.active }} Team Members</p>
                                        <div v-if="teamData.inactive" class="is-flex">
                                            {{ teamData.inactive }} Removed Team Members
                                            <span class="icon ml-1 has-tooltip-bottom cursor-pointer" :data-tooltip="teamData.tooltipText">
                                                <i class="em-icon info"></i>
                                            </span>
                                        </div>
                                    </template>
                                </div>
                                <p v-if="subscription.cancelAtPeriodEnd" class="ml-auto">Your premium plan was canceled. This subscription expires at {{ subscriptionPeriodEnd | formatDate }}.</p>
                                <p class="ml-auto has-text-weight-bold total-amount">{{ pricingString(undefined, 2) }}</p>
                            </template>
                            <template v-if="!teamData || (teamData && teamData.active === 0 && teamData.inactive === 0)" v-slot:card-footer-item>
                                <router-link v-if="!subscription.id" class="button is-transparent has-text-primary" to="/get-premium/">Reactivate Premium</router-link>
                                <a v-else-if="!subscription.cancelAtPeriodEnd" id="barecancel-trigger" class="button is-transparent has-text-danger">Cancel plan</a>
                                <a v-else class="button is-transparent has-text-primary" @click="showReactivatePlan()">Reactivate</a>
                            </template>
                        </account-card>
                    </div>
                    <div v-if="subscription.id && customer.source" class="tile is-4 is-parent">
                        <account-card id="payment-method" title="Payment Method">
                            <template v-slot:card-content>
                                <div class="is-flex">
                                    <img :src="CREDIT_CARD[customer.source.creditCardBrand]" width="30px" height="auto" class="mr-2" alt="Credit card brand" />
                                    <div>
                                        <p>•••• {{ customer.source.creditCardLast4 }}</p>
                                        <p>{{ customer.source.expMonth }}/{{ customer.source.expYear }}</p>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:card-footer-item>
                                <a class="button is-transparent has-text-primary" @click="showEditPaymentMethod">
                                    <span class="icon">
                                        <i class="em-icon edit"></i>
                                    </span>
                                    <span>Edit</span>
                                </a>
                            </template>
                        </account-card>
                    </div>
                    <div v-if="subscription.id" class="tile is-4 is-parent">
                        <account-card id="billing-info" title="Billing Information">
                            <template v-slot:card-content>
                                <p>{{ customer.address.name }}</p>
                                <p>{{ customer.email }}</p>
                                <p>{{ customer.address.line1 }}</p>
                                <p v-if="customer.address.city && customer.address.state && customer.address.postalCode">
                                    {{ customer.address.city }}, {{ customer.address.state }} {{ customer.address.postalCode }}
                                </p>
                                <p v-if="customer.address.country">{{ getCountryName(customer.address.country) }}</p>
                            </template>
                            <template v-slot:card-footer-item>
                                <a class="button is-transparent has-text-primary" @click="showEditBillingInfo">
                                    <span class="icon">
                                        <i class="em-icon edit"></i>
                                    </span>
                                    <span>Edit</span>
                                </a>
                            </template>
                        </account-card>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="is-flex flex-1 items-center justify-center">
            <spinner></spinner>
        </div>
    </div>
</template>

<script>
import countryList from 'country-list';
import { mapGetters, mapState } from 'vuex';

import PAGE_NAMES from '../../router/pages';
import Log from '../../utils/log';
import modalsEnum from '../../utils/modals_enum';
import Spinner from '../base/spinner.vue';
import AccountCard from './account_card.vue';

const CREDIT_CARD = {
    'American Express': require('../../../resources/static/images/icons/americanexpress_card.svg'),
    'MasterCard': require('../../../resources/static/images/icons/mastercard_card.svg'),
    'Visa': require('../../../resources/static/images/icons/visa_card.svg')
};

export default {
    components: {
        AccountCard,
        Spinner
    },
    data() {
        return {
            CREDIT_CARD: CREDIT_CARD
        };
    },
    computed: {
        ...mapState('billing', ['customer']),
        ...mapGetters('billing', ['subscriptionPeriodEnd', 'pricingString']),
        subscription() {
            return this.customer.subscription || {};
        },
        teamData() {
            const team = this.$store.getters['team/firstTeam'];

            if (team) {
                const inactiveTeamMembers = team.members.filter(member => member.expiresAt !== null);
                let tooltipText = null;
                if (inactiveTeamMembers.length !== 0) {
                    const formattedDate = this.$root.$options.filters.formatDate(inactiveTeamMembers[0].expiresAt);
                    tooltipText = `You'll lose access to those accounts from your next billing cycle: ${formattedDate}`;
                }
                return {
                    active: team.members.length - inactiveTeamMembers.length + team.invitations.length,
                    inactive: inactiveTeamMembers.length,
                    tooltipText: tooltipText
                };
            }

            return null;
        }
    },
    mounted() {
        /* eslint-disable camelcase, no-unused-expressions, no-sequences */
        if (window.barecancel && window.barecancel.created) {
            Log.warn('Barecancel snippet included twice.');
        } else {
            window.barecancel = { created: !0 };
            const a = document.createElement('script');
            (a.src = 'https://baremetrics-barecancel.baremetrics.com/js/application.js'), (a.async = !0);
            const b = document.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(a, b),
                (window.barecancel.params = {
                    access_token_id: process.env.BAREMETRICS_KEY,
                    subscription_oids: [this.subscription.id],

                    callback_send: () => {
                        this.cancelPlan();
                    },

                    callback_error(data) {
                        Log.error(new Error('Error while canceling subscription in barecancel.'), { data: data });
                    }
                });
        }
        /* eslint-enable camelcase, no-unused-expressions, no-sequences */
    },
    methods: {
        showReactivatePlan() {
            this.$bus.emit(this.$bus.events.showModal, modalsEnum.reactivatePlan);
        },
        showEditPaymentMethod() {
            this.$bus.emit(this.$bus.events.showModal, modalsEnum.editPaymentMethod);
        },
        showEditBillingInfo() {
            this.$bus.emit(this.$bus.events.showModal, modalsEnum.editBillingInfo);
        },
        getCountryName(abbr) {
            return countryList.getName(abbr);
        },
        cancelPlan() {
            const subscriptionId = this.subscription.id;
            this.requestLoading = true;
            this.$store
                .dispatch('billing/updateSubscription', {
                    subscriptionId: subscriptionId,
                    action: 'cancel'
                })
                .then(() => {
                    this.$router.push({
                        name: PAGE_NAMES.cancelled
                    });
                })
                .catch(() => {
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
p {
    margin-bottom: 0;
}
</style>
