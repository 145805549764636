<template>
    <div>
        <component
            :is="allInvitations[modalKind].length === 0 ? 'span' : 'a'"
            v-for="modalKind in Object.keys(MODAL_TYPES)"
            :key="modalKind"
            :class="allInvitations[modalKind].length === 0 ? 'cursor-default' : 'underline'"
            class="has-text-white mx-1"
            @click="showInvitationsModal(MODAL_TYPES[modalKind])"
        >
            {{ allInvitations[modalKind].length }} {{ FOOTER_TEXT[modalKind] }}
        </component>
    </div>
</template>

<script>
import { INVITATION_STATUS, PURCHASES_STATUS } from '../../../models/referral';
import modalsEnum from '../../../utils/modals_enum';

export default {
    name: 'ReferralHeaderFooterNumbers',
    props: {
        invitations: {
            type: Object,
            required: true
        },
        purchases: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            INVITATION_STATUS,
            PURCHASES_STATUS,
            MODAL_TYPES: {
                ACCEPTED_INVITATION: 'acceptedInvitations',
                PENDING_INVITATION: 'pendingInvitations',
                ACCEPTED_PURCHASES: 'acceptedPurchases'
            },
            FOOTER_TEXT: {
                ACCEPTED_INVITATION: 'accepted',
                PENDING_INVITATION: 'pending',
                ACCEPTED_PURCHASES: 'purchased'
            }
        };
    },
    computed: {
        allInvitations() {
            return {
                ACCEPTED_INVITATION: this.acceptedInvitations,
                PENDING_INVITATION: this.pendingInvitations,
                ACCEPTED_PURCHASES: this.acceptedPurchases
            };
        },
        acceptedInvitations() {
            return this.invitations[INVITATION_STATUS.ACCEPTED].map(i => ({ email: i, consumed: false })).concat(this.invitations[INVITATION_STATUS.CONSUMED].map(i => ({ email: i, consumed: true })));
        },
        pendingInvitations() {
            return this.invitations[INVITATION_STATUS.PENDING].map(i => ({ email: i, consumed: false }));
        },
        acceptedPurchases() {
            return this.purchases[PURCHASES_STATUS.AVAILABLE].map(i => ({ email: i, consumed: false })).concat(this.purchases[PURCHASES_STATUS.CONSUMED].map(i => ({ email: i, consumed: true })));
        }
    },
    methods: {
        showInvitationsModal(contentType) {
            if (this[contentType].length > 0) {
                const modalData = {
                    CONTENT_TYPE: contentType,
                    modalContent: this[contentType],
                    isLarge: true
                };
                this.$bus.emit(this.$bus.events.showModal, modalsEnum.referralInvitations, modalData);
            }
        }
    }
};
</script>
