<template>
    <div class="columns is-multiline">
        <div class="column is-full has-text-centered relative">
            <h2 class="title is-3">Let’s create your Team!</h2>
        </div>
        <div class="column is-6 mx-auto">
            <div class="box">
                <p class="mb-4">
                    Get started by entering the email address of each Team member you’d like to add. Add as many as you like.
                    <b
                        >You’ll be charged <span class="has-text-primary">${{ isYearly ? `${STRIPE_PRICING.yearlyJune2019 * 12}/year` : `${STRIPE_PRICING.monthlyJune2019}/month` }} per user</span></b
                    >.
                </p>
                <add-members-input @list-change="onListChange"></add-members-input>
                <button class="button is-medium is-primary is-fullwidth" :disabled="!isContinueEnabled" @click="showConfirmModal()">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import addMembersMixin from '../team/mixins/add_members_mixin';

export default {
    mixins: [addMembersMixin],
    computed: {
        isYearly() {
            return this.$store.state.billing.customer.subscription.interval === 'year';
        }
    }
};
</script>
