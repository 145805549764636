<template>
    <div v-if="!showAdvancedSettings" class="has-text-centered">
        <button class="button is-primary is-outlined" @click="showAdvancedSettings = true">Show Advanced Settings</button>
    </div>
    <div v-else class="columns is-multiline advanced-settings">
        <div class="column is-full">
            <hr />
        </div>
        <div class="column is-full has-text-centered">
            <h2 class="title is-2 is-size-5-mobile mb-2">Advanced Settings</h2>
        </div>
        <div class="column is-full">
            <div class="box">
                <level-setting
                    id="delete-account-setting"
                    icon=""
                    title="Delete Account"
                    subtitle="All your reports, metrics and profile information will be gone forever if you delete your Email Meter account."
                    class="is-mobile"
                >
                    <template v-slot:field>
                        <button class="button is-danger" @click="deleteAccount()">Delete</button>
                    </template>
                </level-setting>
            </div>
        </div>
    </div>
</template>

<script>
import MODALS_ENUM from '../../utils/modals_enum';
import LevelSetting from './level_setting.vue';

export default {
    name: 'AdvancedSettings',
    components: {
        LevelSetting
    },
    data() {
        return {
            showAdvancedSettings: false
        };
    },
    methods: {
        deleteAccount() {
            this.$bus.emit(this.$bus.events.showModal, MODALS_ENUM.accountDeleteConfirm);
        }
    }
};
</script>

<style lang="scss" scoped>
p:last-child {
    margin-bottom: 0;
}

.level:not(:last-child) {
    border-bottom: 1px solid #f9f9f9;
    padding-bottom: 1.5rem;
}

.setting-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    color: #333333;
}

.setting-subtitle {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.54);
}
</style>
