<template>
    <!-- eslint-disable max-len -->
    <div class="modal is-active is-large">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div id="multi-form" class="is-flex flex-col">
                <div class="columns is-gapless mb-0">
                    <div class="column">
                        <div class="has-background-primary rounded-t p-8">
                            <slot name="header"></slot>
                        </div>
                    </div>
                </div>
                <div class="columns flex-1 is-gapless">
                    <div class="column is-8-tablet">
                        <div class="p-8 h-full is-flex flex-col has-background-white rounded-bl">
                            <div class="flex-1 mb-4">
                                <h2 class="title is-5 mb-8">{{ currentForm.title }}</h2>

                                <template v-if="currentForm.choicesClass === 'button-selector-with-icon'">
                                    <onboarding-option-button
                                        v-for="choice in currentForm.choices"
                                        :key="choice.value"
                                        :is-selected="isChoiceSelected(choice.value)"
                                        :label="choice.label"
                                        :icon-class="choice.icon"
                                        @click="selectChoice(choice.value)"
                                    ></onboarding-option-button>
                                </template>
                                <template v-else>
                                    <div :class="currentForm.choicesClass === 'button-selector-rounded-inline' ? 'buttons' : 'is-flex flex-col items-start'">
                                        <button
                                            v-for="choice in currentForm.choices"
                                            :key="choice.value"
                                            class="button is-white is-rounded has-text-weight-bold mb-4 shadow hover:shadow-3"
                                            :class="{
                                                'is-hovered is-primary': isChoiceSelected(choice.value)
                                            }"
                                            @click="selectChoice(choice.value)"
                                        >
                                            {{ choice.label }}
                                        </button>
                                    </div>
                                </template>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="is-flex-mobile justify-center">
                                        <button v-if="currentFormIndex > 0" class="button is-medium is-outlined is-primary" @click="prevForm()">
                                            <slot name="back-button-text"></slot>
                                        </button>
                                    </div>
                                </div>
                                <div class="column is-narrow">
                                    <div class="is-flex-mobile justify-center">
                                        <a class="button is-medium is-primary-lighter" @click="nextForm()">
                                            <slot name="next-button-text"></slot>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4-tablet">
                        <div v-if="!isMobile" class="py-6 px-4 h-full is-flex flex-col has-background-grey-lighter rounded-br">
                            <div class="flex-1">
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p><span v-html="currentForm.hint.header"></span></p>
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p class="is-size-8"><span v-html="currentForm.hint.subheader"></span></p>
                            </div>
                            <div class="is-flex flex-col items-center">
                                <img :src="getFormHintPicture" width="160px" alt="Hint picture" />
                                <hr class="w-full" />
                                <slot name="hint-footer"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMobile" class="paginator relative is-flex justify-center flex-grow-0">
                <span v-for="page in forms.length" :key="page" class="has-text-white page-selector" :class="{ 'has-text-black-bis': page - 1 === currentFormIndex }">&bull;</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import images from '../../../resources/static/images/onboarding/*.png';
import OnboardingOptionButton from './option_button.vue';

export default {
    name: 'MultiForm',
    components: { OnboardingOptionButton },
    props: {
        forms: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            currentFormIndex: 0,
            selectedChoices: {}
        };
    },
    computed: {
        ...mapGetters(['isMobile']),
        currentForm() {
            return this.forms[this.currentFormIndex];
        },
        getFormHintPicture() {
            return images[this.currentForm.hint.picture];
        }
    },
    methods: {
        isChoiceSelected(value) {
            return this.selectedChoices[this.currentFormIndex] === value;
        },
        prevForm() {
            this.currentFormIndex--;
        },
        nextForm() {
            if (this.currentFormIndex === this.forms.length - 1) {
                this.$emit('form-dismissed', true);
            } else {
                this.currentFormIndex++;
            }
        },
        selectChoice(value) {
            this.$emit('form-selected-choice', this.currentFormIndex, value);
            this.selectedChoices[this.currentFormIndex] = value;
            this.nextForm();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

.modal {
    &.is-large > .modal-content {
        overflow: visible;
    }

    @include mobile {
        &.is-large > .modal-content {
            overflow: auto;
            max-height: none;
        }
    }
}

.page-selector {
    opacity: 0.6;
    font-size: 2.5rem;
}

#multi-form {
    height: 690px;

    .paginator {
        top: 24px;
        height: 0;
    }
}
</style>
