export default {
    account: 'account',
    cancelled: 'cancelled',
    comingSoon: 'coming-soon',
    genericPaywall: 'generic-paywall',
    getPremium: 'get-premium',
    referral: 'referral',
    report: 'report',
    settings: 'settings',
    team: 'team'
};
