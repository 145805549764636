<template>
    <fieldset v-if="billingData">
        <div class="field">
            <label class="label">First and last name</label>
            <div class="control">
                <input v-model="billingData.shipping.name" class="input name-input" :class="{ 'is-danger': errors.name }" type="text" placeholder="Name" />
                <p v-if="errors.name" class="help is-danger">{{ errors.name }}</p>
            </div>
        </div>
        <div class="field">
            <label class="label">Email address</label>
            <div class="control">
                <input v-model="billingData.email" class="input email-input" :class="{ 'is-danger': errors.email }" type="email" placeholder="Email" />
                <p v-if="errors.email" class="help is-danger">{{ errors.email }}</p>
            </div>
        </div>
        <!--div class="field">
            <label class="label">VAT Number</label>
            <div class="control">
                <input class="input" :class="{ 'is-danger': errors. }" type="text" v-model="billingData.vat" placeholder="B123456789">
            </div>
        </div-->
        <div class="field is-grouped is-grouped-multiline">
            <div class="control is-expanded">
                <label class="label">Country</label>
                <div class="select is-fullwidth">
                    <select v-model="billingData.shipping.address.country" class="country-select">
                        <option disabled selected value>Select a country</option>
                        <option v-for="country in countryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                    </select>
                    <p v-if="errors.country" class="help is-danger">{{ errors.country }}</p>
                </div>
            </div>
            <div class="control">
                <label class="label">State / Province</label>
                <input v-model="billingData.shipping.address.state" class="input state-input" :class="{ 'is-danger': errors.state }" type="text" placeholder="State or Province" />
                <p v-if="errors.state" class="help is-danger">{{ errors.state }}</p>
            </div>
        </div>
        <div class="field">
            <label class="label">Address</label>
            <div class="control">
                <input v-model="billingData.shipping.address.line1" class="input address-input" :class="{ 'is-danger': errors.line1 }" type="text" placeholder="Street name & number" />
                <p v-if="errors.line1" class="help is-danger">{{ errors.line1 }}</p>
            </div>
        </div>
        <div class="field is-grouped is-grouped-multiline">
            <div class="control is-expanded">
                <label class="label">City</label>
                <input v-model="billingData.shipping.address.city" class="input city-input" :class="{ 'is-danger': errors.city }" type="text" placeholder="City" />
                <p v-if="errors.city" class="help is-danger">{{ errors.city }}</p>
            </div>
            <div class="control">
                <label class="label">ZIP Code</label>
                <input v-model="billingData.shipping.address.postal_code" class="input zip-input" :class="{ 'is-danger': errors.postalCode }" type="text" placeholder="00000" />
                <p v-if="errors.postalCode" class="help is-danger">{{ errors.postalCode }}</p>
            </div>
        </div>
    </fieldset>
</template>

<script>
import countryList from 'country-list';
import { mapState } from 'vuex';

import utils from '../../utils/utils';

export default {
    name: 'Billing',
    data() {
        return {
            countryList: countryList.getData(),
            billingData: null,
            errors: null,
            isFormValid: false
        };
    },
    computed: {
        ...mapState('billing', ['customer'])
    },
    watch: {
        billingData: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal) {
                    this.$emit('billing', {
                        billingData: newVal,
                        isFormValid: this.validateForm()
                    });
                }
            }
        }
    },
    mounted() {
        this.billingData = {
            email: this.customer.email,
            shipping: {
                address: {
                    line1: this.customer.address.line1,
                    city: this.customer.address.city,
                    country: this.customer.address.country,
                    'postal_code': this.customer.address.postalCode,
                    state: this.customer.address.state
                },
                name: this.customer.address.name
            }
        };
    },
    methods: {
        /* eslint-disable complexity */
        validateForm() {
            this.errors = {};

            if (this.billingData) {
                if (!this.billingData.shipping.name) {
                    this.errors.name = 'Name required.';
                }

                if (!this.billingData.email) {
                    this.errors.email = 'Email required.';
                } else if (!utils.validateEmail(this.billingData.email)) {
                    this.errors.email = 'Invalid email.';
                }

                if (!this.billingData.shipping.address.country) {
                    this.errors.country = 'Country required.';
                }

                if (!this.billingData.shipping.address.state) {
                    this.errors.state = 'State required.';
                }

                if (!this.billingData.shipping.address.line1) {
                    this.errors.line1 = 'Address required.';
                }

                if (!this.billingData.shipping.address.city) {
                    this.errors.city = 'City required.';
                }

                if (!this.billingData.shipping.address.postal_code) {
                    this.errors.postalCode = 'Postal code required.';
                }

                if (!Object.keys(this.errors).length) {
                    return true;
                }
            }

            return false;
        }
        /* eslint-disable complexity */
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

@include mobile {
    .field {
        &.is-grouped > .control:not(:last-child) {
            margin-right: 0;
        }
        &.is-grouped-multiline {
            .control {
                flex-grow: 1;
            }

            > .control {
                &:last-child {
                    margin-bottom: 1.25rem;
                }
                &:not(:last-child) {
                    margin-bottom: 1.25rem;
                }
            }
        }
    }
}
@include tablet {
    .field.is-grouped.is-grouped-multiline {
        flex-wrap: nowrap;

        > .control:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }
}
</style>
