<template>
    <section id="cancel-premium" class="section">
        <div class="box">
            <div class="columns is-centered">
                <div class="column is-three-fifths">
                    <h2 class="is-size-3 mb-8">{{ user.firstName }}, we are sorry to see you go!</h2>
                    <p>
                        <b>Your subscription renovation has been successfully cancelled.</b>
                        This means your subscription will end on {{ subscriptionPeriodEnd | formatDate }}. Remember after that date you can still use Email Meter Basic for your Monthly Reports.
                    </p>
                    <p class="mb-8">Hope to see you here again.</p>
                    <div class="has-text-centered">
                        <router-link class="button is-primary" to="/account">Go to My Account</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'Cancelled',
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('billing', ['subscriptionPeriodEnd'])
    }
};
</script>
