import { Team, TEAM_MEMBER_STATUS, TeamMember } from '../../models/team';

export default class TeamParser {
    static parseTeams(data) {
        return data.results.map(t => {
            const teamMembersParsed = this._parseTeamMembers(t.members);
            const teamLeaderParsed = this._parseTeamMembers([t.leader])[0];
            return new Team(
                {
                    id: t.id,
                    leader: teamLeaderParsed,
                    client: t.client,
                    name: t.name
                },
                teamMembersParsed
            );
        });
    }

    static _parseTeamMembers(members) {
        return members.map(member => {
            return new TeamMember({
                id: member.id,
                email: member.email,
                fullName: member.full_name,
                googleUserId: member.google_user_id,
                status: member.expires_at ? TEAM_MEMBER_STATUS.deleted : member.status,
                expiresAt: member.expires_at
            });
        });
    }
}
