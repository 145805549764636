import { User, UserClient, UserConfig } from '../../models/user';

function parseUserConfig(data) {
    return new UserConfig({
        batchDone: data.batch_done,
        timezone: data.timezone,
        emailMonthly: data.email_monthly,
        emailWeekly: data.email_weekly,
        businessHoursEnabled: data.business_hours_enabled,
        businessHours: data.business_hours
    });
}

function parseUserClient(data) {
    return new UserClient({
        productName: data.product,
        hasActiveSubscription: data.has_active_subscription,
        hasStripeCustomer: data.has_stripe_customer
    });
}

export default class UserParser {
    static parseUser(data) {
        const userConfigParsed = parseUserConfig(data);
        const userClientParsed = parseUserClient(data.client);
        return new User(data.email, {
            firstName: data.first_name,
            lastName: data.last_name,
            picture: data.picture,
            googleUserId: data.google_user_id,
            firstLog: data.first_log,
            config: userConfigParsed,
            client: userClientParsed,
            isTeamMember: data.is_team_member,
            permissions: data.permissions,
            shortId: data.short_id
        });
    }
}
