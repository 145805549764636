<template>
    <div class="box tile is-child flex-1 is-flex flex-col">
        <div v-if="metricName" class="columns">
            <div class="column is-flex items-center">
                <h6 class="title is-6 is-inline-block mr-1 mb-0">{{ metricName }}</h6>
                <metric-tooltip :metric-name="metricName" v-bind="$attrs"></metric-tooltip>
            </div>
        </div>
        <div v-if="metricsLoading" class="is-flex flex-col flex-1 justify-center items-center has-text-centered">
            <spinner></spinner>
            <template v-if="!initLoading">
                <p class="has-text-weight-bold mb-0 mt-4">
                    This is taking some time
                </p>
                <p>
                    Our robots are working to get the numbers
                </p>
            </template>
        </div>
        <template v-else-if="metricsLoadingFailed">
            <div class="is-flex flex-col flex-1 items-center justify-center has-text-centered">
                <img class="mb-4" src="../../../../resources/static/images/alert.svg" alt="Metric not loaded" />
                <p class="has-text-weight-bold mb-0">
                    We're having some trouble retrieving this data
                </p>
                <p>Our engineers have been notified and are working on it. Feel free to <a href="mailto:hello@emailmeter.com">get in touch</a> if you see this message again</p>
            </div>
        </template>
        <div v-if="metricsLoaded" class="columns flex-1">
            <div class="column is-flex items-center justify-center">
                <component :is="metricComponent" class="flex-1" :required-metrics="requiredMetrics"></component>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { STATUS } from '../../../api/parsers/bqi_parser';
import Spinner from '../../base/spinner.vue';
import AverageFirstResponse from './avg_first_response.vue';
import DirectMessages from './direct_messages.vue';
import EmailsReplied from './emails_replied.vue';
import InternalExternal from './internal_external.vue';
import MetricTooltip from './metric_tooltip.vue';
import NewThreads from './new_threads.vue';
import QuickestResponse from './quickest_response.vue';
import ReceivedHeatmap from './received_heatmap.vue';
import ReceivedHistogram from './received_histogram.vue';
import SentHeatmap from './sent_heatmap.vue';
import SentHistogram from './sent_histogram.vue';
import TimeBeforeFirstResponse from './time_before_first_response.vue';
import TopInteractions from './top_interactions.vue';

const metricComponents = {
    SentHistogram,
    ReceivedHistogram,
    TimeBeforeFirstResponse,
    QuickestResponse,
    AverageFirstResponse,
    EmailsReplied,
    InternalExternal,
    NewThreads,
    DirectMessages,
    TopInteractions,
    SentHeatmap,
    ReceivedHeatmap
};

export default {
    name: 'MetricWrapper',
    components: {
        MetricTooltip,
        Spinner
    },
    props: {
        metricName: {
            type: String,
            default: ''
        },
        requiredMetrics: {
            type: Array,
            required: true
        },
        metricComponentName: {
            type: String,
            default: 'dummy'
        }
    },
    data() {
        return {
            initLoading: true
        };
    },
    computed: {
        ...mapState('filter', ['metrics']),
        metricComponent() {
            return metricComponents[this.metricComponentName];
        },
        metricsLoaded() {
            return this.requiredMetrics.every(metricname => this.metrics[metricname].status === STATUS.LOADED);
        },
        metricsLoading() {
            return this.requiredMetrics.some(metricname => this.metrics[metricname].status === STATUS.LOADING);
        },
        metricsLoadingFailed() {
            return this.requiredMetrics.some(metricname => this.metrics[metricname].status === STATUS.ERROR);
        }
    },
    mounted() {
        setTimeout(() => {
            this.initLoading = false;
        }, 5000);
    }
};
</script>
