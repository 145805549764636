<template>
    <div>
        <div class="field is-grouped mb-5">
            <div class="control has-icons-left is-expanded">
                <input
                    ref="input"
                    v-model="inputEmail"
                    type="text"
                    class="input is-medium"
                    :class="{ 'is-danger': emailError !== '' }"
                    placeholder="Team member email"
                    :disabled="loading"
                    @keydown.enter="addEmail()"
                />
                <span class="icon is-left">
                    <i class="em-icon user"></i>
                </span>
                <p v-if="emailError" class="help is-danger mb-1">{{ emailError }}</p>
            </div>
            <div class="control">
                <button class="button is-primary is-outlined is-medium" :disabled="loading" @click="addEmail()">
                    <span class="icon">
                        <i class="em-icon user-add"></i>
                    </span>
                    <span>Add</span>
                </button>
            </div>
        </div>
        <div v-for="(email, index) in emails" :key="index" class="notification is-primary-lighter">
            <a class="button is-small is-transparent has-text-primary" @click="removeEmail(index)">
                <span class="icon is-small">
                    <i class="em-icon cancel is-size-9"></i>
                </span>
            </a>
            {{ email }}
        </div>
    </div>
</template>

<script>
import TeamApi from '../../api/team_api';
import utils from '../../utils/utils';

export default {
    name: 'AddMembersInput',
    data() {
        return {
            emailError: '',
            inputEmail: '',
            emails: [],
            loading: false
        };
    },
    watch: {
        inputEmail: {
            handler() {
                this.emailError = '';
            }
        }
    },
    methods: {
        async addEmail() {
            if (!this.loading) {
                this.loading = true;
                const email = this.inputEmail;
                const isValid = await this.validateInput(email);
                if (isValid) {
                    this.emails.push(email);
                    this.inputEmail = '';
                    this.$emit('list-change', this.emails);
                }
                this.loading = false;
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        },
        removeEmail(index) {
            this.emails.splice(index, 1);
            this.$emit('list-change', this.emails);
        },
        async validateInput(email) {
            this.emailError = '';
            if (this.emails.includes(email)) {
                this.emailError = 'Oops, you already added that email address';
                return false;
            } else if (!email) {
                this.emailError = 'Enter an email.';
                return false;
            } else if (!utils.validateEmail(email.toLocaleLowerCase())) {
                this.emailError = 'Oops, looks like this email address is incorrect';
                return false;
            }

            const canInvite = await TeamApi.canInviteEmail(email)
                .then(() => {
                    return true;
                })
                .catch(({ resJSON }) => {
                    this.emailError = resJSON.message;
                    return false;
                });

            return canInvite;
        }
    }
};
</script>

<style lang="scss" scoped>
.notification {
    margin-bottom: 1.25rem;
    padding: 0.75rem;

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    > .button {
        position: absolute;
        right: 0.45rem;
        top: 0.45rem;
    }
}
</style>
