<template>
    <base-filter id="date-filter" icon="em-icon date" header="DATE RANGE">
        <template #description>
            <p>
                Select any week between <b>{{ getWeekStart.unix() | formatDate }}</b> and <b>{{ getWeekEnd.unix() | formatDate }}</b
                >.
                <premium-cta></premium-cta>
            </p>
        </template>
        <template #buttons>
            <button class="button is-small" :class="{ 'is-primary': isLastWeek, 'is-light': !isLastWeek }" @click="selectPastWeek()">Past Week</button>
        </template>
        <template #filter>
            <datepicker
                ref="calendar"
                maximum-view="day"
                :inline="true"
                :use-utc="true"
                :open-date="currentSpan.from"
                wrapper-class="calendar"
                :disabled-dates="{ customPredictor: disableDate }"
                :highlighted="currentSpan"
                @input="dateSelected"
            ></datepicker>
        </template>
    </base-filter>
</template>

<script>
import dayjs from 'dayjs';
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex';

import dateHelpers from '../../utils/date_helpers';
import BaseFilter from './base_filter.vue';
import filtersMixin from './mixins/filters_mixin';
import PremiumCta from './premium_cta.vue';

export default {
    name: 'WeeklyFilter',
    components: {
        BaseFilter,
        Datepicker,
        PremiumCta
    },
    mixins: [filtersMixin],
    data() {
        return {
            currentSpan: {
                from: null,
                to: null
            },
            initialized: false
        };
    },
    computed: {
        ...mapState('user', ['user']),
        getWeekStart() {
            if (this.dataSpan.timestampFrom > this.dataSpan.timestampFrom.clone().startOf('week')) {
                return this.dataSpan.timestampFrom
                    .clone()
                    .add(1, 'week')
                    .startOf('week');
            } else {
                return this.dataSpan.timestampFrom.clone();
            }
        },
        getWeekEnd() {
            const lastPeriod = dateHelpers.getLastPeriod(this.dataSpan.timestampTo.unix(), 'week');
            return lastPeriod.endOf('week');
        },
        isLastWeek() {
            const startOfLastWeek = dayjs
                .utc()
                .subtract(1, 'week')
                .startOf('week');
            const endOfLastWeek = startOfLastWeek.clone().endOf('week');

            return (
                dayjs.utc(this.currentSpan.from).isSame(startOfLastWeek, 'day') &&
                dayjs
                    .utc(this.currentSpan.from)
                    .endOf('week')
                    .isSame(endOfLastWeek, 'day')
            );
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.currentSpan = {
                from: dayjs
                    .unix(this.$store.getters['filter/currentPageFilters'].dateFilter.timestampFrom)
                    .utc()
                    .toDate(),
                to: dayjs
                    .unix(this.$store.getters['filter/currentPageFilters'].dateFilter.timestampTo)
                    .utc()
                    .toDate()
            };
        });
    },
    methods: {
        disableDate(d) {
            const minDate = this.dataSpan.timestampFrom
                .clone()
                .add(1, 'week')
                .startOf('week');
            const maxDate = this.dataSpan.timestampTo
                .clone()
                .subtract(1, 'week')
                .endOf('week');
            return dayjs.utc(d).isBefore(minDate) || dayjs.utc(d).isAfter(maxDate);
        },
        dateSelected(d) {
            this.currentSpan.from = dayjs
                .utc(d)
                .startOf('week')
                .toDate();
            this.currentSpan.to = dayjs
                .utc(d)
                .endOf('week')
                .toDate();
            this.emitToParent(this.currentSpan);
        },
        selectPastWeek() {
            this.$refs.calendar.clearDate();

            // IMPROVE: not doing any check that we have data in the following span
            const startOfLastWeek = dayjs
                .utc()
                .subtract(1, 'week')
                .startOf('week');
            const endOfLastWeek = startOfLastWeek.clone().endOf('week');

            this.currentSpan = {
                from: startOfLastWeek.toDate(),
                to: endOfLastWeek.toDate()
            };

            this.emitToParent(this.currentSpan);
        }
    }
};
</script>
