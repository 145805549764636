function validateEmail(email) {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
}

function validateDomain(domain) {
    const re = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(domain);
}

function getValueOrNull(data, path) {
    const subPaths = path.split('.');

    if (data[subPaths[0]] && subPaths.length > 1) {
        return this.getValueOrNull(data[subPaths[0]], subPaths.slice(1).join('.'));
    } else if (data[subPaths[0]] === null || data[subPaths[0]] === undefined) {
        return null;
    } else {
        return data[subPaths[0]];
    }
}

function equalArrays(a, b) {
    if (a === b) return true;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function deepMergeObjects(target, source) {
    const result = {};
    for (const key of Object.keys(source))
        if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
            result[key] = deepMergeObjects((target || {})[key], source[key]);
        }
    return Object.assign({}, target || {}, source, result);
}

function formatCurrency(amount, decimals = 2, currency = '$') {
    return `${currency}${(amount / 100).toFixed(decimals)}`;
}

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

function b64EncodeUnicode(str) {
    // More info: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_4_%E2%80%93_escaping_the_string_before_encoding_it
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(`0x${p1}`);
        })
    );
}

export default {
    b64EncodeUnicode,
    deepMergeObjects,
    equalArrays,
    formatCurrency,
    getValueOrNull,
    shuffle,
    validateDomain,
    validateEmail
};
