export default class PreviousReportComparison {
    constructor(newMetrics, previousMetrics) {
        this.newMetrics = newMetrics;
        this.previousMetrics = previousMetrics;
    }

    getMetricDiff(metricName) {
        const previousMetric = this.previousMetrics[metricName].value;
        const newMetric = this.newMetrics[metricName].value;
        if (!previousMetric || newMetric === undefined) return null;
        const diff = newMetric - previousMetric;
        const percentage = (diff / previousMetric) * 100;
        return Math.round(percentage);
    }
}
