<template>
    <div id="referral-invite" class="column is-full">
        <div class="box">
            <section class="section">
                <div class="columns is-multiline">
                    <div class="column is-full">
                        <h3 class="title is-3 is-size-4-mobile has-text-centered">Ready to spread productivity?</h3>
                    </div>
                    <div class="column is-7-tablet is-12-mobile is-flex justify-center items-center">
                        <figure class="image">
                            <img src="../../../../resources/static/images/illustration_referral_invite.svg" />
                        </figure>
                    </div>
                    <div class="column is-5-tablet is-12-mobile is-flex flex-col">
                        <transition-group id="suggestions-box" class="mb-4" name="fade" tag="div">
                            <suggestion
                                v-for="(suggestion, index) in fiveOwnSuggestions"
                                :key="suggestion.empty ? 'empty' : suggestion.email"
                                :empty="suggestion.empty"
                                :name="suggestion.name"
                                :email="suggestion.email"
                                @send-invitation="sendInvitation(suggestion.email, index)"
                                @remove-suggestion="removeSuggestion(index)"
                            ></suggestion>
                        </transition-group>
                        <div>
                            <p>
                                Invite friends that haven't discovered
                                <b>Email Meter</b> yet and that you interact with the most.
                            </p>
                            <div
                                class="field mb-0"
                                :class="{
                                    'opacity-4': invitationInputStatus === invitationInputStatuses.WORKING,
                                    'is-grouped-center': isMobile,
                                    'is-grouped': !isMobile
                                }"
                            >
                                <div class="control" :class="{ 'is-expanded': !isMobile, 'mb-2': isMobile }">
                                    <input
                                        v-model="emailNotInList"
                                        type="email"
                                        placeholder="Enter an email"
                                        class="input is-fullwidth"
                                        :class="{ 'is-danger': emailError }"
                                        @input="emailError = false"
                                        @keydown.enter="sendInvitation(emailNotInList)"
                                    />
                                </div>
                                <div class="control">
                                    <button
                                        class="button w-full"
                                        :class="{
                                            'is-outlined is-primary': invitationInputStatus === invitationInputStatuses.IDLE,
                                            'is-green': [invitationInputStatuses.WORKING, invitationInputStatuses.SENT].includes(invitationInputStatus),
                                            'is-danger': invitationInputStatus === invitationInputStatuses.ERROR
                                        }"
                                        @click="sendInvitation(emailNotInList)"
                                    >
                                        <span class="icon">
                                            <i
                                                class="em-icon"
                                                :class="{
                                                    'ok-circle': [invitationInputStatuses.WORKING, invitationInputStatuses.SENT].includes(invitationInputStatus),
                                                    'user-add': invitationInputStatus === invitationInputStatuses.IDLE,
                                                    'error-circle': invitationInputStatus === invitationInputStatuses.ERROR
                                                }"
                                            ></i>
                                        </span>
                                        <span>{{ buttonLabel }}</span>
                                    </button>
                                </div>
                            </div>
                            <div style="height: 21px" class="field">
                                <p v-if="emailError" class="help is-danger">{{ emailErrorText }}</p>
                            </div>
                        </div>
                    </div>
                    <div id="referral-invite-share" class="column is-full is-flex flex-col items-center">
                        <p>
                            Share your referral link and
                            <b>spread productivity</b>
                        </p>
                        <div class="field" :class="{ 'has-addons': !isMobile, 'w-full': isMobile }" @click="copyLink()">
                            <div class="control">
                                <input id="referral-invite-share-input" ref="referral-invite-share-input" type="text" class="input" :value="referralLink" readonly />
                            </div>
                            <div class="control" :class="{ 'mt-2': isMobile }">
                                <button class="button w-full has-background-enterprise has-text-white px-6">Copy link</button>
                            </div>
                        </div>
                    </div>
                    <div id="referral-social-share" class="column is-full is-flex justify-center">
                        <div class="buttons">
                            <social-button provider="linkedin" @social-click="shareOn"></social-button>
                            <social-button provider="facebook" @social-click="shareOn"></social-button>
                            <social-button provider="twitter" @social-click="shareOn"></social-button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SocialShare from '../../../api/social_share';
import utils from '../../../utils/utils';
import InviteSuggestionMixin from './mixins/referral_invite_suggestions_mixin';
import SocialButton from './social_button.vue';
import Suggestion from './suggestion.vue';

export default {
    name: 'ReferralInvite',
    components: {
        SocialButton,
        Suggestion
    },
    mixins: [InviteSuggestionMixin],
    props: {
        suggestions: {
            type: Array,
            required: true
        },
        shortId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            emailNotInList: '',
            emailError: false,
            emailErrorText: '',
            invitationInputStatuses: {
                IDLE: 'IDLE',
                WORKING: 'WORKING',
                SENT: 'SENT',
                ERROR: 'ERROR'
            },
            invitationInputStatus: 'IDLE'
        };
    },
    computed: {
        ...mapGetters(['isMobile']),
        referralLink() {
            return `${process.env.ENDPOINT_FRONTEND}/ref/${this.shortId}`;
        },
        buttonLabel() {
            switch (this.invitationInputStatus) {
                case this.invitationInputStatuses.IDLE:
                    return 'Invite';
                case this.invitationInputStatuses.WORKING:
                    return 'Sending';
                case this.invitationInputStatuses.SENT:
                    return 'Invited';
                default:
                    return 'Error';
            }
        }
    },
    methods: {
        shareOn(provider) {
            const share = new SocialShare(
                {
                    title: 'Boost your email productivity',
                    description: `How long do you take to reply to emails? What time of the day are you most productive?
                    Get these insights and more, for free, from Email Meter.`,
                    image: 'want_a_free_report.png'
                },
                'referral'
            );

            const msg = `The path to great email productivity starts with understanding your performance 📈\n
            I’ve been using @emailmeter for free email analytics, and you can too! Sign up here ⬇️\n\n`;

            const extraParams = { term: this.shortId, 'referral_id': this.shortId };
            const link = share.getLink(provider, extraParams, msg);
            this.openPopup(link);
        },
        openPopup(link) {
            window.open(link, 'popup', 'width=600,height=600');
        },
        copyLink() {
            const referralLinkInput = this.$refs['referral-invite-share-input'];
            referralLinkInput.focus();
            referralLinkInput.select();
            document.execCommand('copy');
        },
        sendInvitation(email, index) {
            // eslint-disable-line complexity
            const emailFromInput = index === undefined;
            if (email === '') {
                if (emailFromInput) {
                    this.emailErrorText = 'Enter an email.';
                    this.emailError = true;
                }
                return;
            }

            if (!utils.validateEmail(email)) {
                if (emailFromInput) {
                    this.emailErrorText = 'Oops, looks like this email address is incorrect';
                    this.emailError = true;
                }
                return;
            }

            this.emailError = '';
            if (emailFromInput) {
                this.emailNotInList = '';
                this.invitationInputStatus = this.invitationInputStatuses.WORKING;
                this.$emit('send-invitation', email, success => {
                    if (success) {
                        this.invitationInputStatus = this.invitationInputStatuses.SENT;
                    } else {
                        this.invitationInputStatus = this.invitationInputStatuses.ERROR;
                    }
                    setTimeout(() => {
                        this.invitationInputStatus = this.invitationInputStatuses.IDLE;
                    }, 2000);
                });
            } else {
                this.$emit('send-invitation', email);
                this.removeSuggestion(index);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

#referral-invite-share-input {
    min-width: 300px;
    pointer-events: none;
    cursor: none;
}

.has-background-enterprise {
    background-color: $enterprise-color;
}

.opacity-4 {
    opacity: 0.4;
}

.fade-item {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
