/* eslint-disable max-classes-per-file */
import utils from '../../utils/utils';
import Filter from './filter';

class AdvancedFilter extends Filter {
    constructor(data) {
        super(data.value);
    }

    static get validator() {
        return () => true;
    }

    isValid() {
        return this.value.every(this.constructor.validator);
    }
}

class ExclusiveAdvancedFilter extends AdvancedFilter {
    constructor(data) {
        super(data);
        this.excluded = data.excluded;
    }

    static get excludedOptions() {
        if (this.valueOptions) {
            return {
                'is': 'is',
                '-is': 'is not'
            };
        } else {
            return {
                'contains': 'contains',
                '-contain': 'not contain',
                'starts': 'starts with',
                'ends': 'ends with',
                'is': 'is',
                '-is': 'is not'
            };
        }
    }

    parse(value, key) {
        super.parse(value);
        this._parseKey(key);
    }

    serialize() {
        const key = `${this._formatExcluded()}${this.name}`;

        return this._serializeObject({
            [key]: this.value
        });
    }

    bqiSerialize() {
        const key = `${this._formatExcludedQueryPrefix()}${this.name}${this._formatExcludedQuerySuffix()}`;

        return this._serializeObject({
            [key]: this.value
        });
    }

    _parseKey(key) {
        const keys = Object.keys(ExclusiveAdvancedFilter.excludedOptions);
        this.excluded = keys.find(e => key.startsWith(e));
    }

    _formatExcluded() {
        return `${this.excluded}_`;
    }

    _formatExcludedQueryPrefix() {
        return {
            'contains': '',
            '-contain': 'exclude__',
            'starts': '',
            'ends': '',
            'is': '',
            '-is': 'exclude__'
        }[this.excluded];
    }

    _formatExcludedQuerySuffix() {
        return {
            'contains': '__contains',
            '-contain': '__contains',
            'starts': '__startswith',
            'ends': '__endswith',
            'is': '__is',
            '-is': '__is'
        }[this.excluded];
    }
}

class TargetedAdvancedFilter extends ExclusiveAdvancedFilter {
    constructor(data) {
        super(data);
        this.target = data.target;
    }

    static get excludedOptions() {
        return {
            'is': 'is',
            '-is': 'is not'
        };
    }

    static get targetOptions() {
        return {
            from: 'from',
            recipients: 'recipients',
            any: 'any'
        };
    }

    _parseOptions(str) {
        return str.split('_').slice(2);
    }

    _formatTarget() {
        if (this.target === 'any') {
            return '';
        } else {
            return `_${this.target}`;
        }
    }

    serialize() {
        const key = `${this._formatExcluded()}${this.name}${this._formatTarget()}`;

        return this._serializeObject({
            [key]: this.value
        });
    }

    bqiSerialize() {
        let target = `_${this.target}`;
        if (this.target === TargetedAdvancedFilter.targetOptions.any.toLowerCase()) {
            target = '';
        }
        const key = `${this._formatExcludedQueryPrefix()}${this.name}${target}${this._formatExcludedQuerySuffix()}`;
        return this._serializeObject({
            [key]: this.value
        });
    }

    parse(value, key) {
        super.parse(value, key);
        const opts = this._parseOptions(key);
        this.target = opts[0] || 'any';
    }
}

class DomainFilter extends TargetedAdvancedFilter {
    static get id() {
        return 'domain';
    }

    static get validator() {
        return utils.validateDomain;
    }
}

class AddressFilter extends TargetedAdvancedFilter {
    static get id() {
        return 'address';
    }

    static get validator() {
        return utils.validateEmail;
    }
}

class SubjectFilter extends ExclusiveAdvancedFilter {
    static get id() {
        return 'subject';
    }
}

class LabelsFilter extends ExclusiveAdvancedFilter {
    static get id() {
        return 'label';
    }

    static get valueOptions() {
        return {
            IMPORTANT: 'Important',
            STARRED: 'Starred',
            SPAM: 'Spam'
        };
    }
}

class StatusFilter extends ExclusiveAdvancedFilter {
    static get id() {
        return 'status';
    }

    static get valueOptions() {
        return {
            ARCHIVED: 'Archived',
            TRASHED: 'Trashed'
        };
    }

    bqiSerialize() {
        let bqiStatusFilter;
        if (this.value[0] === 'ARCHIVED') {
            bqiStatusFilter = this.clone();
            bqiStatusFilter.excluded = this.excluded === 'is' ? '-is' : 'is';
            bqiStatusFilter.value = ['INBOX'];
        } else bqiStatusFilter = this;

        // eslint-disable-next-line no-underscore-dangle
        const key = `${bqiStatusFilter._formatExcludedQueryPrefix()}label${bqiStatusFilter._formatExcludedQuerySuffix()}`;

        return this._serializeObject({
            [key]: bqiStatusFilter.value
        });
    }
}

class GmailTabFilter extends ExclusiveAdvancedFilter {
    static get id() {
        return 'gmailTab';
    }

    static get valueOptions() {
        return {
            CATEGORY_PERSONAL: 'Personal',
            CATEGORY_SOCIAL: 'Social',
            CATEGORY_PROMOTIONS: 'Promotions',
            CATEGORY_UPDATES: 'Notifications',
            CATEGORY_FORUMS: 'Forums'
        };
    }

    bqiSerialize() {
        const key = `${this._formatExcludedQueryPrefix()}label${this._formatExcludedQuerySuffix()}`;

        return this._serializeObject({
            [key]: this.value
        });
    }
}

class IntradomainFilter extends AdvancedFilter {
    static get id() {
        return 'intradomain';
    }

    static get valueOptions() {
        return {
            true: 'true',
            false: 'false'
        };
    }
}

class AutomatedFilter extends AdvancedFilter {
    static get id() {
        return 'automated';
    }

    static get valueOptions() {
        return {
            true: 'true',
            false: 'false'
        };
    }
}

export default {
    DomainFilter,
    AutomatedFilter,
    AddressFilter,
    IntradomainFilter,
    SubjectFilter,
    LabelsFilter,
    StatusFilter,
    GmailTabFilter,
    AdvancedFilter, // BASE CLASSES
    ExclusiveAdvancedFilter,
    TargetedAdvancedFilter
};

/* eslint-enable max-classes-per-file */
