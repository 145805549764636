<template>
    <div class="is-flex flex-col items-center">
        <h1 class="title is-4">You're ready to use the extension!</h1>
        <p class="is-size-6">Start enjoying the benefits of <b>Email Meter</b></p>
        <div class="my-8">
            <img src="../../../resources/static/images/modals/extension.png" />
        </div>
        <button class="button is-primary is-medium" @click="closeModal()">Get started now!</button>
    </div>
</template>

<script>
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'ExtensionModal',
    mixins: [ModalMixin]
};
</script>
