import { TeamInvitation } from '../../models/invitation';

export default class InvitationParser {
    static parseTeamInvitations(data) {
        return data.results
            .map(i => {
                return new TeamInvitation(this._dataAdapter(i));
            })
            .reduce((acc, elem) => {
                if (!acc[elem.teamId]) acc[elem.teamId] = [];
                acc[elem.teamId].push(elem);
                return acc;
            }, {});
    }

    static _dataAdapter(data) {
        return {
            id: data.id,
            kind: data.kind,
            inviterEmail: data.inviter_email,
            inviterName: data.inviter_name,
            sentTo: data.sent_to,
            status: data.status,
            teamId: data.team
        };
    }
}
