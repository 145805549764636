<template>
    <section id="team" class="section">
        <create-team v-if="dynamicComponent === 'createTeam'"></create-team>
        <my-team v-if="dynamicComponent === 'myTeam'"></my-team>
        <paywall-screen
            v-if="dynamicComponent === 'paywallBasicTeam'"
            title="Would you like to create a Team?"
            :illustration="paywallIllustration"
            :go-premium="{ link: '/get-premium/select-product?kind=team', btnText: 'Create Team' }"
            :footer-features="paywallFooterFeatures"
            :faqs-component="TeamFaqs"
        ></paywall-screen>
        <team-premium v-if="dynamicComponent === 'paywallPremiumTeam'"></team-premium>
    </section>
</template>

<script>
import { mapState } from 'vuex';

import footer1TeamPaywall from '../../resources/static/images/footer_1_team_paywall.svg';
import footer2Paywall from '../../resources/static/images/footer_2_paywall.svg';
import footer3Paywall from '../../resources/static/images/footer_3_paywall.svg';
import illustrationTeamPaywall from '../../resources/static/images/illustration_team_paywall.png';
import PaywallScreen from '../components/paywall/paywall_screen.vue';
import TeamFaqs from '../components/paywall/team_faqs.vue';
import TeamPremium from '../components/paywall/team_premium.vue';
import CreateTeam from '../components/team/create_team.vue';
import MyTeam from '../components/team/my_team.vue';
import FilterUtils from '../models/filters/filter_utils';
import METRIC_TYPE from '../models/metric_type';

const components = {
    CreateTeam,
    MyTeam,
    PaywallScreen,
    TeamPremium
};

function handleUrlChange(vm, to, next) {
    if (vm.dynamicComponent === 'paywallBasicTeam') window.dataLayer.push({ event: 'paywallTeamBasic' });
    else if (vm.dynamicComponent === 'paywallPremiumTeam') window.dataLayer.push({ event: 'paywallTeamPremium' });

    if (!vm.user.permissions(vm.$em.constants.USER_PERMISSIONS.canSeeMyTeam)) {
        next('/report');
    }

    const parsedFilters = FilterUtils.parseUrl(to.query);
    const filtersArray = [parsedFilters.dateFilter, parsedFilters.usersFilter, ...(parsedFilters.advancedFilters || [])];

    if (filtersArray.filter(Boolean).length > 0) {
        vm.$store.dispatch('filter/changeFilters', {
            page: '/team',
            filters: parsedFilters,
            metrics: METRIC_TYPE.TEAM
        });
    } else {
        vm.$store.commit('filter/setPageFilters', { page: '/team', filters: {} });
        vm.$store.commit('filter/resetMetrics', METRIC_TYPE.TEAM);
    }
}

export default {
    name: 'Team',
    components,
    data() {
        return {
            paywallIllustration: illustrationTeamPaywall,
            TeamFaqs,
            paywallFooterFeatures: [
                {
                    illustration: footer1TeamPaywall,
                    title: 'Create a Team',
                    body: 'Compare detailed email stats for any Team member with just a few clicks.',
                    knowMoreLink: 'https://emailmeter.com/premium'
                },
                {
                    illustration: footer2Paywall,
                    title: 'Access your email history',
                    body: 'See the last 2 years of your Gmail activity to understand trends and improvements.',
                    knowMoreLink: 'https://emailmeter.com/premium'
                },
                {
                    illustration: footer3Paywall,
                    title: 'Easily download data',
                    body: 'Analyze your data in any format you want from our CSV exports.',
                    knowMoreLink: 'https://emailmeter.com/premium'
                }
            ]
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            handleUrlChange(vm, to, next);
        });
    },
    beforeRouteUpdate(to, from, next) {
        handleUrlChange(this, to, next);
        next();
    },
    computed: {
        ...mapState('user', ['user']),
        dynamicComponent() {
            const team = this.$store.getters['team/firstTeam'];

            if (this.$route.params.section === 'create') {
                return 'createTeam';
            }

            if (team) {
                if (this.user.client.hasActiveSubscription && (team.members.length !== 0 || team.invitations.length !== 0)) {
                    return 'myTeam';
                } else {
                    return 'paywallPremiumTeam';
                }
            } else {
                return 'paywallBasicTeam';
            }
        }
    }
};
</script>
