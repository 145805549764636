<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal">You’re about to delete a member</h2>
        <img class="modal-image" src="../../../../resources/static/images/modals/user_red.svg" />

        <p>
            <b>Are you sure you want to delete {{ teamMember.email }}?</b>
        </p>
        <p>
            <i class="has-text-grey-dark"
                >You’ll lose access to their data at the end of this billing period on <b>{{ subscriptionPeriodEnd | formatDate }}</b
                >.</i
            >
        </p>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary" :disabled="requestLoading" @click="closeModal()">Keep member</button>
            <button class="button is-primary is-outlined" :class="{ 'is-loading': requestLoading }" :disabled="requestLoading" @click="deleteMember()">Delete member</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ModalMixin from '../mixins/modal_mixin';

export default {
    name: 'DeleteMember',
    mixins: [ModalMixin],
    data() {
        return {
            requestLoading: false
        };
    },
    computed: {
        ...mapGetters('billing', ['subscriptionPeriodEnd']),
        teamMember() {
            return this.modalData.teamMember;
        }
    },
    methods: {
        deleteMember() {
            this.requestLoading = true;
            this.$store
                .dispatch('team/deleteMember', {
                    teamId: this.modalData.teamId,
                    memberId: this.teamMember.id
                })
                .then(() => {
                    // display notification
                    this.closeModal();
                });
        }
    }
};
</script>
