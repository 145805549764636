<template>
    <div class="is-flex flex-col flex-1">
        <section v-if="Object.keys(invitations).length > 0" class="section">
            <div class="columns is-multiline">
                <referral-header
                    :is-premium-referral="isPremiumReferral"
                    :premium-referral-remaining-seconds="premiumReferralRemainingSeconds"
                    :invitations="invitations"
                    :purchases="purchases"
                    :premium-just-ended="premiumJustEnded"
                    @activate-premium="activatePremium"
                ></referral-header>
                <referral-invite :suggestions="suggestions" :short-id="user.shortId" @send-invitation="sendInvitation"></referral-invite>
                <referral-features v-if="!isPremiumReferral && !premiumJustEnded"></referral-features>
                <referral-tutorial></referral-tutorial>
            </div>
        </section>
        <div v-else class="is-flex flex-1 items-center justify-center">
            <spinner></spinner>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ReferralApi from '../api/referral_api';
import spinner from '../components/base/spinner.vue';
import ReferralHeader from '../components/referral/header/header.vue';
import ReferralInvite from '../components/referral/invite/invite.vue';
import ReferralSendInvitationMixin from '../components/referral/mixins/referral_send_invitation_mixin';
import ReferralFeatures from '../components/referral/referral_features.vue';
import ReferralTutorial from '../components/referral/referral_tutorial.vue';
import Log from '../utils/log';

export default {
    name: 'ReferralMain',
    components: {
        ReferralHeader,
        ReferralFeatures,
        ReferralInvite,
        ReferralTutorial,
        spinner
    },
    mixins: [ReferralSendInvitationMixin],
    computed: {
        ...mapState('user', ['user']),
        ...mapState('referral', ['premiumJustEnded', 'invitations', 'purchases', 'suggestions']),
        ...mapGetters('referral', ['isPremiumReferral', 'premiumReferralRemainingSeconds'])
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const canReferral = vm.user.permissions(vm.$em.constants.USER_PERMISSIONS.canReferralProgram) && vm.$waffle.switch_is_active('referral_program');
            if (canReferral) vm.$bus.on(vm.$bus.events.resendReferralEmail, vm.sendInvitation);
            else next('/');
        });
    },
    beforeDestroy() {
        this.$bus.off(this.$bus.events.resendReferralEmail, this.sendInvitation);
    },
    methods: {
        activatePremium() {
            ReferralApi.redeemPremium()
                .then(() => {
                    this.$store.dispatch('user/fetchUser');
                    this.$store.dispatch('referral/fetchStatus');
                })
                .catch(({ debug }) => {
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'There was an error activating your Premium'
                    });
                    Log.error(new Error('Unable to activate premium (referral program)'), { ...debug });
                });
        }
    }
};
</script>
