<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal mb-4">Edit your billing information</h2>
        <div class="columns">
            <div class="column is-8 is-offset-2">
                <billing @billing="updateBilling"></billing>
            </div>
        </div>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary mr-4" :class="{ 'is-loading': requestLoading }" :disabled="!isFormValid || requestLoading" @click="submitBillingForm()">Save</button>
        </div>
    </div>
</template>

<script>
import Billing from '../paywall/billing.vue';
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'EditBillingInfo',
    components: { Billing },
    mixins: [ModalMixin],
    data() {
        return {
            billingData: null,
            requestLoading: false,
            isFormValid: false
        };
    },
    methods: {
        submitBillingForm() {
            this.requestLoading = true;
            this.$store
                .dispatch('billing/updateCustomer', this.billingData)
                .then(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: 'Done! You’ve updated your billing info successfully.'
                    });
                })
                .catch(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
        },
        updateBilling({ billingData, isFormValid }) {
            this.billingData = billingData;
            this.isFormValid = isFormValid;
        }
    }
};
</script>
