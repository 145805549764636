<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal">Invitation to join a Team</h2>
        <img class="modal-image" src="../../../../resources/static/images/modals/join_team_preaccept.svg" />

        <p>
            <b>{{ invitation.inviterName }} ({{ invitation.inviterEmail }}) would like you to join their Email Meter Team.</b>
        </p>
        <p>
            <b>If you accept it, you’ll share your key email stats. This includes your average response time, response rate, and number of sent and received emails.</b>
        </p>
        <p>These metrics will only ever be shared privately with {{ invitation.inviterName }}.</p>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary" :class="{ 'is-loading': requestLoading && isAcceptLoading }" :disabled="requestLoading" @click="acceptInvitation()">Accept Invitation</button>
            <button class="button is-primary is-outlined" :class="{ 'is-loading': requestLoading && !isAcceptLoading }" :disabled="requestLoading" @click="rejectInvitation()">
                Decline Invitation
            </button>
        </div>
    </div>
</template>

<script>
import TeamApi from '../../../api/team_api';
import ModalMixin from '../mixins/modal_mixin';

export default {
    name: 'UserInvitation',
    mixins: [ModalMixin],
    data() {
        return {
            requestLoading: false,
            isAcceptLoading: false
        };
    },
    computed: {
        invitation() {
            return this.modalData.invitation;
        }
    },
    methods: {
        acceptInvitation() {
            this.requestLoading = true;
            this.isAcceptLoading = true;
            TeamApi.acceptInvitation(this.invitation.id)
                .then(() => {
                    this.$store.dispatch('user/fetchUser').then(() => {
                        this.closeModal();
                    });
                })
                .catch(() => {
                    this.closeModal();
                    // display notification
                });
        },
        rejectInvitation() {
            this.requestLoading = true;
            this.isAcceptLoading = false;
            TeamApi.rejectInvitation(this.invitation.id)
                .then(() => {
                    // display notification
                    this.closeModal();
                })
                .catch(() => {
                    // display notification
                });
        }
    }
};
</script>
