<template>
    <div v-if="!$store.getters.isMobileOrTablet" id="settings-navigation" class="tabs mb-0">
        <ul>
            <router-link tag="li" to="/settings/general" active-class="is-active">
                <a>Basic Settings</a>
            </router-link>
            <router-link v-if="canSeeTeamManagement" tag="li" to="/settings/team-management" active-class="is-active">
                <a>Team Management</a>
            </router-link>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SettingsNavigation',
    computed: {
        canSeeTeamManagement() {
            const user = this.$store.state.user.user;
            const team = this.$store.getters['team/firstTeam'];

            if (team) return user.permissions(this.$em.constants.USER_PERMISSIONS.canManageTeams) && (team.members.length !== 0 || team.invitations.length !== 0);
            return false;
        }
    }
};
</script>
