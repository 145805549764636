<template>
    <div class="level shadow is-mobile" :class="{ 'level-expanded': isExpanded }" @click="toggle()">
        <div class="level-left">
            <i class="em-icon filter"></i>
            <span class="has-text-weight-bold is-hidden-mobile ml-3">FILTER EMAILS</span>
        </div>
        <div class="level-item">
            <div class="tags">
                <span v-for="(tag, index) in tags" :key="index" class="tag is-rounded has-text-primary has-background-primary-lighter">{{ tag }}</span>
            </div>
        </div>
        <div class="level-right">
            <span class="is-hidden-mobile ml-12 mr-1">Edit Filters</span>
            <i v-if="!isExpanded" class="em-icon chevron-down"></i>
            <i v-else class="em-icon chevron-up"></i>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import { STATUS } from '../../api/parsers/bqi_parser';
import { PERIOD_SPAN_KINDS } from '../../constants/em';
import PAGE_NAMES from '../../router/pages';

export default {
    name: 'FilterNavbar',
    props: {
        isExpanded: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        tags() {
            const filters = this.$store.getters['filter/currentPageFilters'];
            if (!filters) return null;
            return [this.getDateTags(filters.dateFilter), this.getUsersTags(filters.usersFilter), this.getAdvancedFilters(filters.advancedFilters)].filter(Boolean);
        }
    },
    methods: {
        getDateTags(dateFilter) {
            if (dateFilter) {
                const periodKind = this.$route.params.section;
                if (periodKind === PERIOD_SPAN_KINDS.monthly) {
                    return dayjs
                        .unix(dateFilter.timestampFrom)
                        .utc()
                        .format('MMMM YYYY');
                } else {
                    const periodFrom = dayjs
                        .unix(dateFilter.timestampFrom)
                        .utc()
                        .format('MMM D, YYYY');
                    const periodTo = dayjs
                        .unix(dateFilter.timestampTo)
                        .utc()
                        .format('MMM D, YYYY');
                    return `${periodFrom} to ${periodTo}`;
                }
            } else {
                return 'No date range';
            }
        },
        getUsersTags(usersFilter) {
            const usersList = this.$store.getters['team/getAllTeamMembers'];
            const user = this.$store.state.user.user;
            const firstLoad = Object.values(this.$store.state.filter.metrics).every(m => m.status === STATUS.LOADING);

            if (usersList.length < 2) {
                return null;
            }

            if (usersFilter) {
                if (usersFilter.value.length === 1) {
                    const userFilter = usersList.find(u => u.googleUserId === usersFilter.value[0]);
                    return userFilter.email;
                } else {
                    return `${usersFilter.value.length} users`;
                }
            } else if (this.$route.name === PAGE_NAMES.team && firstLoad) {
                return 'All users';
            } else {
                return user.email;
            }
        },
        getAdvancedFilters(advancedFilters) {
            if ((advancedFilters || []).length > 0) {
                return this.$root.$options.filters.pluralize(`${advancedFilters.length} advanced filter`, advancedFilters.length);
            } else {
                return null;
            }
        },
        toggle() {
            this.$emit('toggle-filters', !this.isExpanded);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

.level {
    background-color: white;
    font-size: $size-8;
    margin-bottom: 0;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;
    border-radius: $radius-large;

    .tags {
        .tag {
            font-size: $size-9;
        }
    }

    &.level-expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: solid 1px #efefef;
    }

    @include desktop {
        height: 3rem;
    }

    .level-item {
        @include mobile {
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>
