import Papa from 'papaparse';

import csvParser from './parsers/csv_parser';

function getMetrics(metricTypes, metrics) {
    let headers = [];
    let data = [];
    for (const metricName in metricTypes) {
        if (Object.prototype.hasOwnProperty.call(metricTypes, metricName)) {
            headers = headers.concat(csvParser[metricName].getCsvHeaders(metrics[metricName]));
            data = data.concat(csvParser[metricName].getCsvData(metrics[metricName]) || csvParser[metricName].notLoaded || '');
        }
    }

    return {
        fields: headers,
        data: data
    };
}

export default function csvExport(metricTypes, metrics) {
    const formattedMetrics = getMetrics(metricTypes, metrics);

    const csvData = Papa.unparse({
        fields: formattedMetrics.fields,
        data: formattedMetrics.data
    });

    const csvFile = new Blob([csvData], {
        type: 'text/csv;charset=utf-8;'
    });

    if (navigator.msSaveBlob) {
        // IE11
        navigator.msSaveBlob(csvFile, 'report.csv');
    } else {
        return window.URL.createObjectURL(csvFile);
    }
}
