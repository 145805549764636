<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal">You’re about to reactivate Premium</h2>
        <img class="modal-image" src="../../../resources/static/images/success.svg" />
        <p>
            <b>Good to see you again!</b>
        </p>
        <p>Reactivate your premium account to get continued access to Custom Reports, CSV exports and all other Premium features.</p>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary" :class="{ 'is-loading': requestLoading }" :disabled="requestLoading" @click="renewPlan()">Ok, reactivate</button>
        </div>
    </div>
</template>

<script>
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'ReactivePlan',
    mixins: [ModalMixin],
    data() {
        return {
            requestLoading: false
        };
    },
    methods: {
        renewPlan() {
            this.requestLoading = true;
            const subscriptionId = this.$store.state.billing.customer.subscription.id;
            this.$store
                .dispatch('billing/updateSubscription', {
                    subscriptionId: subscriptionId,
                    action: 'renew'
                })
                .then(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        text: 'Welcome back! You’ve successfully reactivated your account.'
                    });
                });
        }
    }
};
</script>
