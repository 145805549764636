<template>
    <section id="report" class="section">
        <div class="box is-flex items-center">
            <div class="is-flex sd:flex-col lg:flex-row">
                <img src="../../../resources/static/images/waiting_clock.svg" class="mr-8 mb-4" />
                <div class="is-flex flex-col justify-center">
                    <h2 class="title is-3">Almost there...</h2>
                    <p>We are still loading data for your account. This may take a few hours if you have a lot of mail.</p>
                    <p>We will send you an email when everything is finished. You can also check the progress below.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CardBatchLoading'
};
</script>
