<template>
    <div class="columns is-multiline">
        <div class="column is-full has-text-centered">
            <h2 class="title is-2 is-size-5-mobile mb-2">Settings</h2>
        </div>
        <div class="column is-full">
            <div class="box">
                <level-setting
                    v-if="$waffle.switch_is_active('monthly_report_notification')"
                    id="monthly-setting"
                    icon="em-icon monthly-report"
                    title="Monthly Report"
                    subtitle="If you enable this, you will receive an email when your monthly report is ready."
                    class="is-mobile"
                >
                    <template v-slot:field>
                        <div class="field">
                            <input id="monthly-checkbox" v-model="settings.emailMonthly" type="checkbox" name="monthly" class="switch is-rounded is-primary" checked="checked" />
                            <label for="monthly-checkbox"></label>
                        </div>
                    </template>
                </level-setting>
                <level-setting
                    id="weekly-setting"
                    icon="em-icon weekly-report"
                    title="Weekly Report"
                    subtitle="If you enable this, you will receive an email when your weekly report is ready."
                    class="is-mobile"
                >
                    <template v-slot:field>
                        <div class="field">
                            <input id="weekly-checkbox" v-model="settings.emailWeekly" type="checkbox" name="weekly" class="switch is-rounded is-primary" checked="checked" />
                            <label for="weekly-checkbox"></label>
                        </div>
                    </template>
                </level-setting>
                <level-setting id="timezone-setting" icon="em-icon timezone" title="Your Timezone" subtitle="Select the timezone for your email statistics.">
                    <template v-slot:field>
                        <div class="field">
                            <div class="control">
                                <div class="select">
                                    <select v-model="settings.timezone">
                                        <option v-for="timezone in timezoneList" :key="timezone" :value="timezone">{{ timezone }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </template>
                </level-setting>
                <div v-if="$waffle.switch_is_active('business_hours')" id="business-hours-setting" class="columns is-multiline mx-0 business-hours">
                    <div class="column px-0 is-full">
                        <level-setting
                            icon="em-icon working-hours"
                            title="Business Hours"
                            subtitle="If you enable this, we'll take into account your work hours when we calculate your Response Time metrics."
                            class="is-mobile"
                        >
                            <template v-slot:field>
                                <div class="field">
                                    <div v-if="!canBusinessHours" @click.stop="showBusinessHoursPaywall">
                                        <input type="checkbox" name="business-hours" class="switch is-rounded is-primary" />
                                        <label for="business-hours-checkbox"></label>
                                    </div>
                                    <input v-else id="business-hours-checkbox" v-model="settings.businessHoursEnabled" type="checkbox" name="business-hours" class="switch is-rounded is-primary" />
                                    <label for="business-hours-checkbox"></label>
                                </div>
                            </template>
                        </level-setting>
                    </div>
                    <business-hours
                        v-model="settings.businessHours"
                        :business-hours-enabled="settings.businessHoursEnabled"
                        :error-rows="businessHoursErrorRows"
                        @input="businessHoursErrorRows = []"
                        @editing="editingBusinessHours = $event"
                    ></business-hours>
                </div>

                <div class="has-text-centered">
                    <button class="button is-primary" :class="{ 'is-loading': saving }" :disabled="!saveEnabled" @click="save()">Save</button>
                </div>
            </div>
        </div>
        <div v-if="canBeDeleted && $waffle.switch_is_active('delete_account_oauth')" class="column">
            <advanced-settings></advanced-settings>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import BqiApi from '../../api/bqi_api';
import Log from '../../utils/log';
import modalsEnum from '../../utils/modals_enum';
import TIMEZONE_LIST from '../../utils/timezones';
import utils from '../../utils/utils';
import AdvancedSettings from './advanced_settings.vue';
import BusinessHours from './business_hours.vue';
import LevelSetting from './level_setting.vue';

export default {
    name: 'SettingsGeneral',
    components: {
        AdvancedSettings,
        BusinessHours,
        LevelSetting
    },
    data() {
        return {
            settings: {
                emailWeekly: false,
                emailMonthly: false,
                timezone: 'UTC',
                businessHoursEnabled: true,
                businessHours: []
            },
            saving: false,
            timezoneList: TIMEZONE_LIST,
            businessHoursErrorRows: [],
            editingBusinessHours: false
        };
    },
    computed: {
        ...mapState('user', ['user']),
        userConfig() {
            return this.user.config;
        },
        // eslint-disable-next-line complexity
        saveEnabled() {
            if (!this.userConfig || this.saving || this.editingBusinessHours) return false;
            return (
                this.settings.emailWeekly !== this.userConfig.emailWeekly ||
                this.settings.emailMonthly !== this.userConfig.emailMonthly ||
                this.settings.timezone !== this.userConfig.timezone ||
                this.settings.businessHoursEnabled !== (this.canBusinessHours && this.userConfig.businessHoursEnabled) ||
                JSON.stringify(this.settings.businessHours) !== JSON.stringify(this.userConfig.businessHours)
            );
        },
        canBusinessHours() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canBusinessHours);
        },
        canBeDeleted() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canBeDeleted);
        }
    },
    watch: {
        userConfig: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.settings = Object.assign({}, newVal, { businessHoursEnabled: this.canBusinessHours && newVal.businessHoursEnabled });
                }
            }
        }
    },
    methods: {
        showBusinessHoursPaywall() {
            this.$bus.emit(this.$bus.events.showModal, modalsEnum.paywall, { type: 'businessHours', isLarge: true });
        },
        save() {
            this.saving = true;
            const settings = {
                'email_weekly': this.settings.emailWeekly,
                'email_monthly': this.settings.emailMonthly,
                timezone: this.settings.timezone,
                'business_hours_enabled': this.settings.businessHoursEnabled,
                'business_hours': this.settings.businessHours
            };
            this.$store
                .dispatch('user/updateUserConfig', settings)
                .then(() => {
                    this.saving = false;
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: 'Done! You’ve updated your settings successfully.'
                    });
                    BqiApi.clearCache();
                })
                .catch(({ resJSON, debug }) => {
                    this.saving = false;
                    if (utils.getValueOrNull(resJSON, 'business_hours.error') === 'overlaps') {
                        const overlaps = resJSON.business_hours.overlaps;
                        const failing = Object.keys(overlaps).concat(Object.values(overlaps));
                        const temp = [];
                        Array.from(new Set(failing.map(Number))).forEach(i => {
                            temp[i] = true;
                        });
                        this.businessHoursErrorRows = temp;
                    } else {
                        this.$bus.emit(this.$bus.events.notification, {
                            bgColor: 'is-danger',
                            text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                            timeLapse: 0
                        });
                        Log.error(new Error('Error while saving settings'), { ...debug });
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.box > div:not(:last-child) {
    border-bottom: 1px solid $grey-lighter;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

#timezone-setting {
    .field {
        @include desktop {
            margin-left: auto;
        }
    }
}
</style>
