<template>
    <div class="columns">
        <div class="column">
            <div class="columns">
                <div class="column">
                    <div v-if="!canTopInteractions" class="has-background-primary-lighter has-text-primary p-2 is-size-8">
                        Results are limited to your top 10 interactions.
                        <b>
                            Want to see more?
                            <router-link class="underline has-text-primary" to="/get-premium/paywall">Get Premium here</router-link>
                        </b>
                    </div>
                    <div v-else class="has-background-primary-lighter has-text-primary p-2 is-size-8">
                        This ranking shows the top addresses/domains you've interacted with, limited to 200. Use
                        <a href="https://help.emailmeter.com/en/articles/3058215-advanced-filters" class="has-text-primary has-text-weight-bold underline" target="_blank" rel="noopener"
                            >Advanced Filters</a
                        >
                        to find specific data or <a href="mailto:hello@emailmeter.com" class="has-text-primary has-text-weight-bold underline">contact us</a> to get the full list. <br />Some use
                        cases:
                        <div class="content">
                            <ul>
                                <li>
                                    📮
                                    <b>Where do your emails come from?</b> Order by Received Messages, locate addresses you don't interact with and focus on the emails that matter by unsubscribing or
                                    setting up filters.
                                </li>
                                <li>
                                    ⏱
                                    <b>Who are you emailing the most?</b> Order by Sent Messages and see who is taking up most of your time, or who never gets back to you!
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-flex">
                    <div class="buttons has-addons top-interactions-selector">
                        <button
                            class="button is-small is-shadowless"
                            :class="{ 'is-secondary': selectedTable === MetricType.topInteractions, 'is-secondary-lighter': selectedTable === MetricType.topInteractionsByDomain }"
                            @click="selectTable(MetricType.topInteractions)"
                        >
                            Address
                        </button>
                        <button
                            class="button is-small is-shadowless"
                            :class="{ 'is-secondary': selectedTable === MetricType.topInteractionsByDomain, 'is-secondary-lighter': selectedTable === MetricType.topInteractions }"
                            @click="selectTable(MetricType.topInteractionsByDomain)"
                        >
                            Domain
                        </button>
                    </div>
                </div>
            </div>
            <table class="table is-striped is-fullwidth">
                <thead class="is-hidden-touch">
                    <tr>
                        <th>
                            <input v-if="canTopInteractions" v-model="emailFilter" type="text" class="input is-small" placeholder="Filter by email or address" />
                            <template v-else>Email</template>
                        </th>
                        <th v-for="h in tableHeaders" :key="h.slug" :class="h.class">
                            <div v-if="canTopInteractions" class="cursor-pointer select-none" @click="sortByField(h.slug)">
                                {{ h.name }}
                                <span class="icon">
                                    <i class="em-icon is-size-9" :class="{ [orderCharacter]: sortBy === h.slug, 'sort': sortBy !== h.slug }"></i>
                                </span>
                            </div>
                            <template v-else>{{ h.name }}</template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in interactionsData" :key="row.email">
                        <td class="email-column">{{ row.email }}</td>
                        <td class="metric-col">
                            <div class="is-flex items-center relative">
                                <div class="is-inline-flex metric-bar mr-2 has-background-grey-light">
                                    <span class="has-background-metric-total" :style="{ width: `calc(100% * ${row.messages} / ${maxValue})` }"></span>
                                </div>
                                <span class="metric-amount">{{ row.messages }}</span>
                            </div>
                        </td>
                        <td class="metric-col">
                            <div class="is-flex items-center relative">
                                <div class="is-inline-flex metric-bar mr-2 has-background-grey-light">
                                    <span class="has-background-metric-received" :style="{ width: `calc(100% * ${row.sentMessages} / ${maxValue})` }"></span>
                                </div>
                                <span class="metric-amount">{{ row.sentMessages }}</span>
                            </div>
                        </td>
                        <td class="metric-col">
                            <div class="is-flex items-center relative">
                                <div class="is-inline-flex metric-bar mr-2 has-background-grey-light">
                                    <span class="has-background-metric-sent" :style="{ width: `calc(100% * ${row.receivedMessages} / ${maxValue})` }"></span>
                                </div>
                                <span class="metric-amount">{{ row.receivedMessages }}</span>
                            </div>
                        </td>
                        <td v-show="showBestContactTime" class="has-text-right-desktop">{{ row.contactTime | getIntervalFromHour }}</td>
                        <td class="has-text-right-desktop">{{ row.responseTime | formatDate }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-if="!canTopInteractions" class="columns">
                <div class="column is-flex">
                    <router-link class="button is-primary is-outlined mx-auto" to="/get-premium/paywall">Show more</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import dateHelpers from '../../../utils/date_helpers';
import ReportMetricMixin from '../mixins/report_metric_mixin';

const TABLE_HEADERS = [
    { name: 'Interactions', slug: 'messages', class: '' },
    { name: 'Sent messages', slug: 'sentMessages', class: '' },
    { name: 'Received messages', slug: 'receivedMessages', class: '' },
    { name: 'Best contact time', slug: 'contactTime', class: 'has-text-right-desktop' },
    { name: 'My response time', slug: 'responseTime', class: 'has-text-right-desktop' }
];

export default {
    name: 'TopInteractions',
    filters: {
        formatDate(val) {
            if (val === 0 || val === null) return '-';
            return dateHelpers.formatDate(val);
        },
        getIntervalFromHour(val) {
            if (val === '' || val === null) return '-';
            return dayjs()
                .set('hour', val)
                .format('h A');
        }
    },
    mixins: [ReportMetricMixin],
    data() {
        return {
            selectedTable: 'topInteractions',
            ascOrder: true,
            sortBy: 'messages',
            emailFilter: ''
        };
    },
    computed: {
        ...mapState('user', ['user']),
        canTopInteractions() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canTopInteractions);
        },
        tableHeaders() {
            if (!this.showBestContactTime) {
                return TABLE_HEADERS.filter(h => h.slug !== 'contactTime');
            }
            return TABLE_HEADERS;
        },
        topInteractions() {
            return this.componentMetrics[this.selectedTable].value;
        },
        showBestContactTime() {
            return this.selectedTable === this.MetricType.topInteractions;
        },
        orderCharacter() {
            if (this.ascOrder) return 'arrow-up';
            else return 'arrow-down';
        },
        interactionsData() {
            const field = this.sortBy;
            const data = this.topInteractions
                .filter(item => {
                    return item.email.indexOf(this.emailFilter) !== -1;
                })
                .sort((a, b) => {
                    let val = 0;
                    if (a[field] > b[field]) val = 1;
                    if (b[field] > a[field]) val = -1;
                    if (this.ascOrder) val = -val;
                    return val;
                });

            if (!this.canTopInteractions) {
                return data.slice(0, 10);
            }

            return data;
        },
        maxValue() {
            return Math.max(...this.interactionsData.map(elem => elem.messages));
        }
    },
    methods: {
        sortByField(field) {
            if (this.sortBy === field) {
                this.ascOrder = !this.ascOrder;
            }
            this.sortBy = field;
            // Missing reverse (lower = better)
        },
        selectTable(table) {
            this.selectedTable = table;
            if (this.selectedTable === this.MetricType.topInteractionsByDomain) {
                window.dataLayer.push({
                    event: 'topInteractionsByDomainSelected'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/mixins';
@import '../../../styles/variables';

.item {
    margin-bottom: 1.25rem;

    div {
        padding-left: 0.5rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.top-interactions-selector {
    @include desktop {
        margin-left: auto;
    }

    @include touch {
        margin: 0 auto;
    }
}

.metric-bar {
    height: 15px;
    width: 78%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    > span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.metric-amount {
    position: absolute;
    left: 80%;
}

.metric-col {
    width: 17%;

    @include touch {
        width: 100%;
    }
}

.has-background-metric-total {
    background-color: #dc73fc;
}

.content ul {
    list-style: none;
}

.table {
    font-size: $size-8;
    thead {
        & > tr {
            th {
                color: $text;
                font-weight: normal;
                vertical-align: middle;
                border-bottom: none;
            }
        }
    }
    tbody {
        & > tr {
            td {
                vertical-align: middle;
                font-size: $size-8;

                &:not(:first-child) {
                    color: $grey-darker;
                }

                &.email-column {
                    @include desktop {
                        word-break: break-all;
                        max-width: 225px;
                    }
                }
            }
        }
    }
}

@include touch {
    .table {
        tbody {
            & > tr {
                display: flex;
                flex-direction: column;
                background-color: $grey-lighter !important;
                margin-bottom: 1rem;
                border-radius: $radius;

                td {
                    border: unset;
                    white-space: pre;

                    &.email-column {
                        max-width: 80vw;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    &::before {
                        color: $text;
                    }

                    &:nth-child(1) {
                        font-weight: bold;
                    }
                    &:nth-child(2):before {
                        content: 'Interactions \A';
                    }
                    &:nth-child(3):before {
                        content: 'Sent messages \A';
                    }
                    &:nth-child(4):before {
                        content: 'Received messages \A';
                    }
                    &:nth-child(5):before {
                        content: 'Best contact time \A';
                    }
                    &:nth-child(6):before {
                        content: 'My response time \A';
                    }
                }
            }
        }
    }
}
</style>
