<template>
    <nav class="navbar is-fixed-top" role="navigation" aria-label="dropdown navigation">
        <div class="container is-fluid">
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item">
                    <img src="../../../resources/static/images/logo.svg" alt="Email Meter" class="logo" />
                </router-link>

                <div class="navbar-burger">
                    <a role="button" class="navbar-link is-arrowless is-flex items-center" aria-label="menu" aria-expanded="false" @click="burgerClick()">
                        <figure class="image is-40x40 mr-2">
                            <img class="is-rounded is-40x40" :src="user.picture" alt="Profile image" />
                        </figure>
                        <i class="em-icon dropdown"></i>
                    </a>
                </div>
            </div>
            <div class="navbar-menu" :class="{ 'is-active': isMenuActive }">
                <div class="navbar-end">
                    <a
                        v-if="!isMobileOrTablet && $waffle.switch_is_active('help_button')"
                        href="http://help.emailmeter.com"
                        aria-label="Help Center"
                        target="_blank"
                        rel="noopener"
                        class="navbar-item"
                    >
                        <span class="icon is-medium">
                            <i class="em-icon help-center is-size-4"></i>
                        </span>
                    </a>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link is-arrowless is-hidden-touch">
                            <figure class="image is-40x40 mr-2">
                                <img class="is-rounded is-40x40" :src="user.picture" alt="Profile image" />
                            </figure>
                            <div class="mr-4">
                                <span class="has-text-weight-bold">{{ user.fullName() }}</span
                                ><br />
                                {{ user.email }}
                            </div>
                            <i class="em-icon dropdown"></i>
                        </a>
                        <div class="navbar-dropdown flex-col flex-1" :class="{ 'is-flex': isMobileOrTablet }">
                            <template v-if="$waffle.switch_is_active('help_button')">
                                <a v-if="isMobileOrTablet" href="http://help.emailmeter.com" target="_blank" rel="noopener" class="navbar-item">
                                    <i class="em-icon help-center mr-2"></i>
                                    Help
                                </a>
                                <hr v-if="isMobileOrTablet" class="navbar-divider mx-6 is-block" />
                            </template>
                            <a v-if="isMobileOrTablet && !user.client.hasActiveSubscription && !user.isTeamMember" class="navbar-item" @click="goToPage('/get-premium')">
                                <i class="em-icon menu-premium mr-2"></i>
                                Upgrade your plan
                            </a>
                            <hr v-if="isMobileOrTablet && !user.client.hasActiveSubscription && !user.isTeamMember" class="navbar-divider mx-6 is-block" />
                            <a v-if="isMobileOrTablet && canMyAccountBilling" class="navbar-item" @click="goToPage('/account')">
                                <i class="em-icon menu-user mr-2"></i>
                                My Account
                            </a>
                            <hr v-if="isMobileOrTablet && canMyAccountBilling" class="navbar-divider mx-6 is-block" />
                            <a v-if="isMobileOrTablet" class="navbar-item" @click="goToPage('/settings')">
                                <i class="em-icon menu-settings mr-2"></i>
                                Settings
                            </a>
                            <hr v-if="isMobileOrTablet" class="navbar-divider mx-6 is-block" />
                            <a href="/logout" class="navbar-item">
                                <i class="em-icon logout mr-2"></i>
                                Logout
                            </a>
                            <hr v-if="isMobileOrTablet" class="navbar-divider mx-6 is-block" />
                            <div v-if="isMobileOrTablet" class="navbar-item">
                                <div>
                                    <p class="has-text-weight-bold">{{ user.fullName() }}</p>
                                    <p>{{ user.email }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'Header',
    data() {
        return {
            isMenuActive: false
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters(['isMobileOrTablet']),
        canMyAccountBilling() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canMyAccountBilling);
        }
    },
    methods: {
        burgerClick() {
            this.isMenuActive = !this.isMenuActive;
        },
        goToPage(path) {
            this.isMenuActive = false;
            this.$router.push({
                path: path
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.navbar-brand {
    > :not(.navbar-burger) {
        img {
            max-height: 1.75rem;
        }
    }
}

.is-40x40 {
    width: 40px;
    height: 40px;
}

.navbar-item {
    display: flex;
    align-items: center;
}

.navbar-burger {
    width: unset;
}
</style>
