import ApiBase from './api_base';
import BatchParser from './parsers/batch_parser';
import UserParser from './parsers/user_parser';
import poll from './poller';

const URLS = {
    userInfo: '/api/accounts/user_info',
    batch: '/api/accounts/batches/?kind=',
    batches: '/api/accounts/batches/',
    deleteAccount: '/api/accounts/delete-oauth-account/',
    csvExport: '/bqi/csv_export',
    onboardingProfile: '/api/accounts/onboarding-profile/'
};
const ENDPOINT_BQIP = process.env.ENDPOINT_BQIP;

export default class UserApi extends ApiBase {
    static info() {
        return this.fetchData(URLS.userInfo).then(data => {
            window.USER_TOKEN = data.token_key;
            return UserParser.parseUser(data);
        });
    }

    static deleteAccount() {
        return this.postData(URLS.deleteAccount, {}, 'DELETE');
    }

    static updateUserConfig(config) {
        return this.postData(URLS.userInfo, config, 'PATCH').then(data => {
            return UserParser.parseUser(data).config;
        });
    }

    static hasActiveBatch(kind) {
        return this.fetchData(`${URLS.batch}${kind}`).then(data => {
            if (data.count > 0) {
                const batchData = BatchParser.parseBatchData(data.results[0]);
                return !batchData.isComplete();
            }

            return false;
        });
    }

    static getBatchProgress(kind, timelapse = 1000, callback) {
        return poll(
            `${URLS.batch}${kind}`,
            data => {
                if (data.count > 0) {
                    const mainBatch = data.results;
                    if (mainBatch.length > 0) {
                        const batch = BatchParser.parseBatchData(mainBatch[0]);
                        callback(batch);
                        return batch.isComplete();
                    }
                }
                return true;
            },
            () => {
                return true;
            },
            timelapse
        );
    }

    static getAllBatches() {
        return this.fetchData(`${URLS.batches}`).then(data => {
            const batches = data.results;
            if (batches.length > 0) {
                return batches.map(batchData => BatchParser.parseBatchData(batchData));
            } else {
                return [];
            }
        });
    }

    static csvExport(filters) {
        return this.fetchData(`${URLS.csvExport}?${filters}`, ENDPOINT_BQIP, true);
    }

    static updateOnboardingProfile(config) {
        return this.postData(URLS.onboardingProfile, config, 'PATCH');
    }
}
