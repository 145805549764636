<template>
    <div>
        <faq question="What can I do with Teams?">
            <p>In a nutshell, Teams enables you to conveniently analyze the email stats of your team members. More specifically, you’ll be able to:</p>
            <ul class="ml-8">
                <li>See individual email stats for each team member.</li>
                <li>See email stats for a specific group of team members within the team.</li>
                <li>Access a team dashboard to clearly compare team members’ email stats.</li>
                <li>Grant team members access to their Premium data.</li>
            </ul>
        </faq>
        <faq question="How can I add Team members?">
            <ol class="ml-4">
                <li class="pl-2">After sending an invite to as many addresses as you want, your invitees can accept either from an email or within their Email Meter interface.</li>
                <li class="pl-2">Account is created for them if they didn’t already have one, and their data is loaded.</li>
                <li class="pl-2">Once finished, they will become part of your Team and have access to all Premium features!</li>
            </ol>
            <p class="mt-4">You can cancel anytime, including pending invitations.</p>
        </faq>
        <faq question="What’s the cancelation policy?">
            Our cancelation policy is simple - you can cancel anytime you want by going to “My Account”. If you do cancel your Premium account, you’ll be able to continue using all the Premium
            features right up until the end of your last billing cycle. The expiry date of your Premium account is displayed clearly in “My Account”.
        </faq>
        <faq question="Do you have any more questions?">
            Reach us at
            <a href="mailto:hello@emailmeter.com">hello@emailmeter.com</a>, or open up a chat by clicking on the bubble in the bottom right hand corner of the screen! We'd be very happy to help.
        </faq>
    </div>
</template>

<script>
import Faq from './faq.vue';

export default {
    name: 'TeamFaqs',
    components: {
        Faq
    }
};
</script>

<style lang="scss" scoped>
ul {
    list-style: disc;
}
</style>
