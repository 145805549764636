import dayjs from 'dayjs';

import MetricsAPI from '../api/metrics_api';
import { MetricData, STATUS } from '../api/parsers/bqi_parser';
import { PAGE_PATHS } from '../constants/em';
import FilterUtils from '../models/filters/filter_utils';
import METRIC_TYPE from '../models/metric_type';
import router from '../router/router';
import dateHelpers from '../utils/date_helpers';
import PreviousReportComparison from '../utils/previous_report_comparison';

function initMetricsLoading(metrics) {
    return Object.keys(metrics).reduce((acc, metricName) => Object.assign({}, acc, { [metricName]: new MetricData(STATUS.LOADING) }), {});
}

export default {
    namespaced: true,
    state: {
        dataSpan: {
            timestampFrom: null,
            timestampTo: null
        },
        filters: {},
        metrics: null,
        previousSummary: null
    },
    getters: {
        dataSpan(state) {
            return {
                timestampFrom: dayjs.unix(state.dataSpan.timestampFrom).utc(),
                timestampTo: dayjs.unix(state.dataSpan.timestampTo).utc()
            };
        },
        currentPageFilters(state) {
            return state.filters[router.currentRoute.path];
        },
        /**
         * Receives a path (ex: /report/monthly) and returns that path with its filters serialized as query string
         * @param {string} path - Page path
         * @returns {string} path with serialized QS
         */
        pathToFilteredPath: state => path => {
            const currentFilters = state.filters[path] || {};
            const query = FilterUtils.flattenFilters(currentFilters);
            return `${path}?${query}`;
        },
        summaryDiff(state) {
            if (state.previousSummary === null || state.metrics === null) return null;
            const previousMetricsLoaded = Object.values(state.previousSummary).every(m => m.status === STATUS.LOADED);
            const currentMetricsLoaded = Object.keys(state.metrics)
                .filter(name => Object.keys(METRIC_TYPE.PREVIOUS_SUMMARY).includes(name))
                .every(name => state.metrics[name].status === STATUS.LOADED);

            if (!previousMetricsLoaded || !currentMetricsLoaded) return null;

            const diff = new PreviousReportComparison(state.metrics, state.previousSummary);
            return Object.keys(METRIC_TYPE.PREVIOUS_SUMMARY).reduce((acc, metricName) => {
                return Object.assign({}, acc, { [metricName]: diff.getMetricDiff(metricName) });
            }, {});
        }
    },
    mutations: {
        setDataSpan(state, logSpan) {
            state.dataSpan = Object.assign({}, state.dataSpan, logSpan);
        },
        setPageFilters(state, { page, filters }) {
            state.filters = Object.assign({}, state.filters, { [page]: filters });
        },
        updateMetric(state, metrics) {
            state.metrics = Object.assign({}, state.metrics, metrics);
        },
        // eslint-disable-next-line no-unused-vars
        resetMetrics(state, metricsToReset) {
            state.metrics = initMetricsLoading(METRIC_TYPE.ALL); // TODO: This should use metricsToReset instead of ALL
        },
        resetPreviousMetrics(state) {
            state.previousSummary = initMetricsLoading(METRIC_TYPE.PREVIOUS_SUMMARY);
        },
        updatePreviousSummary(state, data) {
            state.previousSummary = data;
        }
    },
    actions: {
        changeFilters(context, { page, filters, metrics }) {
            context.commit('resetMetrics', metrics);
            context.commit('setPageFilters', { page, filters });
            // TODO: this should be a flat object
            const qsFilters = FilterUtils.flattenFiltersBqi(filters);
            context.dispatch('fetchMetrics', { qsFilters, metrics });

            if (page === PAGE_PATHS.monthly) {
                context.dispatch('fetchPreviousSummary', filters);
            } else {
                context.commit('resetPreviousMetrics');
            }
        },
        fetchMetrics(context, { qsFilters, metrics }) {
            const newMetrics = MetricsAPI.fetchMetrics(metrics, qsFilters);
            for (const metricName in newMetrics) {
                if (Object.prototype.hasOwnProperty.call(metrics, metricName)) {
                    newMetrics[metricName].then(res => {
                        context.commit('updateMetric', res);
                    });
                }
            }
        },
        fetchPreviousSummary(context, filters) {
            const selectedMonth = dateHelpers.getLastPeriod(filters.dateFilter.timestampFrom, 'month');
            const lastMonthSpan = dateHelpers.getPeriodSpan(selectedMonth, 'month');
            const canGetPreviousReport = lastMonthSpan.timestampFrom < context.rootState.filter.dataSpan.timestampFrom;

            if (canGetPreviousReport) {
                context.commit('resetPreviousMetrics');
            } else {
                const newFilters = Object.assign({}, filters, { dateFilter: lastMonthSpan });
                const newFiltersQs = FilterUtils.flattenFiltersBqi(newFilters);

                const newMetrics = MetricsAPI.fetchMetrics(METRIC_TYPE.PREVIOUS_SUMMARY, newFiltersQs);

                Promise.all(Object.values(newMetrics)).then(previousSummary => {
                    previousSummary = previousSummary.reduce((acc, metric) => {
                        const key = Object.keys(metric)[0];
                        acc[key] = metric[key];
                        return acc;
                    }, {});
                    context.commit('updatePreviousSummary', previousSummary);
                });
            }
            context.commit('resetPreviousMetrics');
        }
    }
};
