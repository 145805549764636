<template>
    <div class="tile is-child card flex-1 is-flex flex-col">
        <div class="card-content is-flex flex-col flex-1">
            <h5 class="title is-size-6">{{ title }}</h5>
            <div class="is-flex flex-col flex-1 has-text-grey-dark">
                <slot name="card-content"></slot>
            </div>
        </div>
        <footer class="card-footer border-0">
            <div class="card-footer-item">
                <slot name="card-footer-item"></slot>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'AccountCard',
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>
