<template>
    <div class="stars is-flex flex-col justify-center mb-3" :class="{ 'group-filled': filled > 4 }">
        <div class="is-flex basic-stars px-3 py-2">
            <span v-for="n in basicGroupSize" :key="n" class="icon mx-2">
                <i class="em-icon" :class="{ 'star-filled': filled > n - 1, 'menu-premium': filled <= n - 1 }"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StarGroup',
    props: {
        filled: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            basicGroupSize: 5
        };
    }
};
</script>

<style lang="scss" scoped>
$filled-star-color: #269bf7;
$gold-star-color: #ffa600;

.stars {
    background-color: rgba(9, 150, 252, 0.1);
    border-radius: 5px;
    height: 4rem;

    .em-icon {
        color: $filled-star-color;
    }
}

.basic-stars > .icon {
    width: 2.25rem;
    height: 2.25rem;

    > .em-icon {
        font-size: 2.25rem;
    }
}

.group-filled {
    background-color: rgba(255, 166, 0, 0.1);
    .em-icon {
        color: $gold-star-color;
    }
}
</style>
