import Vue from 'vue';
import Vuex from 'vuex';

import BULMA_VARIABLES from '../constants/bulma_variables';
import BatchStore from './batch_store';
import BillingStore from './billing_store';
import FilterStore from './filter_store';
import ReferralStore from './referral_store';
import TeamStore from './team_store';
import UserStore from './user_store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        billing: BillingStore,
        filter: FilterStore,
        team: TeamStore,
        user: UserStore,
        referral: ReferralStore,
        batch: BatchStore
    },
    state: {
        windowWidth: 0
    },
    getters: {
        isMobileOrTablet(state) {
            return state.windowWidth <= BULMA_VARIABLES.TABLET;
        },
        isMobile(state) {
            return state.windowWidth <= BULMA_VARIABLES.MOBILE;
        }
    },
    mutations: {
        setWindowWidth(state, width) {
            state.windowWidth = width;
        }
    }
});
