/* eslint-disable camelcase */

import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import DateFilter from '../../../models/filters/date_filter';

export default {
    computed: {
        ...mapGetters('filter', ['dataSpan'])
    },
    methods: {
        emitToParent(dataSpan) {
            if (!dataSpan.from || !dataSpan.to) return this.$emit('filterUpdate', null);
            const tsFrom = dayjs(dataSpan.from)
                .utc()
                .startOf('day')
                .unix();
            const tsTo = dayjs(dataSpan.to)
                .utc()
                .endOf('day')
                .unix();
            this.$emit('filterUpdate', new DateFilter(tsFrom, tsTo));
        }
    }
};
