<template>
    <div class="columns">
        <div class="column is-8-desktop is-offset-2-desktop is-12-mobile">
            <div class="columns is-multiline">
                <div class="column is-full">
                    <h3 v-if="starsCount === 0" class="title is-3 is-size-4-mobile has-text-white has-text-centered">
                        Invite 5 friends to use Email Meter<br />
                        and get Premium free for 1 month
                    </h3>
                    <h3 v-else class="title is-3 is-size-4-mobile has-text-white has-text-centered">You're {{ 5 - starsCount }} friends away from a month of free Premium!</h3>
                    <p class="has-text-centered">
                        Get 1 free month as soon as all 5 friends have signed up. If they later purchase Premium themselves, we'll give you another free month!
                    </p>
                </div>
                <div class="column is-full is-flex flex-col items-center">
                    <div class="is-flex justify-center">
                        <star-group v-if="starsCount === 0" :filled="0"></star-group>
                        <star-group v-else :key="index" :filled="starsCount"></star-group>
                    </div>
                </div>
                <div class="column is-full is-flex justify-center">
                    <a v-if="starsCount === 0" href="https://help.emailmeter.com/en/articles/3366022-get-free-email-meter-premium-with-our-referral-programme" class="underline">How does it work?</a>
                    <referral-header-footer-numbers v-else v-bind="$attrs"></referral-header-footer-numbers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReferralHeaderFooterNumbers from '../footer_numbers.vue';
import StarGroup from '../star_group.vue';

export default {
    name: 'ReferralInProgress',
    components: {
        ReferralHeaderFooterNumbers,
        StarGroup
    },
    props: {
        starsCount: {
            type: Number,
            required: true
        }
    }
};
</script>
