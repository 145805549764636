<template>
    <div class="metric-field">
        <div v-if="metricText" class="metric">{{ metricText }}</div>
        <div v-else class="metric">{{ metricValue }}</div>
        <div class="compare">
            <span v-if="relativeValueToAverage === Infinity" class="has-text-danger">-</span>
            <span v-else class="percentage has-text-success" :class="{ 'has-text-danger': metricValue < metricAverage }">{{ relativeValueToAverage }}%</span>
            avg
        </div>
    </div>
</template>

<script>
export default {
    name: 'MetricField',
    props: {
        metricValue: {
            type: Number,
            required: true
        },
        metricAverage: {
            type: Number,
            required: true
        },
        metricText: {
            type: String,
            default: ''
        }
    },
    computed: {
        relativeValueToAverage() {
            const relativeValue = ((this.metricValue - this.metricAverage) / this.metricAverage) * 100;
            return Math.abs(Math.round(relativeValue));
        }
    }
};
</script>

<style lang="scss" scoped>
.metric-field {
    display: flex;
    align-items: center;
    justify-content: center;

    .metric {
        display: flex;
        align-items: center;
        font-size: 1.375rem;
        font-weight: bold;
        margin-right: 0.625rem;
    }

    .compare {
        font-size: 0.875rem;
        line-height: 0.875rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .percentage {
            font-weight: bold;

            &.has-text-success:before {
                content: '\2191';
            }

            &.has-text-danger:before {
                content: '\2193';
            }
        }
    }
}
</style>
