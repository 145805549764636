<template>
    <div class="faq">
        <div class="is-flex justify-between faq-header" :class="{ 'is-active': isOpen }" @click="toggleFaq">
            {{ question }}
            <i v-if="!isOpen" class="em-icon chevron-down"></i>
            <i v-if="isOpen" class="em-icon chevron-up has-color-primary"></i>
        </div>
        <div v-if="isOpen" class="faq-answer has-text-grey-dark">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq',
    props: {
        question: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        toggleFaq() {
            this.isOpen = !this.isOpen;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.faq-header {
    margin: 2.5rem 1rem 1rem;
    font-weight: bold;
    font-size: 1.2rem;

    &:hover,
    &.is-active {
        cursor: pointer;
        color: $primary;
    }
}

.faq-answer {
    margin: 0 2rem;
    font-size: 0.9rem;
}
</style>
