<template>
    <div id="filter-wrapper">
        <filter-navbar :is-expanded="showFilters" @toggle-filters="toggleFilters"></filter-navbar>
        <div v-show="showFilters" class="filters-container shadow">
            <div v-if="Boolean(currentPageFilters)" class="px-4">
                <monthly-filter v-if="$route.path === $em.constants.PAGE_PATHS.monthly" @filterUpdate="updateDateFilter"> </monthly-filter>
                <weekly-filter v-if="$route.path === $em.constants.PAGE_PATHS.weekly" @filterUpdate="updateDateFilter"> </weekly-filter>
                <date-filter
                    v-if="canUseAdvancedFilters && ($route.path === $em.constants.PAGE_PATHS.custom || $route.path === $em.constants.PAGE_PATHS.team)"
                    @filterUpdate="updateDateFilter"
                ></date-filter>
                <user-filter v-if="canUseAdvancedFilters && usersList.length > 1" :filter="usersFilter" :users-list="usersList" @filterUpdate="updateUsersFilter"></user-filter>
                <advanced-filters
                    v-if="$route.path !== $em.constants.PAGE_PATHS.team && $waffle.switch_is_active('advanced_filters')"
                    :filters="advancedFilters"
                    @filterUpdate="updateAdvancedFilters"
                ></advanced-filters>
            </div>
            <div class="filter-buttons">
                <div class="buttons mb-0 button-wrapper">
                    <button class="button is-primary is-fullwidth" :disabled="!enableApplyBtn" @click="saveFilters()">Apply Filters</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import FilterUtils from '../../models/filters/filter_utils';
import AdvancedFilters from './advanced_filters.vue';
import DateFilter from './date_filter.vue';
import FilterNavbar from './filter_navbar.vue';
import MonthlyFilter from './monthly_filter.vue';
import UserFilter from './user_filter.vue';
import WeeklyFilter from './weekly_filter.vue';

export default {
    name: 'FilterWrapper',
    components: {
        FilterNavbar,
        DateFilter,
        MonthlyFilter,
        WeeklyFilter,
        UserFilter,
        AdvancedFilters
    },
    data() {
        return {
            dateFilter: null,
            usersFilter: null,
            advancedFilters: [],
            showFilters: false,
            filterValidation: {
                dateFilter: true,
                usersFilter: true,
                advancedFilters: true
            },
            filtersInitialized: false,
            advancedFilterValid: true
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('team', { usersList: 'getAllTeamMembers' }),
        ...mapGetters('filter', ['currentPageFilters']),
        enableApplyBtn() {
            return this.filtersInitialized && Object.values(this.filterValidation).every(Boolean) && this.advancedFilterValid;
        },
        canUseAdvancedFilters() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canAdvancedFilters);
        }
    },
    watch: {
        '$route'() {
            this.showFilters = false;
            this.filtersInitialized = false;
            this.refreshFilters();
            this.$emit('show-content', !this.showFilters);
        }
    },
    beforeMount() {
        this.refreshFilters();
    },
    methods: {
        refreshFilters() {
            let parsedFilters = this.$store.state.filter.filters[this.$route.path];
            if (!parsedFilters) {
                parsedFilters = FilterUtils.parseUrl(this.$route.query);
            }

            if (parsedFilters.dateFilter === null) {
                this.filterValidation.dateFilter = false;
                return;
            } else {
                this.filterValidation.dateFilter = true;
            }
            this.dateFilter = parsedFilters.dateFilter;
            this.usersFilter = parsedFilters.usersFilter;
            this.advancedFilters = parsedFilters.advancedFilters || [];
        },
        updateDateFilter(dateFilter) {
            if (dateFilter) this.dateFilter = dateFilter;
            this.filtersInitialized = true;
            this.filterValidation.dateFilter = Boolean(dateFilter);
        },
        updateUsersFilter(usersFilter) {
            this.usersFilter = usersFilter;
            this.filtersInitialized = true;
            this.filterValidation.usersFilter = Boolean(usersFilter && usersFilter.value.length > 0);
        },
        updateAdvancedFilters(advancedFilters) {
            this.advancedFilters = advancedFilters;
            this.filtersInitialized = true;
            this.filterValidation.advancedFilters = advancedFilters.every(f => f && f.value && f.value.length >= 1 && f.isValid());
        },
        saveFilters() {
            const qs = FilterUtils.flattenFilters({
                dateFilter: this.dateFilter,
                usersFilter: this.usersFilter,
                advancedFilters: this.advancedFilters.filter(Boolean)
            });

            this.$router.push({
                path: `${this.$route.path}?${qs}`
            });

            this.toggleFilters();
        },
        toggleFilters(newVal) {
            this.showFilters = newVal;
            this.$emit('show-content', !this.showFilters);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

#filter-wrapper {
    flex: 1;

    .filters-container {
        background-color: #fff;
        border-bottom-left-radius: $radius-large;
        border-bottom-right-radius: $radius-large;
    }

    .section {
        border-bottom: 1px solid #efefef;
    }
}

.filter-buttons {
    margin-top: 2rem;
    padding-bottom: 2rem;
    display: flex;

    .button-wrapper {
        width: 150px;
        margin: auto;

        button:not(:last-child) {
            margin-right: 1.5rem;
        }
    }
}
</style>
