import { BillingCoupon, BillingCustomer, BillingInvoice, BillingPlan, BillingShipping, BillingSource, BillingSubscription } from '../../models/billing';
import utils from '../../utils/utils';

export default class BillingParser {
    static parseBillingCustomer(data) {
        const billingAddress = this.parseBillingAddress(data);
        let billingSource = null;
        if (data.sources.total_count > 0) {
            billingSource = this.parseBillingSource(data.sources.data[0]);
        }
        const billingSubscription = this.parseBillingSubscription(data);

        return new BillingCustomer(data.email, billingAddress, billingSource, billingSubscription);
    }

    static parseCouponCode(data) {
        return new BillingCoupon(data.id, data.name, data.percent_off, data.duration, data.duration_in_months);
    }

    static parseBillingSource(data) {
        return new BillingSource({
            creditCardLast4: data.last4,
            creditCardBrand: data.brand,
            expMonth: data.exp_month,
            expYear: data.exp_year
        });
    }

    static parseBillingAddress(data) {
        return new BillingShipping({
            name: utils.getValueOrNull(data, 'shipping.name'),
            line1: utils.getValueOrNull(data, 'shipping.address.line1'),
            city: utils.getValueOrNull(data, 'shipping.address.city'),
            state: utils.getValueOrNull(data, 'shipping.address.state'),
            postalCode: utils.getValueOrNull(data, 'shipping.address.postal_code'),
            country: utils.getValueOrNull(data, 'shipping.address.country')
        });
    }

    static parseBillingSubscription(data) {
        return new BillingSubscription({
            id: utils.getValueOrNull(data, 'subscriptions.data.0.id'),
            quantity: utils.getValueOrNull(data, 'subscriptions.data.0.quantity'),
            planName: utils.getValueOrNull(data, 'subscriptions.data.0.plan.metadata.displayName'),
            amount: utils.getValueOrNull(data, 'subscriptions.data.0.plan.amount'),
            interval: utils.getValueOrNull(data, 'subscriptions.data.0.plan.interval'),
            cancelAtPeriodEnd: utils.getValueOrNull(data, 'subscriptions.data.0.cancel_at_period_end'),
            currentPeriodEnd: utils.getValueOrNull(data, 'subscriptions.data.0.current_period_end')
        });
    }

    static parseBillingInvoices(data) {
        const invoices = [];
        for (let i = 0; i < data.length; i++) {
            const newInvoice = new BillingInvoice({
                date: data[i].date,
                invoiceNumber: data[i].number,
                total: data[i].total,
                description: data[i].description || data[i].lines.data[0].description,
                pdf: data[i].invoice_pdf
            });
            invoices.push(newInvoice);
        }
        return invoices;
    }

    static parseBillingPlans(stripePlans) {
        const plans = [];
        for (let i = 0; i < stripePlans.length; i++) {
            const newPlan = new BillingPlan({
                id: stripePlans[i].stripe_id,
                name: stripePlans[i].name
            });
            plans.push(newPlan);
        }
        return plans;
    }
}
