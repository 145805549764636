<template>
    <div class="columns flex-row-reverse">
        <div class="column is-flex">
            <div class="columns is-multiline">
                <div class="column is-full">
                    <h3 class="title is-3 is-size-4-mobile has-text-white has-text-centered-mobile">{{ title }}</h3>
                </div>
                <div class="column is-full">
                    <div class="is-flex flex-wrap sm:justify-center">
                        <star-group :filled="5"></star-group>
                    </div>
                </div>
                <div class="column is-full is-flex-mobile sm:justify-center">
                    <referral-header-footer-numbers v-bind="$attrs"></referral-header-footer-numbers>
                </div>
                <div class="column is-full has-text-centered-mobile">
                    <p>We’re excited to welcome you to Premium. Ready to get started?</p>
                </div>
                <div class="column is-full has-text-centered-mobile">
                    <button class="button is-size-5 is-green" @click="$emit('activate-premium')">Activate Premium</button>
                </div>
            </div>
        </div>
        <div class="column is-flex justify-center">
            <img src="../../../../../resources/static/images/illustration_referral_header_balloons.svg" />
        </div>
    </div>
</template>

<script>
import ReferralHeaderFooterNumbers from '../footer_numbers.vue';
import StarGroup from '../star_group.vue';

export default {
    name: 'ReferralHasAchievedPremium',
    components: {
        ReferralHeaderFooterNumbers,
        StarGroup
    },
    props: {
        monthsAchieved: {
            type: Number,
            required: true
        }
    },
    computed: {
        title() {
            const numberOfMonths = this.monthsAchieved === 1 ? 'a free month' : `${this.monthsAchieved} free months`;
            return `Top work! You've earned ${numberOfMonths} of Premium`;
        }
    }
};
</script>
