import '@babel/polyfill';
import 'whatwg-fetch';

import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import dayjs from 'dayjs';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VueRouter from 'vue-router';
import { ClientTable } from 'vue-tables-2';

import app from './app.vue';
import * as EM_CONSTANTS from './constants/em';
import router from './router/router';
import store from './store/main_store';
import AppBus from './utils/app_bus';
import utils from './utils/utils';

window.bugsnagClient = bugsnag({
    apiKey: process.env.BUGSNAG_KEY,
    releaseStage: process.env.NODE_ENV,
    appVersion: process.env.CI_COMMIT_SHORT_SHA,
    notifyReleaseStages: ['production', 'staging'],
    autoBreadcrumbs: true,
    maxBreadcrumbs: 40
});
window.bugsnagClient.use(bugsnagVue, Vue);

Vue.use(VueRouter);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(ClientTable, {}, false, 'bulma', 'default');

Vue.prototype.$bus = new AppBus();
Vue.prototype.$em = {
    constants: EM_CONSTANTS
};

Vue.filter('formatDate', (timestamp, format = 'MMM D, YYYY') => {
    return dayjs
        .unix(timestamp)
        .utc()
        .format(format);
});

Vue.filter('formatCurrency', utils.formatCurrency);

Vue.filter('toFixed', val => {
    const isInteger = Number.isInteger(val);
    if (!isInteger) {
        return Number.parseFloat(val).toFixed(2);
    }
    return val;
});

Vue.filter('pluralize', (word, amount) => (amount > 1 ? `${word}s` : word));

new Vue({
    router: router,
    store: store,
    data() {
        return {
            batchLoading: false
        };
    },
    render: h => h(app)
}).$mount('#app');
