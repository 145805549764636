import ReferralApi from '../../../api/referral_api';
import Log from '../../../utils/log';

export default {
    methods: {
        sendInvitation(email, cb) {
            ReferralApi.createReferralInvitation(email)
                .then(() => {
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: `Invitation sent successfully to ${email}`
                    });
                    this.$store.commit('referral/addNewInvitation', email);
                    if (cb) cb(true);
                })
                .catch(({ debug }) => {
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: `Error sending invitation to ${email}`
                    });
                    Log.error(new Error('Error while inviting (referral program)'), { ...debug });
                    if (cb) cb(false);
                });
        }
    }
};
