import dayjs from 'dayjs';

export default {
    proccessByDaySeries(series) {
        return series.reduce((acc, el) => {
            const formattedDate = dayjs.utc(el.unit).unix() * 1000;
            return Object.assign({}, acc, { [formattedDate]: el.count });
        }, {});
    },
    getMetricCountOrZero(metrics, selector, value) {
        const metric = metrics.filter(item => item[selector] === value);
        if (metric.length === 0) return 0;
        else return metric[0].count;
    },
    heatmapFormat(metrics) {
        const days = dayjs.en.weekdays;
        const result = metrics
            .map(item => {
                return {
                    name: `${dayjs()
                        .hour(item.hour)
                        .minute(0)
                        .format('HH:mm')}`,
                    data: [
                        {
                            x: days[item.weekday][0],
                            y: item.count
                        }
                    ]
                };
            })
            .reduce((list, item) => {
                const found = list.find(f => f.name === item.name);
                const value = item.data[0];
                if (!found) {
                    list.push({ name: item.name, data: [value] });
                } else {
                    found.data.push(value);
                }
                return list;
            }, [])
            .reverse();
        return result;
    },
    processTimeDataSeries(series) {
        const result = {
            '< 15m': 0,
            '1h': 0,
            '4h': 0,
            '12h': 0,
            '1d': 0,
            '2d': 0,
            '> 2d': 0
        };
        for (let i = 0; i < series.length; i++) {
            result[series[i].intervals] = series[i].count;
        }
        return result;
    },
    processTopInteractionField(rawInteractions) {
        return rawInteractions.map(element => {
            return {
                email: element.email_address,
                messages: element.messages,
                responseTime: element.response_time,
                contactTime: element.best_contact_time,
                receivedMessages: element.received_messages,
                sentMessages: element.sent_messages
            };
        });
    },
    processTeamStats(rawTeamStats) {
        return rawTeamStats.map(item => ({
            googleUserId: item.gmail_user_id,
            fullName: item.name,
            email: item.email,
            received: item.received,
            sent: item.sent,
            responseRate: item.response_rate,
            avgResponse: item.avg_response
        }));
    }
};
