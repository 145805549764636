<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal mb-4">Edit your payment method</h2>
        <div class="columns">
            <div class="column is-8 is-offset-2">
                <card-form :save-payment="savePayment" @modified-payment="modifiedPayment" @payment-data-ok="isPaymentDataOk"></card-form>
            </div>
        </div>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary mr-4" :class="{ 'is-loading': requestLoading }" :disabled="!canTriggerPay || requestLoading" @click="gettingCardToken()">Save</button>
        </div>
    </div>
</template>

<script>
import CardForm from '../paywall/card_form.vue';
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'EditPaymentMethod',
    components: { CardForm },
    mixins: [ModalMixin],
    data() {
        return {
            savePayment: false,
            canTriggerPay: false,
            requestLoading: false
        };
    },
    methods: {
        gettingCardToken() {
            this.savePayment = true;
            this.requestLoading = true;
        },
        isPaymentDataOk(val) {
            this.canTriggerPay = val;
            this.requestLoading = false;
        },
        modifiedPayment(token) {
            const cardData = {
                source: token.id
            };

            this.$store
                .dispatch('billing/updateCustomer', cardData)
                .then(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: 'Done! You’ve updated your payment method successfully.'
                    });
                })
                .catch(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
            this.savePayment = false;
        }
    }
};
</script>
