// TODO: sync with backend and create a JSON with all metrics, then have per page dict

const REPORT = {
    totalUniqueSenders: 'totalUniqueSenders',
    totalEmailsReceived: 'totalEmailsReceived',
    totalReceivedAndAnswered: 'totalReceivedAndAnswered',
    totalDirectMessages: 'totalDirectMessages',
    totalIndirectMessages: 'totalIndirectMessages',
    totalOtherMessages: 'totalOtherMessages',
    totalInternalMessagesReceived: 'totalInternalMessagesReceived',
    totalExternalMessagesReceived: 'totalExternalMessagesReceived',
    receivedHistogram: 'receivedHistogram',
    totalUniqueRecipients: 'totalUniqueRecipients',
    totalSentAndAnswered: 'totalSentAndAnswered',
    totalEmailsSent: 'totalEmailsSent',
    totalNewThreadsReplies: 'totalNewThreadsReplies',
    totalNewThreads: 'totalNewThreads',
    totalInternalMessagesSent: 'totalInternalMessagesSent',
    totalExternalMessagesSent: 'totalExternalMessagesSent',
    sentHistogram: 'sentHistogram',
    minReplyTime: 'minReplyTime',
    averageFirstResponseTime: 'averageFirstResponseTime',
    averageResponseTime: 'averageResponseTime',
    timeBeforeFirstResponse: 'timeBeforeFirstResponse',
    topInteractions: 'topInteractions',
    topInteractionsByDomain: 'topInteractionsByDomain',
    receivedHeatmap: 'receivedHeatmap',
    sentHeatmap: 'sentHeatmap'
};

const CSV_EXPORT = {
    csvExport: 'csvExport'
};

const PREVIOUS_SUMMARY = {
    totalUniqueSenders: 'totalUniqueSenders',
    totalEmailsReceived: 'totalEmailsReceived',
    totalUniqueRecipients: 'totalUniqueRecipients',
    totalEmailsSent: 'totalEmailsSent',
    averageResponseTime: 'averageResponseTime'
};

const TEAM = {
    teamStats: 'teamStats'
};

export default {
    REPORT,
    PREVIOUS_SUMMARY,
    TEAM,
    CSV_EXPORT,
    ALL: {
        ...REPORT,
        ...TEAM,
        ...CSV_EXPORT
    }
};
