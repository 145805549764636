<template>
    <div id="emails-replied">
        <div class="columns is-flex-desktop flex-1">
            <div class="column is-flex flex-col justify-end items-center">
                <div class="is-flex flex-1 flex-col">
                    <p class="is-uppercase is-size-8 has-text-centered">Sent</p>
                    <text-with-tag text="Replied" :value="totalSentAndAnswered" :color="greenColors[0]"></text-with-tag>
                    <text-with-tag text="Not replied" :value="totalSentAndNotAnswered" :color="greenColors[1]"></text-with-tag>
                </div>
                <div>
                    <apexchart type="donut" width="180px" height="180px" :options="sentChartOptions" :series="sentGraphData"></apexchart>
                </div>
            </div>
            <div class="column is-flex flex-col justify-end items-center">
                <div class="is-flex flex-1 flex-col">
                    <p class="is-uppercase is-size-8 has-text-centered">Received</p>
                    <text-with-tag text="Replied" :value="totalReceivedAndAnswered" :color="blueColors[0]"></text-with-tag>
                    <text-with-tag text="Not replied" :value="totalReceivedAndNotAnswered" :color="blueColors[1]"></text-with-tag>
                </div>
                <div>
                    <apexchart type="donut" width="180px" height="180px" :options="receivedChartOptions" :series="receivedGraphData"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { DONUT_CHART_OPTIONS } from './chart_options';
import TextWithTag from './text_with_tag.vue';

export default {
    name: 'EmailsReplied',
    components: {
        TextWithTag
    },
    mixins: [ReportMetricMixin],
    data() {
        return {
            baseChartOptions: Object.assign({}, DONUT_CHART_OPTIONS, { labels: ['Replied', 'Not Replied'] })
        };
    },
    computed: {
        totalSentAndAnswered() {
            return this.componentMetrics.totalSentAndAnswered.value;
        },
        totalSentAndNotAnswered() {
            return this.componentMetrics.totalEmailsSent.value - this.totalSentAndAnswered;
        },
        totalReceivedAndAnswered() {
            return this.componentMetrics.totalReceivedAndAnswered.value;
        },
        totalReceivedAndNotAnswered() {
            return this.componentMetrics.totalEmailsReceived.value - this.totalReceivedAndAnswered;
        },
        receivedGraphData() {
            return [this.totalReceivedAndAnswered, this.totalReceivedAndNotAnswered];
        },
        sentGraphData() {
            return [this.totalSentAndAnswered, this.totalSentAndNotAnswered];
        },
        sentChartOptions() {
            return Object.assign({}, this.baseChartOptions, { colors: this.greenColors });
        },
        receivedChartOptions() {
            return Object.assign({}, this.baseChartOptions, { colors: this.blueColors });
        }
    }
};
</script>
