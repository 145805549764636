<template>
    <div id="emailmeter-app">
        <div v-if="pageLoaded" id="page" class="is-flex flex-1 flex-col" :class="{ 'has-top-bar': showTopBar }">
            <top-bar-notifications v-if="showTopBar"></top-bar-notifications>
            <em-header></em-header>
            <onboarding v-if="showUserOnboarding" @form-dismissed="onOnboardingFormDismiss"></onboarding>
            <div v-else>
                <main-menu v-if="$route.name !== PAGE_NAMES.getPremium"></main-menu>
                <div class="main-container" :class="{ 'ml-0': $route.name === PAGE_NAMES.getPremium }">
                    <!-- Current page-->
                    <router-view></router-view>
                    <!-- End of current page-->
                    <em-footer v-if="$route.name !== PAGE_NAMES.getPremium"></em-footer>
                </div>
                <premium-batch-loading v-if="premiumBatch && !premiumBatch.isComplete()"></premium-batch-loading>
            </div>
        </div>
        <page-loader v-else></page-loader>
        <notification></notification>
        <modals></modals>
    </div>
</template>

<script>
import sha256 from 'js-sha256';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import BaseApi from './api/api_base';
import TeamApi from './api/team_api';
import EmFooter from './components/base/footer.vue';
import EmHeader from './components/base/header.vue';
import MainMenu from './components/base/main_menu.vue';
import Modals from './components/base/modals.vue';
import Notification from './components/base/notifications.vue';
import PageLoader from './components/base/page_loader.vue';
import TopBarNotifications from './components/base/top_bar_notifications';
import Onboarding from './components/onboarding/onboarding.vue';
import PremiumBatchLoading from './components/paywall/premium_batch_loading.vue';
import SetupBrowserSize from './mixins/setup_browser_size';
import { BATCH_KINDS } from './models/batch';
import { INVITATION_STATUS } from './models/invitation';
import { TEAM_MEMBER_STATUS } from './models/team';
import PAGE_NAMES from './router/pages';
import dateHelpers from './utils/date_helpers';
import Log from './utils/log';
import modalsEnum from './utils/modals_enum';
import waffleDefaults from './utils/waffle';

export default {
    components: {
        EmFooter,
        EmHeader,
        MainMenu,
        Modals,
        Notification,
        Onboarding,
        PageLoader,
        PremiumBatchLoading,
        TopBarNotifications
    },
    mixins: [SetupBrowserSize],
    data() {
        return {
            PAGE_NAMES,
            pageLoaded: false,
            showOnboardingForm: false,
            showChromeExtModal: 'signup_from_chext' in this.$route.query
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('batch', ['onboardingBatch', 'premiumBatch']),
        ...mapGetters(['isMobileOrTablet']),
        showUserOnboarding() {
            return (this.onboardingBatch && !this.onboardingBatch.isComplete()) || this.showOnboardingForm;
        },
        showTopBar() {
            return this.$waffle.switch_is_active('top_navbar_active') && !this.isMobileOrTablet && this.$route.name !== PAGE_NAMES.getPremium;
        }
    },
    beforeMount() {
        this.setupWindowWidth();
        this.$store
            .dispatch('user/fetchUser')
            .then(async user => {
                this.initGTM(user);
                this.initErrorHandlers(user);

                await this.initUser(user);
                await this.initNonBasicUser(user);
                await this.initMyAccount(user);

                Vue.prototype.$waffle = window.waffle || waffleDefaults;
                this.showOnboardingForm = this.onboardingBatch && !this.onboardingBatch.isComplete();
                this.pageLoaded = true;

                if (!this.showOnboardingForm && this.showChromeExtModal) {
                    this.$bus.emit(this.$bus.events.showModal, modalsEnum.chromeExtension);
                }

                this.$store.dispatch('billing/fetchPlans');
                this.initReferral(user);
                this.displayUserInvitations();
                window.addEventListener('unauthenticated', this.forcePageReload);
            })
            .catch(() => {
                Log.warn('Unauthenticated! Please login and try again.');
                this.redirectToLogin();
            });
    },
    beforeDestroy() {
        this.removeListener();
    },
    methods: {
        async initUser(user) {
            if (!user.config.batchDone) {
                this.$store.dispatch('user/updateUserConfig', {
                    timezone: dateHelpers.getBrowserTimezone()
                });
                window.dataLayer.push({
                    event: 'newBasicOnboarding'
                });
                const batches = await this.$store.dispatch('batch/fetchBatches');
                this.pollBatchInBackground();
                return batches;
            }
        },
        pollBatchInBackground() {
            return this.$store.dispatch('batch/pollBatchProgress', { kind: BATCH_KINDS.onboarding, timelapse: 1000 }).then(async () => {
                this.$store.commit('filter/setDataSpan', {
                    timestampTo: Math.floor(new Date(this.onboardingBatch.endDate).valueOf() / 1000)
                });
                await this.$store.dispatch('batch/pollBatchProgress', { kind: BATCH_KINDS.onboardingGap, timelapse: 5000 });
                this.$store.dispatch('user/fetchUser');
            });
        },
        async initMyAccount(user) {
            if (user.permissions(this.$em.constants.USER_PERMISSIONS.canMyAccountBilling)) {
                this.$store.dispatch('billing/fetchInvoices');
                await this.$store.dispatch('billing/fetchCustomer');
                this.$store.dispatch('batch/fetchBatches');
            }
        },
        initNonBasicUser(user) {
            if (user.permissions(this.$em.constants.USER_PERMISSIONS.canManageTeams)) {
                return this.$store.dispatch('team/fetchTeams').then(() => {
                    this.$store.dispatch('team/fetchInvitations');
                    this.displayCredentialsKONotification();
                });
            } else {
                return Promise.resolve();
            }
        },
        initReferral(user) {
            if (user.permissions(this.$em.constants.USER_PERMISSIONS.canReferralProgram)) {
                this.$store.dispatch('referral/fetchStatus');
                this.$store.dispatch('referral/fetchSuggestions');
            }
        },
        initGTM(user) {
            window.dataLayer.push({
                userId: sha256(user.googleUserId),
                product: user.client.productName,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                event: 'datalayer_ready'
            });
        },
        initErrorHandlers(user) {
            window.bugsnagClient.user = {
                id: user.email,
                email: user.email
            };
        },
        displayUserInvitations() {
            TeamApi.getInvitations(TeamApi.invitationKind.received).then(invitations => {
                const pendingInvitations = Object.values(invitations)
                    .reduce((i, acc) => acc.concat(i), [])
                    .filter(i => i.status === INVITATION_STATUS.pending);
                if (pendingInvitations.length > 0) {
                    this.$bus.emit(this.$bus.events.showModal, modalsEnum.teamUserInvitation, { invitation: pendingInvitations[0] });
                }
            });
        },
        displayCredentialsKONotification() {
            const team = this.$store.getters['team/firstTeam'];
            const invalidMembers = team.members.filter(m => m.status === TEAM_MEMBER_STATUS.inactive);

            if (invalidMembers.length > 0) {
                let membersText = `${invalidMembers[0].email}`;
                if (invalidMembers.length > 1) membersText = `${membersText} and ${invalidMembers.length - 1} others`;

                const message = `We lost access to the following accounts over a week ago: ${membersText}. Metrics won’t be accurate until they log in to Email Meter. Go to Team Management to check the status or read this article for more details.`; // eslint-disable-line max-len

                this.$bus.emit(this.$bus.events.notification, {
                    bgColor: 'is-warning',
                    text: message,
                    timeLapse: 0
                });
            }
        },
        redirectToLogin() {
            const nextURL = encodeURIComponent(window.location.href);
            BaseApi.redirectTo(`${process.env.ENDPOINT_BACKEND}/login/basic?next=${nextURL}`);
        },
        forcePageReload() {
            window.location.reload();
        },
        onOnboardingFormDismiss() {
            this.showOnboardingForm = false;
            if (this.showChromeExtModal) {
                this.$bus.emit(this.$bus.events.showModal, modalsEnum.chromeExtension);
            }
        }
    }
};
</script>
