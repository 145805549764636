const BATCH_STATUS = {
    done: 'D',
    running: 'R',
    created: 'C',
    error: 'E'
};

const BATCH_KINDS = {
    onboarding: 'onboarding',
    premium: 'premium',
    premiumTeam: 'prm_team',
    restore: 'restore',
    custom: 'custom',
    onboardingGap: 'onbrd_gap'
};

class BatchData {
    constructor(data) {
        this.id = data.id;
        this.progress = data.progress;
        this.status = data.status;
        this.days = data.days;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.total = data.total;
        this.kind = data.kind;
    }

    isComplete() {
        return this.status === BATCH_STATUS.done;
    }
}

export { BATCH_STATUS, BATCH_KINDS, BatchData };
