<template>
    <div class="columns">
        <div class="column is-8-desktop is-offset-2-desktop is-12-mobile">
            <div class="columns is-multiline">
                <div class="column is-full has-text-centered">
                    <h3 class="title is-3 is-size-4-mobile has-text-white">Your free month of Premium has expired</h3>
                    <p>But fear not, you can extend your free Premium by inviting more friends! Otherwise, buy it to enjoy it permanently.</p>
                </div>
                <div class="column is-full is-flex justify-center">
                    <router-link v-if="monthsAchieved === 0" tag="button" to="/get-premium/select-product" class="button is-size-5 is-green">Buy Premium</router-link>
                </div>
                <div class="column is-flex justify-center">
                    <star-group :filled="starsCount"></star-group>
                </div>
                <div class="column is-full is-flex justify-center">
                    <referral-header-footer-numbers v-bind="$attrs"></referral-header-footer-numbers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReferralHeaderFooterNumbers from '../footer_numbers.vue';
import StarGroup from '../star_group.vue';

export default {
    name: 'ReferralPremiumEnded',
    components: {
        ReferralHeaderFooterNumbers,
        StarGroup
    },
    props: {
        starsCount: {
            type: Number,
            required: true
        },
        monthsAchieved: {
            type: Number,
            required: true
        }
    }
};
</script>
