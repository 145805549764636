<template>
    <onboarding-form v-if="showOnboardingForm" @form-dismissed="onFormDismissed"></onboarding-form>
    <onboarding-batch-loader v-else></onboarding-batch-loader>
</template>

<script>
import OnboardingBatchLoader from './onboarding_batch_loader.vue';
import OnboardingForm from './onboarding_form.vue';

export default {
    name: 'Onboarding',
    components: { OnboardingBatchLoader, OnboardingForm },
    data() {
        return {
            showOnboardingForm: true
        };
    },
    methods: {
        onFormDismissed() {
            this.$emit('form-dismissed');
            this.showOnboardingForm = false;
        }
    }
};
</script>
