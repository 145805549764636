import { mapState } from 'vuex';

import MetricType from '../../../models/metric_type';
import METRICS_SETUP from '../metrics/metrics_setup';

export default {
    data() {
        return {
            chartColors: ['#08b545', '#0096fb'],
            greenColors: ['#08b545', '#cef0da'],
            blueColors: ['#0096fb', '#cceafe'],
            blueColors3Slices: ['#0096fb', '#8dbcfd', '#cceafe'],
            MetricType: MetricType.ALL,
            componentMetrics: null,
            METRICS_SETUP
        };
    },
    props: {
        requiredMetrics: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    computed: {
        ...mapState('filter', ['metrics'])
    },
    beforeMount() {
        this.componentMetrics = this.$props.requiredMetrics.reduce((acc, el) => {
            return Object.assign({}, acc, { [el]: this.metrics[el] });
        }, {});
    }
};
