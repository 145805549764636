<template>
    <div v-if="!canCustomReports">Would you like to look at other dates? <router-link to="/get-premium">Go back up to 2 years with Email Meter Premium.</router-link></div>
</template>

<script>
export default {
    name: 'PremiumCta',
    computed: {
        canCustomReports() {
            const user = this.$store.state.user.user;
            return user.permissions(this.$em.constants.USER_PERMISSIONS.canCustomReport);
        }
    }
};
</script>
