<template>
    <div>
        <h3 class="title is-3 has-text-centered">{{ title }}</h3>
        <p class="has-text-centered mb-6">
            Start enjoying the benefits of a Premium user now for just <span class="has-text-primary has-text-weight-bold">${{ STRIPE_PRICING.yearlyJune2019 }}/month</span> billed yearly. You can
            cancel anytime.
        </p>
        <div class="columns is-flex is-multiline paywall-cta">
            <div class="column is-flex flex-col justify-center">
                <paywall-referral-link v-if="$store.getters.isMobile" class="has-text-centered"></paywall-referral-link>
                <slot :illustration="illustration">
                    <figure class="image px-5 mx-auto paywall-illustration">
                        <img :src="illustration" />
                    </figure>
                </slot>
                <paywall-referral-link v-if="!$store.getters.isMobile" class="has-text-centered mt-4"></paywall-referral-link>
            </div>
            <div class="column">
                <div class="features is-flex flex-col mb-2">
                    <div v-for="(feature, index) in features" :key="index" class="flex-1 is-flex mb-2">
                        <span class="icon mr-2">
                            <i class="em-icon tick-list has-text-primary"></i>
                        </span>
                        <p class="has-text-weight-bold has-text-primary">{{ feature }}</p>
                    </div>
                </div>
                <div class="mb-8 mt-2 is-flex is-fullwidth">
                    <a href="https://emailmeter.com/premium/" target="_blank" class="has-text-primary underline ml-auto">More info on Premium features</a>
                </div>
                <a class="button is-primary is-fullwidth is-large mb-6 paywall-cta-button" @click="goToPage()">
                    <span class="is-size-6">{{ goPremium.btnText }}</span>
                </a>
                <div v-if="displayPaymentInfo" class="payment-info">
                    <div class="secure-info">
                        <figure class="image secure-img">
                            <img src="../../../resources/static/images/icons/secure_payment.svg" />
                        </figure>
                        <span class="secure-text is-size-8">100% secure</span>
                    </div>
                    <div class="is-flex mx-auto">
                        <figure class="mr-4">
                            <img src="../../../resources/static/images/icons/visa_logo.svg" />
                        </figure>
                        <figure class="mr-4">
                            <img src="../../../resources/static/images/icons/mastercard_logo.svg" />
                        </figure>
                        <figure>
                            <img src="../../../resources/static/images/icons/americanexpress_logo.svg" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { STRIPE_PRICING } from '../../models/billing';
import { TITLE_FEATURES } from './paywall_defaults';
import PaywallReferralLink from './paywall_referral_link.vue';

export default {
    name: 'PaywallModal',
    components: {
        PaywallReferralLink
    },
    props: {
        trackingEvent: {
            type: String,
            default: null
        },
        title: {
            type: String,
            required: true
        },
        illustration: {
            type: String,
            required: true
        },
        features: {
            type: Array,
            default: () => TITLE_FEATURES
        },
        goPremium: {
            type: Object,
            default() {
                return {
                    link: '/get-premium/select-product',
                    btnText: 'Get Premium'
                };
            }
        },
        displayPaymentInfo: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            STRIPE_PRICING
        };
    },
    mounted() {
        if (this.trackingEvent) window.dataLayer.push({ event: this.trackingEvent });
    },
    methods: {
        goToPage() {
            this.$bus.emit(this.$bus.events.closeModal);
            this.$router.push({ path: this.goPremium.link });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.paywall-cta {
    max-width: 900px;
    margin: 0 auto;

    @include mobile {
        flex-direction: column-reverse;
        .column:first-child {
            flex-basis: auto;
        }
    }

    .paywall-illustration {
        @include mobile {
            width: 100%;
            max-width: 274px;
            margin: auto;
        }
    }

    .payment-info {
        display: flex;
        justify-content: space-between;

        @include touch {
            flex-direction: column;
        }

        .secure-info {
            display: flex;
            margin: 0 auto;

            @include touch {
                margin-bottom: 1rem;
            }

            .secure-img {
                width: 28px;
                height: auto;
                margin-right: 0.5rem;
            }
            .secure-text {
                color: #6b7c93;
            }
        }
    }
}
</style>
