import Vue from 'vue';

const EVENTS = {
    notification: 'show-notification',
    resendReferralEmail: 'resend-referral-email',
    showModal: 'show-modal',
    closeModal: 'close-modal'
};

export default class AppBus {
    constructor() {
        this.bus = new Vue();
        this.events = EVENTS;
    }

    emit(eventName, ...args) {
        this.bus.$emit(eventName, ...args);
    }

    on(eventName, cb) {
        this.bus.$on(eventName, cb);
    }

    off(eventName, cb) {
        this.bus.$off(eventName, cb);
    }
}
