<template>
    <div id="new-threads">
        <div class="columns flex-1">
            <div class="column is-flex flex-col justify-end items-center">
                <div class="is-flex flex-1 flex-col">
                    <text-with-tag text="Threads you started" :value="componentMetrics.totalNewThreads.value" :color="greenColors[0]"></text-with-tag>
                    <text-with-tag text="To existing threads" :value="componentMetrics.totalNewThreadsReplies.value" :color="greenColors[1]"></text-with-tag>
                </div>
                <div>
                    <apexchart
                        type="donut"
                        width="180px"
                        height="180px"
                        :options="chartOptions"
                        :series="[componentMetrics.totalNewThreads.value, componentMetrics.totalNewThreadsReplies.value]"
                    ></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import { DONUT_CHART_OPTIONS } from './chart_options';
import TextWithTag from './text_with_tag.vue';

export default {
    name: 'NewThreads',
    components: {
        TextWithTag
    },
    mixins: [ReportMetricMixin],
    computed: {
        chartOptions() {
            return Object.assign({}, DONUT_CHART_OPTIONS, { labels: ['New Threads', 'Replies'] }, { colors: this.greenColors });
        }
    }
};
</script>
