import { INVITATION_STATUS, PURCHASES_STATUS, ReferralStatus } from '../../models/referral';

export default class ReferralParser {
    static parseStatus(data) {
        return new ReferralStatus({
            invitations: {
                [INVITATION_STATUS.PENDING]: data.invitations[INVITATION_STATUS.PENDING],
                [INVITATION_STATUS.ACCEPTED]: data.invitations[INVITATION_STATUS.ACCEPTED],
                [INVITATION_STATUS.CONSUMED]: data.invitations[INVITATION_STATUS.CONSUMED]
            },
            purchases: {
                [PURCHASES_STATUS.AVAILABLE]: data.premium_purchases[PURCHASES_STATUS.AVAILABLE],
                [PURCHASES_STATUS.CONSUMED]: data.premium_purchases[PURCHASES_STATUS.CONSUMED]
            },
            premiumTimestampEnd: data.period_end ? data.period_end * 1000 : null
        });
    }
}
