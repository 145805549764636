import METRIC_TYPE from '../../models/metric_type';
import bqiParserHelper from './bqi_parser_helper';

export const STATUS = {
    LOADED: 'LOADED',
    LOADING: 'LOADING',
    ERROR: 'ERROR'
};

class MetricValueError extends Error {}

export class MetricData {
    constructor(status = STATUS.LOADING, value = null) {
        this._status = status;
        this._value = value;
    }

    get status() {
        return this._status;
    }

    set status(newStatus) {
        if (Object.keys(STATUS).includes(newStatus)) {
            this._status = newStatus;
        } else {
            throw new MetricValueError(`${newStatus} is not a valid status`);
        }
    }

    get value() {
        if (this._status !== STATUS.LOADED) throw new MetricValueError(`Metric is not yet loaded (${this._status})`);
        else return this._value;
    }

    set value(newValue) {
        this._value = newValue;
    }
}

class MetricParser {
    constructor(name, endpoint, parser, config = {}) {
        this.name = name;
        this.endpoint = endpoint;
        this.config = config;
        this._parser = parser;
    }

    parseMetric(res) {
        return { [this.name]: new MetricData(STATUS.LOADED, this._parser(res)) };
    }
}

/* eslint-disable */
export const PARSER = {
    [METRIC_TYPE.ALL.totalEmailsSent]: new MetricParser(
        METRIC_TYPE.ALL.totalEmailsSent,
        '/bqi/total_sent_emails',
        res => res[0].count
    ),
    [METRIC_TYPE.ALL.totalEmailsReceived]: new MetricParser(
        METRIC_TYPE.ALL.totalEmailsReceived,
        '/bqi/total_received_emails',
        res => res[0].count
    ),
    [METRIC_TYPE.ALL.totalUniqueRecipients]: new MetricParser(
        METRIC_TYPE.ALL.totalUniqueRecipients,
        '/bqi/sent_messages_list_by_recipient',
        res => res.length
    ),
    [METRIC_TYPE.ALL.totalUniqueSenders]: new MetricParser(
        METRIC_TYPE.ALL.totalUniqueSenders,
        '/bqi/received_messages_list_by_sender',
        res => res.length
    ),
    [METRIC_TYPE.ALL.sentHistogram]: new MetricParser(
        METRIC_TYPE.ALL.sentHistogram,
        '/bqi/sent_emails_histogram',
        bqiParserHelper.proccessByDaySeries,
        {unit: 'days'}
    ),
    [METRIC_TYPE.ALL.receivedHistogram]: new MetricParser(
        METRIC_TYPE.ALL.receivedHistogram,
        '/bqi/received_emails_histogram',
        bqiParserHelper.proccessByDaySeries,
        {unit: 'days'}
    ),
    [METRIC_TYPE.ALL.averageResponseTime]: new MetricParser(
        METRIC_TYPE.ALL.averageResponseTime,
        '/bqi/reply_times',
        res => res[0].avg_reply_time
    ),
    [METRIC_TYPE.ALL.averageFirstResponseTime]: new MetricParser(
        METRIC_TYPE.ALL.averageFirstResponseTime,
        '/bqi/reply_times',
        res => res[0].avg_first_reply
    ),
    [METRIC_TYPE.ALL.minReplyTime]: new MetricParser(
        METRIC_TYPE.ALL.minReplyTime,
        '/bqi/reply_times',
        res => res[0].min_reply_time
    ),
    [METRIC_TYPE.ALL.totalReceivedAndAnswered]: new MetricParser(
        METRIC_TYPE.ALL.totalReceivedAndAnswered,
        '/bqi/reply_times',
        res => res[0].total_replied_emails
    ),
    [METRIC_TYPE.ALL.timeBeforeFirstResponse]: new MetricParser(
        METRIC_TYPE.ALL.timeBeforeFirstResponse,
        '/bqi/reply_times_histogram',
        bqiParserHelper.processTimeDataSeries
    ),
    [METRIC_TYPE.ALL.totalInternalMessagesReceived]: new MetricParser(
        METRIC_TYPE.ALL.totalInternalMessagesReceived,
        '/bqi/received_internal_vs_external_emails',
        res => bqiParserHelper.getMetricCountOrZero(res, 'internal_vs_external', 'internal')
    ),
    [METRIC_TYPE.ALL.totalExternalMessagesReceived]: new MetricParser(
        METRIC_TYPE.ALL.totalExternalMessagesReceived,
        '/bqi/received_internal_vs_external_emails',
        res => bqiParserHelper.getMetricCountOrZero(res, 'internal_vs_external', 'external')
    ),
    [METRIC_TYPE.ALL.totalInternalMessagesSent]: new MetricParser(
        METRIC_TYPE.ALL.totalInternalMessagesSent,
        '/bqi/sent_internal_vs_external_emails',
        res => bqiParserHelper.getMetricCountOrZero(res, 'internal_vs_external', 'internal')
    ),
    [METRIC_TYPE.ALL.totalExternalMessagesSent]: new MetricParser(
        METRIC_TYPE.ALL.totalExternalMessagesSent,
        '/bqi/sent_internal_vs_external_emails',
        res => bqiParserHelper.getMetricCountOrZero(res, 'internal_vs_external', 'external')
    ),
    [METRIC_TYPE.ALL.totalNewThreads]: new MetricParser(
        METRIC_TYPE.ALL.totalNewThreads,
        '/bqi/new_threads',
        res => bqiParserHelper.getMetricCountOrZero(res, 'new_threads', 'new_threads')
    ),
    [METRIC_TYPE.ALL.totalNewThreadsReplies]: new MetricParser(
        METRIC_TYPE.ALL.totalNewThreadsReplies,
        '/bqi/new_threads',
        res => bqiParserHelper.getMetricCountOrZero(res, 'new_threads', 'replies')
    ),
    [METRIC_TYPE.ALL.totalDirectMessages]: new MetricParser(
        METRIC_TYPE.ALL.totalDirectMessages,
        '/bqi/received_direct_messages',
        res => bqiParserHelper.getMetricCountOrZero(res, 'message_type', 'to')
    ),
    [METRIC_TYPE.ALL.totalIndirectMessages]: new MetricParser(
        METRIC_TYPE.ALL.totalIndirectMessages,
        '/bqi/received_direct_messages',
        res => bqiParserHelper.getMetricCountOrZero(res, 'message_type', 'cc')
    ),
    [METRIC_TYPE.ALL.totalOtherMessages]: new MetricParser(
        METRIC_TYPE.ALL.totalOtherMessages,
        '/bqi/received_direct_messages',
        res => bqiParserHelper.getMetricCountOrZero(res, 'message_type', 'others')
    ),
    [METRIC_TYPE.ALL.totalSentAndAnswered]: new MetricParser(
        METRIC_TYPE.ALL.totalSentAndAnswered,
        '/bqi/sent_reply_times',
        res => res[0].total_replied_emails
    ),
    [METRIC_TYPE.ALL.topInteractions]: new MetricParser(
        METRIC_TYPE.ALL.topInteractions,
        '/bqi/top_interactions',
        bqiParserHelper.processTopInteractionField,
        {'group_by': 'address'}
    ),
    [METRIC_TYPE.ALL.topInteractionsByDomain]: new MetricParser(
        METRIC_TYPE.ALL.topInteractionsByDomain,
        '/bqi/top_interactions',
        bqiParserHelper.processTopInteractionField,
        {'group_by': 'domain'}
    ),
    [METRIC_TYPE.ALL.teamStats]: new MetricParser(
        METRIC_TYPE.ALL.teamStats,
        '/bqi/team_stats',
        bqiParserHelper.processTeamStats
    ),
    [METRIC_TYPE.ALL.receivedHeatmap]: new MetricParser(
        METRIC_TYPE.ALL.receivedHeatmap,
        '/bqi/received_emails_heatmap',
        res => res
    ),
    [METRIC_TYPE.ALL.sentHeatmap]: new MetricParser(
        METRIC_TYPE.ALL.sentHeatmap,
        '/bqi/sent_emails_heatmap',
        res => res
    ),
    [METRIC_TYPE.ALL.csvExport]: new MetricParser(
        METRIC_TYPE.ALL.csvExport,
        '/bqi/csv_export',
        res => res
    )
};
/* eslint-enable */
