export default {
    data() {
        return {
            allOwnSuggestions: [],
            fiveOwnSuggestions: []
        };
    },
    watch: {
        suggestions: {
            immediate: true,
            handler(newVal) {
                this.allOwnSuggestions = newVal.slice(0);
                this.fiveOwnSuggestions = this.allOwnSuggestions.splice(0, 5);
            }
        }
    },
    methods: {
        removeSuggestion(index) {
            this.$set(this.fiveOwnSuggestions, index, { empty: true });
            setTimeout(() => {
                if (this.allOwnSuggestions.length > 0) {
                    this.$set(this.fiveOwnSuggestions, index, this.allOwnSuggestions[0]);
                    this.$delete(this.allOwnSuggestions, 0);
                } else {
                    this.$delete(this.fiveOwnSuggestions, index);
                }
            }, 260);
        }
    }
};
