<template>
    <paywall-modal :illustration="illustrations[modalData.type]" :title="titles[modalData.type]" :tracking-event="trackingEvents[modalData.type]"></paywall-modal>
</template>

<script>
import illustrationAdvancedFilters from '../../../resources/static/images/illustration_advanced_filters_paywall.png';
import illustrationBusinessHours from '../../../resources/static/images/illustration_business_hours_paywall.png';
import illustrationExport from '../../../resources/static/images/illustration_export.svg';
import PaywallModal from '../paywall/paywall_modal.vue';
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'PaywallHub',
    components: {
        PaywallModal
    },
    mixins: [ModalMixin],
    data() {
        return {
            illustrations: {
                advancedFilters: illustrationAdvancedFilters,
                businessHours: illustrationBusinessHours,
                exportReport: illustrationExport
            },
            titles: {
                advancedFilters: 'Would you like to use Advanced filters?',
                businessHours: 'Would you like to use Business Hours?',
                exportReport: 'Would you like to export your data?'
            },
            trackingEvents: {
                advancedFilters: 'paywallAdvancedFilters',
                businessHours: 'paywallBusinessHours',
                exportReport: 'paywallExportReport'
            }
        };
    }
};
</script>
