<template>
    <base-filter id="user-filter" icon="em-icon user" header="MAILBOX">
        <template #description>
            <p>
                Select the team members you want to compare email stats for.
            </p>
        </template>
        <template #filter>
            <div class="column is-three-quarters mx-auto">
                <div class="field">
                    <div class="control">
                        <input v-model="searchTerm" type="text" class="input is-small" placeholder="Search mailboxes" />
                    </div>
                </div>
                <div class="email-list-wrapper">
                    <div class="email-list">
                        <div v-for="(user, index) of filteredUsersList" :key="index" class="field">
                            <div class="control custom-checkbox">
                                <input :id="user.googleUserId" v-model="selectedUsers" class="is-checkradio" type="checkbox" :value="user" />
                                <label class="is-marginless" :for="user.googleUserId">{{ user.email }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <a class="underline is-size-8 mr-4 has-text-primary" @click="selectAllUsers()">Select all</a>
                    <a class="underline is-size-8 has-text-grey" @click="selectedUsers = []">Clear all</a>
                </div>
                <p v-if="selectedUsers.length === 0" class="help is-danger">Please, select at least one user</p>
            </div>
        </template>
    </base-filter>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import UsersFilter from '../../models/filters/users_filter';
import PAGE_NAMES from '../../router/pages';
import utils from '../../utils/utils';
import BaseFilter from './base_filter.vue';

export default {
    name: 'UserFilter',
    components: { BaseFilter },
    props: {
        filter: UsersFilter,
        usersList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedUsers: [],
            searchTerm: '',
            loaded: false
        };
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('team', ['firstTeam']),
        filteredUsersList() {
            return this.usersList.filter(u => {
                return u.email.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) !== -1;
            });
        }
    },
    watch: {
        selectedUsers(newVal) {
            const usersFilter = new UsersFilter(newVal.map(u => u.googleUserId));
            if (this.loaded) {
                this.$emit('filterUpdate', usersFilter);
            } else {
                this.loaded = true;
            }
        },
        filter: {
            immediate: true,
            handler(newVal) {
                // Slightly gnapa
                if (!newVal) {
                    this.setDefaultSelectedUsers();
                }
                if (
                    newVal &&
                    !utils.equalArrays(
                        newVal.value,
                        this.selectedUsers.map(u => u.googleUserId)
                    )
                ) {
                    const selected = newVal.value
                        .map(gid => {
                            const user = this.filteredUsersList.find(currentValue => {
                                return currentValue.googleUserId === gid;
                            });
                            return user;
                        })
                        .filter(Boolean);
                    this.selectedUsers = selected;
                }
            }
        }
    },
    beforeMount() {
        this.setDefaultSelectedUsers();
    },
    methods: {
        removeUser(user) {
            const index = this.selectedUsers.indexOf(user);
            this.selectedUsers.splice(index, 1);
        },
        setDefaultSelectedUsers() {
            const hasFilter = !this.filter || (this.filter && this.filter.value.length === 0);
            const hasTeamMembers = this.firstTeam && this.firstTeam.members.length > 0;

            if (hasFilter && hasTeamMembers && this.$route.name === PAGE_NAMES.team) {
                this.selectAllUsers();
                this.loaded = true;
            } else {
                this.selectedUsers = this.usersList.filter(u => u.googleUserId === this.user.googleUserId);
            }
        },
        selectAllUsers() {
            this.selectedUsers = this.usersList.slice(0);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.buttons {
    width: 150px;
}

.email-list-wrapper {
    height: 150px;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 0) 30px);
        padding: 0.75rem;
    }

    .email-list {
        overflow-y: scroll;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .field {
            margin-bottom: 0.75rem;

            &:last-child {
                margin-bottom: 1rem;
            }
        }
    }

    .custom-checkbox {
        & > label {
            color: $grey;

            &::before {
                border-radius: 2px;
                border: unset;
                background-color: #dbdbdb;
            }
        }

        .is-checkradio {
            &:checked + label {
                color: $primary;

                &::before {
                    background-color: $primary;
                }

                &::after {
                    width: 0.6rem;
                    height: 1rem;
                    top: 0.105rem;
                    left: 0.46rem;
                    border-color: white;
                }
            }
        }
    }
}
</style>
