/* eslint-disable no-console */

const DEBUG_LOG = process.env.LOG;

function generateError(error) {
    if (typeof error === 'string') {
        return new Error(error);
    } else {
        return error;
    }
}

export default class Log {
    static info(...args) {
        if (DEBUG_LOG) console.info(...args);
    }

    static warn(...args) {
        if (DEBUG_LOG) console.warn(...args);
    }

    static error(msg, extra) {
        if (DEBUG_LOG) console.error(msg, extra);

        if (process.env.BUGSNAG_KEY) {
            window.bugsnagClient.notify(generateError(msg), {
                metaData: extra
            });
        }
    }
}

/* eslint-enable no-console */
