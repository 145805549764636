<template>
    <div id="page-loader" class="fixed is-flex has-background-grey-light">
        <div class="container is-flex">
            <div class="section mx-auto is-flex flex-col justify-center items-center">
                <img src="../../../resources/static/images/logo.svg" class="mb-16" width="350px" height="48px" alt="Email Meter" />
                <spinner></spinner>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from './spinner.vue';

export default {
    name: 'PageLoader',
    components: { Spinner }
};
</script>

<style lang="scss" scoped>
#page-loader {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
