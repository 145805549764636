<template>
    <div class="is-flex flex-col has-text-centered">
        <p class="has-text-weight-bold m-0" :class="{ 'has-text-grey-dark': textWhite }">Want to try Email Meter for free?</p>
        <router-link :to="{ name: PAGE_NAMES.referral }" class="underline has-text-weight-bold">
            <span @click="closeModal">Invite friends to Email Meter</span>
        </router-link>
    </div>
</template>

<script>
import PAGE_NAMES from '../../router/pages';

export default {
    name: 'PaywallReferralLink',
    props: {
        textWhite: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            PAGE_NAMES
        };
    },
    methods: {
        closeModal() {
            if (this.isModal) this.$bus.emit(this.$bus.events.closeModal);
        }
    }
};
</script>
