<template>
    <base-histogram type="Received" metric-type="receivedHistogram" :color="blueColors" :required-metrics="requiredMetrics"></base-histogram>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import BaseHistogram from './base_histogram.vue';

export default {
    name: 'ReceivedHistogram',
    components: {
        BaseHistogram
    },
    mixins: [ReportMetricMixin]
};
</script>
