import querystring from 'querystring';

import ApiBase from './api_base';

const requestsCache = new Map();

const ENDPOINT_BQIP = process.env.ENDPOINT_BQIP;

export default class BqiApi extends ApiBase {
    static doRequest(metric, defaultParams = {}, avoidCache = false) {
        const query = querystring.stringify(defaultParams);
        const url = `${metric.endpoint}?${query}`;

        // 1. Check if we already made that request
        if (!avoidCache) {
            const cachedRequest = requestsCache.get(url);
            if (cachedRequest) return cachedRequest;
        }

        // 2. Do the request
        const request = this.fetchData(url, ENDPOINT_BQIP).catch(error => {
            requestsCache.delete(url);
            return Promise.reject(error);
        });

        requestsCache.set(url, request);
        return request;
    }

    static clearCache() {
        requestsCache.clear();
    }
}
