<template>
    <base-filter id="advanced-filters" icon="em-icon filter" header="ADVANCED FILTERS">
        <template #description>
            <p>Include emails that match the following rules.</p>
            <p class="is-size-9 has-text-grey">
                Note: press
                <code>enter</code> to separate values.
            </p>
        </template>
        <template #filter>
            <advanced-filter-item
                v-for="(filter, index) in clonedFilters"
                :key="uniqueKeys[index]"
                :filter="filter"
                :is-valid="filterValidity[index]"
                @update="updateFilter(index, $event)"
                @remove="removeFilter(index)"
            ></advanced-filter-item>
            <div class="columns filter">
                <div class="column">
                    <div
                        class="notification is-primary-light is-flex items-center is-full p-2"
                        :class="{ 'cursor-not-allowed': !canAddFilter, 'cursor-pointer': canAddFilter }"
                        @click="addNewFilter()"
                    >
                        <a class="button is-small is-transparent has-text-primary mx-auto">
                            <i class="em-icon add mx-auto"></i>
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </base-filter>
</template>
<script>
import { mapState } from 'vuex';

import modalsEnum from '../../utils/modals_enum';
import AdvancedFilterItem from './advanced_filter_item.vue';
import BaseFilter from './base_filter.vue';

export default {
    name: 'AdvancedFilters',
    components: {
        BaseFilter,
        AdvancedFilterItem
    },
    props: {
        filters: {
            type: Array,
            default: () => [null]
        }
    },
    data() {
        return {
            clonedFilters: [],
            duplicatedFilters: []
        };
    },
    computed: {
        ...mapState('user', ['user']),
        uniqueKeys() {
            return this.clonedFilters.map(f => (f === null ? 'null' : f.getHash()));
        },
        filterValidity() {
            return this.clonedFilters.map((e, i) => {
                if (e === null) return true;
                if (this.duplicatedFilters[i]) return false;
                if (e.value.length === 0) return true;
                return e.isValid();
            });
        },
        canAddFilter() {
            if (this.clonedFilters.length === 0) return true;
            if (this.clonedFilters[this.clonedFilters.length - 1] === null) return false;
            return this.filterValidity.every(Boolean);
        }
    },
    beforeMount() {
        this.clonedFilters = this.filters.map(f => f.clone());
    },
    methods: {
        updateDuplicated(keys) {
            this.duplicatedFilters = keys.map(k => keys.filter(k2 => k2 === k).length > 1);
        },
        updateFilter(index, filter) {
            const allKeys = this.clonedFilters.map(f => (f === null ? 'null' : f.getHash()));
            const newKey = filter === null ? 'null' : filter.getHash();
            const duplicated = allKeys.includes(newKey);
            if (!duplicated) {
                this.$set(this.clonedFilters, index, filter);
                this.emitFilters();
            } else {
                const newDuplicatedKeys = this.uniqueKeys.slice(0);
                newDuplicatedKeys[index] = newKey;
                this.updateDuplicated(newDuplicatedKeys);
            }
        },
        removeFilter(index) {
            this.clonedFilters.splice(index, 1);
            if (this.duplicatedFilters[index]) {
                this.updateDuplicated(this.uniqueKeys);
            } else {
                this.duplicatedFilters.splice(index, 1);
            }
            this.emitFilters();
        },
        addNewFilter() {
            if (!this.user.permissions(this.$em.constants.USER_PERMISSIONS.canAdvancedFilters)) {
                this.$bus.emit(this.$bus.events.showModal, modalsEnum.paywall, { type: 'advancedFilters', isLarge: true });
            } else if (this.canAddFilter) {
                this.clonedFilters.push(null);
                this.updateDuplicated(this.uniqueKeys);
                this.emitFilters();
            }
        },
        emitFilters() {
            this.$emit('filterUpdate', this.clonedFilters);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.filter {
    margin: -0.25rem -0.25rem 0.25rem -0.25rem;

    .column {
        padding: 0.25rem;
    }
}

.filter button.add-filter {
    border-color: transparent;
    & > i {
        font-size: 1.5rem;
        line-height: 0;
    }
}

.notification {
    .button {
        height: 1.5em;
    }
}
</style>
