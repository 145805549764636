<template>
    <div class="is-flex flex-1 justify-center items-center time-display">
        <div class="has-text-centered mr-4">
            <span class="is-block is-size-3 has-text-weight-bold">{{ firstTime.value }}</span>
            <span class="is-block is-size-9 is-lowercase">{{ firstTime.text }}</span>
        </div>
        <div class="has-text-centered">
            <span class="is-block is-size-3 has-text-weight-bold">{{ secondTime.value }}</span>
            <span class="is-block is-size-9 is-lowercase">{{ secondTime.text }}</span>
        </div>
    </div>
</template>

<script>
import dateHelpers from '../../../utils/date_helpers';

export default {
    props: {
        seconds: {
            type: Number,
            required: true
        }
    },
    computed: {
        formattedTime() {
            return this._formatTime(this.seconds);
        },
        firstTime() {
            const time = this.formattedTime;
            if (time.days) return this._formatTimeData(time.days, time.days === 1 ? 'Day' : 'Days');
            else if (time.hours) return this._formatTimeData(time.hours, time.hours === 1 ? 'Hour' : 'Hours');
            else return this._formatTimeData(time.minutes, time.minutes === 1 ? 'Minute' : 'Minutes');
        },
        secondTime() {
            const time = this.formattedTime;
            if (time.days) return this._formatTimeData(dateHelpers.formatTime(time.hours), time.hours === 1 ? 'Hour' : 'Hours');
            else if (time.hours) return this._formatTimeData(dateHelpers.formatTime(time.minutes), time.minutes === 1 ? 'Minute' : 'Minutes');
            else return this._formatTimeData(dateHelpers.formatTime(time.seconds), time.seconds === 1 ? 'Second' : 'Seconds');
        }
    },
    methods: {
        _formatTime(seconds) {
            return dateHelpers.parseTime(seconds);
        },
        _formatTimeData(time, text) {
            return {
                value: time,
                text: text
            };
        }
    }
};
</script>

<style scoped>
.time-display {
    line-height: 1.25;
}
</style>
