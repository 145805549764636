<template>
    <form class="mb-4">
        <div class="field">
            <label class="label">Name on card</label>
            <div class="control">
                <input v-model="cardName" class="input" type="text" placeholder="Name" />
            </div>
        </div>
        <div class="field">
            <label for="card-element" class="label">Card details</label>
            <div id="card-element" ref="card-element" class="control">
                <!-- A Stripe Element will be inserted here. -->
            </div>

            <!-- Used to display Element errors. -->
            <p class="help is-danger">{{ cardErrorMessage }}</p>
        </div>
    </form>
</template>

<script>
export default {
    name: 'CardForm',
    props: {
        savePayment: {
            type: Boolean
        }
    },
    data() {
        return {
            stripe: Stripe(process.env.STRIPE_KEY), // eslint-disable-line
            cardErrorMessage: null,
            cardComplete: false,
            cardName: null,
            card: null
        };
    },
    watch: {
        cardName: {
            immediate: true,
            handler() {
                this.sendToParent();
            }
        },
        savePayment: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.paymentMethodChange()
                        .then(token => {
                            this.$emit('modified-payment', token);
                        })
                        .catch(err => {
                            this.cardErrorMessage = err;
                            this.$emit('payment-data-ok', false);
                        });
                }
            }
        }
    },
    mounted() {
        const elements = this.stripe.elements({
            locale: 'en'
        });

        // Custom styling can be passed to options when creating an Element.
        const style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: '#363636',
                '::placeholder': {
                    color: '#7a7a7a'
                }
            }
        };

        // Create an instance of the card Element.
        this.card = elements.create('card', { style });

        // Add an instance of the card Element into the `card-element` <div>.
        this.card.mount(this.$refs['card-element']);

        this.card.on('change', data => {
            this.cardErrorMessage = '';
            this.cardComplete = data.complete;
            if (data.error) {
                this.cardErrorMessage = data.error.message;
            }
            this.sendToParent();
        });
    },
    methods: {
        sendToParent() {
            if (Boolean(this.cardName) && this.cardErrorMessage === '' && this.cardComplete) {
                this.$emit('payment-data-ok', true);
            } else {
                this.$emit('payment-data-ok', false);
            }
        },
        paymentMethodChange() {
            return new Promise((resolve, reject) => {
                this.stripe.createToken(this.card, { name: this.cardName }).then(res => {
                    if (res.error) {
                        reject(res.error.message);
                    } else {
                        resolve(res.token);
                    }
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.StripeElement {
    background-color: $input-background-color;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0.825rem calc(0.625em - 1px);
}
</style>
