<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal">Last step — confirm your new members</h2>
        <p>
            You’re about to add {{ modalData.emails.length }} {{ 'member' | pluralize(modalData.emails.length) }} to your Team for a total of
            <span class="has-text-primary has-text-weight-bold">{{ pricingString(modalData.emails.length) }}</span
            >.
        </p>
        <div v-for="(email, index) in modalData.emails" :key="index" class="notification is-primary-lighter p-2 mb-2">{{ email }}</div>
        <p class="mt-4 is-italic has-text-grey-dark">
            The amount will be added to your {{ customer.subscription.interval }}ly subscription. The first payment will be worked out on a pro rata basis from the date you send each invitation.
        </p>
        <div class="is-flex modal-action-buttons">
            <button class="button is-primary is-outlined mr-4" @click="closeModal()">Cancel</button>
            <button class="button is-primary" :class="{ 'is-loading': requestLoading }" :disabled="requestLoading" @click="sendInvitations">Confirm</button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ModalMixin from '../mixins/modal_mixin';

export default {
    name: 'ConfirmTeamInvitations',
    mixins: [ModalMixin],
    data() {
        return {
            requestLoading: false
        };
    },
    computed: {
        ...mapState('billing', ['customer']),
        ...mapGetters('billing', ['pricingString'])
    },
    methods: {
        sendInvitations() {
            const team = this.$store.getters['team/firstTeam'];
            this.requestLoading = true;
            this.$store
                .dispatch('team/createInvitations', {
                    teamId: team.id,
                    emails: this.modalData.emails
                })
                .then(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-success',
                        text: 'Once they accept your invitation, we’ll start putting together at least 2 years’ worth of email data for each member. Go to “Team Management” to track our progress.'
                    });
                    this.$router.push({
                        path: '/settings/team-management'
                    });
                })
                .catch(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
        }
    }
};
</script>
