<template>
    <section id="generic-paywall" class="section">
        <paywall-screen title="Would you like to become a Premium user?" :illustration="illustrationPaywall"></paywall-screen>
    </section>
</template>

<script>
import illustrationGenericPaywall from '../../../resources/static/images/illustration_custom_report_paywall.svg';
import PaywallScreen from '../../components/paywall/paywall_screen.vue';

export default {
    name: 'GenericPaywall',
    components: {
        PaywallScreen
    },
    data() {
        return {
            illustrationPaywall: illustrationGenericPaywall
        };
    },
    mounted() {
        window.dataLayer.push({ event: 'paywallGeneric' });
    }
};
</script>
