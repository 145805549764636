<template>
    <section>
        <div class="columns is-flex is-multiline paywall-cta">
            <div class="column">
                <div class="is-flex flex-col" :class="{ 'pl-12': !isMobile }">
                    <paywall-referral-link v-if="isMobile" :is-modal="true" :text-white="true" class="mb-2"></paywall-referral-link>
                    <slot :illustration="illustration">
                        <figure class="image px-5 mx-auto paywall-illustration">
                            <img :src="illustration" />
                        </figure>
                    </slot>
                    <paywall-referral-link v-if="!isMobile" :is-modal="true" :text-white="true" class="pl-5 mt-2"></paywall-referral-link>
                </div>
            </div>
            <div class="column">
                <div class="margin-body">
                    <h3 class="title is-4 has-text-white">{{ title }}</h3>
                    <p class="sub-title has-text-white">
                        <slot name="subtitle"></slot>
                    </p>
                    <div class="features is-flex flex-col mb-2">
                        <div v-for="(feature, index) in features" :key="index" class="flex-1 is-flex mb-2">
                            <span class="icon mr-2">
                                <i class="em-icon tick-list has-text-white"></i>
                            </span>
                            <p class="has-text-weight-bold has-text-white">{{ feature }}</p>
                        </div>
                    </div>
                    <a class="button is-primary is-fullwidth is-medium mb-6 paywall-cta-button" @click="goToPage()">
                        <span class="is-size-6">{{ goPremium.btnText }}</span>
                    </a>
                    <div v-if="displayPaymentInfo" class="payment-info">
                        <div class="secure-info">
                            <figure class="image secure-img">
                                <img src="../../../resources/static/images/icons/secure_payment.svg" />
                            </figure>
                            <span class="secure-text is-size-8">100% secure</span>
                        </div>
                        <div class="is-flex mx-auto">
                            <figure class="mr-4">
                                <img src="../../../resources/static/images/icons/visa_logo.svg" />
                            </figure>
                            <figure class="mr-4">
                                <img src="../../../resources/static/images/icons/mastercard_logo.svg" />
                            </figure>
                            <figure>
                                <img src="../../../resources/static/images/icons/americanexpress_logo.svg" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import PaywallReferralLink from './paywall_referral_link.vue';

export default {
    name: 'BasePaywall',
    components: {
        PaywallReferralLink
    },
    props: {
        title: {
            type: String,
            required: true
        },
        illustration: {
            type: String,
            required: true
        },
        features: {
            type: Array,
            required: true
        },
        goPremium: {
            type: Object,
            required: true
        },
        displayPaymentInfo: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    },
    methods: {
        goToPage() {
            this.$router.push({ path: this.goPremium.link });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.sub-title {
    margin: 0 auto 1rem;
    max-width: 600px;
    @include mobile {
        margin-left: 0;
    }
}

.margin-body {
    @include desktop {
        margin-left: 1rem;
        margin-right: 1.5rem;
    }
}

.paywall-cta {
    margin: 0 auto;

    @include mobile {
        flex-direction: column-reverse;
        .column:first-child {
            flex-basis: auto;
        }
    }

    .paywall-illustration {
        @include mobile {
            width: 100%;
            max-width: 274px;
            margin: auto;
        }
    }

    .payment-info {
        display: flex;
        justify-content: space-between;

        @include touch {
            flex-direction: column;
        }

        .secure-info {
            display: flex;
            margin: 0 auto;

            @include touch {
                margin-bottom: 1rem;
            }

            .secure-img {
                width: 28px;
                height: auto;
                margin-right: 0.5rem;
            }
            .secure-text {
                color: #6b7c93;
            }
        }
    }
}
</style>
