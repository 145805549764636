<template>
    <div id="referral-header" class="column is-full">
        <div class="box is-flex flex-col has-text-white has-background-enterprise">
            <component :is="componentToShow" v-bind="requiredData" @activate-premium="$emit('activate-premium')"></component>
        </div>
    </div>
</template>

<script>
import { INVITATION_STATUS, PURCHASES_STATUS } from '../../../models/referral';
import ReferralHasAchievedPremium from './screens/has_achieved_premium.vue';
import ReferralInProgress from './screens/in_progress.vue';
import ReferralHeaderPremiumTimer from './screens/premium_timer.vue';
import ReferralPremiumEnded from './screens/referral_premium_ended.vue';

export default {
    name: 'ReferralHeader',
    components: {
        ReferralHasAchievedPremium,
        ReferralHeaderPremiumTimer,
        ReferralInProgress,
        ReferralPremiumEnded
    },
    props: {
        isPremiumReferral: {
            type: Boolean,
            required: true
        },
        premiumReferralRemainingSeconds: {
            type: Function,
            required: true
        },
        invitations: {
            type: Object,
            required: true
        },
        purchases: {
            type: Object,
            required: true
        },
        premiumJustEnded: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        availableStars() {
            return this.invitations[INVITATION_STATUS.ACCEPTED].length + this.purchases[PURCHASES_STATUS.AVAILABLE].length * 5;
        },
        freeMonthsAchieved() {
            return Math.floor(this.availableStars / 5);
        },
        componentToShow() {
            const hasAchievedPremium = this.freeMonthsAchieved > 0;

            if (this.premiumJustEnded) {
                return ReferralPremiumEnded;
            } else if (this.isPremiumReferral) {
                return ReferralHeaderPremiumTimer;
            } else if (hasAchievedPremium) {
                return ReferralHasAchievedPremium;
            } else {
                return ReferralInProgress;
            }
        },
        requiredData() {
            return {
                invitations: this.invitations,
                purchases: this.purchases,
                monthsAchieved: this.freeMonthsAchieved,
                remainingTime: this.premiumReferralRemainingSeconds,
                starsCount: this.availableStars
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.has-background-enterprise {
    background-color: $enterprise-color;
}
</style>
