<template>
    <div class="columns is-multiline">
        <div class="column is-full">
            <h3 class="title is-3 is-size-4-mobile has-text-white has-text-centered">You are Premium!</h3>
        </div>
        <div class="column is-full is-flex justify-center">
            <div v-for="section in remainingTimeFormatted" :key="section.title" class="flex-1 is-flex flex-col justify-center items-center remaining-section">
                <span class="is-size-2 has-text-weight-bold remaining-number">{{ section.value }}</span>
                <span class="is-size-8">{{ section.title }}</span>
            </div>
        </div>
        <div class="column is-full is-flex justify-center">
            <features class="is-flex sm:flex-col" :features="features"></features>
        </div>
        <div class="column is-full is-flex justify-center">
            <referral-header-footer-numbers v-bind="$attrs"></referral-header-footer-numbers>
        </div>
        <div class="column is-full">
            <p class="has-text-centered">
                Want to keep Premium once your free month is over?
                <router-link to="/get-premium/select-product" class="underline has-text-weight-bold">Get Premium</router-link>
            </p>
        </div>
    </div>
</template>

<script>
import Features from '../../../base/features.vue';
import ReferralHeaderFooterNumbers from '../footer_numbers.vue';

export default {
    name: 'ReferralHeaderPremiumTimer',
    components: {
        Features,
        ReferralHeaderFooterNumbers
    },
    props: {
        remainingTime: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            ownTimer: 0,
            features: ['Business hours', 'Download to CSV', 'Custom reports', 'Advanced filters']
        };
    },
    computed: {
        remainingTimeFormatted() {
            const secondsInADay = 3600 * 24;
            const days = Math.floor(this.ownTimer / secondsInADay);
            let remainingTime = this.ownTimer % secondsInADay;
            const hours = Math.floor(remainingTime / 3600);
            remainingTime = remainingTime % 3600;
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;

            return [
                { title: 'days', value: days },
                { title: 'hours', value: hours },
                { title: 'minutes', value: minutes },
                { title: 'seconds', value: seconds }
            ];
        }
    },
    beforeMount() {
        this.ownTimer = this.remainingTime();
        const timerLoop = setInterval(() => {
            if (this.ownTimer <= 1) {
                this.$store.commit('referral/resetTimer');
                this.$store.commit('referral/setPremiumJustEnded', true);
                clearInterval(timerLoop);
            }
            --this.ownTimer;
        }, 1000);
    },
    beforeDestroy() {
        if (this.ownTimer !== 0) this.$store.commit('referral/resetTimer');
    }
};
</script>

<style lang="scss" scoped>
.remaining-section {
    min-height: 5rem;
    max-width: 100px;

    &:not(:last-child) {
        border-right: solid 1px #979797;
    }

    .remaining-number {
        color: #ffa600;
        line-height: 65%;
    }
}
</style>
