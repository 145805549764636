<template>
    <div class="notification is-flex-mobile flex-col bh-item" :class="{ 'is-primary-light': !error, 'is-danger-light': error }">
        <div>
            <div class="columns">
                <div v-if="$store.getters.isMobile" class="column p-2">
                    Work Days
                </div>
                <div class="column p-2">
                    <button
                        v-for="(n, index) in 7"
                        :key="index"
                        class="button is-rounded circle-button is-size-8"
                        :class="{
                            'is-primary': !error && workingDays[index],
                            'is-white has-text-primary': !workingDays[index],
                            'is-danger': error && workingDays[index]
                        }"
                        @click="enableDay(index)"
                    >
                        {{ getWeekDay(index).charAt(0) }}
                    </button>
                </div>
                <div v-if="$store.getters.isMobile" class="column p-2">
                    Work Hours
                </div>
                <div class="column is-flex items-center p-2">
                    <div class="select is-small" :class="{ 'is-danger': error }">
                        <select v-model="workingHours.fromHour" class="has-background-white" @change="emitData">
                            <option hidden :value="null">From</option>
                            <option v-for="(n, index) in 24" :key="index" :value="index" :disabled="workingHours.toHour !== null && index > workingHours.toHour">
                                {{ getFormatedHour(index) }}
                            </option>
                        </select>
                    </div>
                    <span class="has-text-primary mx-3">-</span>
                    <div class="select is-small" :class="{ 'is-danger': error }">
                        <select v-model="workingHours.toHour" class="has-background-white" @change="emitData">
                            <option hidden :value="null">To</option>
                            <option v-for="(n, index) in 24" :key="index" :value="index + 1" :disabled="workingHours.fromHour !== null && index < workingHours.fromHour">
                                {{ getFormatedHour(index + 1) }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <a class="button is-transparent has-text-primary remove-btn" @click="$emit('delete-item')">
            <span class="icon is-small">
                <i class="em-icon cancel is-size-9"></i>
            </span>
        </a>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'BusinessHoursItem',
    props: {
        workingData: {
            type: Object,
            required: true
        },
        error: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            workingDays: null,
            workingHours: null
        };
    },
    beforeMount() {
        this.workingDays = this.workingData.workingDays.slice(0);
        this.workingHours = Object.assign({}, this.workingData.workingHours);
    },
    methods: {
        getFormatedHour(h) {
            return `${h}:00`.padStart(5, '0');
        },
        getWeekDay(dIndex) {
            return dayjs()
                .day(dIndex)
                .format('ddd');
        },
        enableDay(index) {
            this.$set(this.workingDays, index, !this.workingDays[index]);
            this.emitData();
        },
        emitData() {
            if (this.workingHours.fromHour === this.workingHours.toHour) {
                this.workingHours.toHour = null;
            }
            this.$emit('business-hours-changed', {
                workingDays: this.workingDays,
                workingHours: this.workingHours
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.circle-button {
    width: 32px;
    height: 32px;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.notification {
    padding: 0.75rem;
}

.remove-btn {
    position: absolute;

    top: 0.4rem;
    right: 0.4rem;
}

@include mobile {
    .columns {
        .column:nth-child(1),
        .column:nth-child(2),
        .column:nth-child(3) {
            text-align: center;
        }

        .column:nth-child(4),
        .column:nth-child(5) {
            justify-content: center;
        }
    }
}
</style>
