/* eslint-disable max-classes-per-file */

const STRIPE_PLANS = {
    monthlyJune2019: {
        id: 'monthlyJune2019',
        name: 'Monthly June 2019',
        adj: 'monthly',
        noun: 'month'
    },
    yearlyJune2019: {
        id: 'yearlyJune2019',
        name: 'Yearly June 2019',
        adj: 'yearly',
        noun: 'year'
    }
};

const STRIPE_PRODUCTS = {
    basic: 'basic',
    premium: 'premium'
};

const STRIPE_PRICING = {
    monthlyJune2019: 19,
    yearlyJune2019: 15
};

class BillingPlan {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
    }
}

class BillingInvoice {
    constructor(data) {
        this.date = data.date;
        this.invoiceNumber = data.invoiceNumber;
        this.total = data.total;
        this.description = data.description;
        this.pdf = data.pdf;
    }
}

class BillingSubscription {
    constructor(data) {
        this.id = data.id;
        this.quantity = data.quantity;
        this.planName = data.planName;
        this.amount = data.amount;
        this.interval = data.interval;
        this.cancelAtPeriodEnd = data.cancelAtPeriodEnd;
        this.currentPeriodEnd = data.currentPeriodEnd;
    }
}

class BillingShipping {
    constructor(data) {
        this.name = data.name;
        this.line1 = data.line1;
        this.city = data.city;
        this.state = data.state;
        this.postalCode = data.postalCode;
        this.country = data.country;
    }
}

class BillingSource {
    constructor(data) {
        this.creditCardLast4 = data.creditCardLast4;
        this.creditCardBrand = data.creditCardBrand;
        this.expMonth = data.expMonth;
        this.expYear = data.expYear;
    }
}

class BillingCustomer {
    constructor(email, billingAddress, billingSource, billingSubscription) {
        this.email = email;
        this.address = billingAddress;
        this.source = billingSource;
        this.subscription = billingSubscription;
    }
}

/* eslint-disable max-params */
class BillingCoupon {
    constructor(id, name, percentOff, duration, durationInMonths) {
        this.id = id;
        this.name = name;
        this.percentOff = percentOff;
        this.duration = duration;
        this.durationInMonths = durationInMonths;
    }
}
/* eslint-enable max-params */

export { STRIPE_PLANS, STRIPE_PRODUCTS, STRIPE_PRICING, BillingPlan, BillingInvoice, BillingSubscription, BillingShipping, BillingSource, BillingCustomer, BillingCoupon };

/* eslint-enable max-classes-per-file */
