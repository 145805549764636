<template>
    <base-histogram type="Sent" metric-type="sentHistogram" :color="greenColors" :required-metrics="requiredMetrics"></base-histogram>
</template>

<script>
import ReportMetricMixin from '../mixins/report_metric_mixin';
import BaseHistogram from './base_histogram.vue';

export default {
    name: 'SentHistogram',
    components: {
        BaseHistogram
    },
    mixins: [ReportMetricMixin]
};
</script>
