<template>
    <div class="column mx-auto">
        <div id="stripe-box" class="box">
            <form class="form mb-6" @submit.prevent>
                <div class="field">
                    <label class="label has-text-black" for="name">Name on card</label>
                    <div class="control has-icons-left">
                        <input v-model="fullName" type="text" class="input is-medium" placeholder="Enter your name" />
                        <span class="icon is-left">
                            <i class="em-icon user"></i>
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label has-text-black" for="name">Card details</label>
                    <div class="control">
                        <div id="card-number" ref="card-number" class="stripe-field input is-medium" :class="{ 'has-generic-error': hasGenericError }"></div>
                    </div>
                    <p v-if="paymentData.cardNumber.error" class="help is-danger">{{ paymentData.cardNumber.error }}</p>
                </div>
                <div class="field is-grouped">
                    <div class="control">
                        <div id="card-expiry" ref="card-expiry" class="stripe-field input is-medium" :class="{ 'has-generic-error': hasGenericError }"></div>
                    </div>
                    <div class="control">
                        <div id="card-cvc" ref="card-cvc" class="stripe-field input is-medium" :class="{ 'has-generic-error': hasGenericError }"></div>
                    </div>
                </div>
                <p v-if="paymentData.cardExpiry.error" class="help is-danger">{{ paymentData.cardExpiry.error }}</p>
                <p v-if="paymentData.cardCvc.error" class="help is-danger">{{ paymentData.cardCvc.error }}</p>
                <div class="field">
                    <div class="control">
                        <button class="button is-success is-medium is-fullwidth" :class="{ 'is-loading': isLoading }" :disabled="isSubmitButtonDisabled || isLoading" @click="submitPayment()">
                            Pay now
                        </button>
                    </div>
                </div>
            </form>
            <div class="secure-info">
                <img src="../../../resources/static/images/icons/secure_payment.svg" />
                <span class="is-size-8">100% secure</span>
                <img src="../../../resources/static/images/icons/visa_logo.svg" />
                <img src="../../../resources/static/images/icons/mastercard_logo.svg" />
                <img src="../../../resources/static/images/icons/americanexpress_logo.svg" />
            </div>
            <div class="mt-4 ml-4 mr-4 is-flex justify-center items-center">
                <img src="../../../resources/static/images/powered_by_stripe.svg" />
            </div>
        </div>
    </div>
</template>

<script>
import PAGE_NAMES from '../../router/pages';
import Log from '../../utils/log';

const RESPONSE_3DSECURE_MESSAGE =
    'Payment for this subscription requires additional user action before it can be completed successfully. ' +
    'Please refer to the use of the `enable_incomplete_payments` parameter here: https://stripe.com/docs/billing/lifecycle#incomplete-opt-in';

export default {
    name: 'StripeCard',
    props: {
        plan: {
            type: String,
            required: true
        },
        emails: {
            type: Array,
            required: true
        },
        coupon: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            stripe: Stripe(process.env.STRIPE_KEY), // eslint-disable-line
            fullName: '',
            isLoading: false,
            hasGenericError: false,
            paymentData: {
                cardNumber: {
                    name: 'card-number'
                },
                cardExpiry: {
                    name: 'card-expiry'
                },
                cardCvc: {
                    name: 'card-cvc'
                }
            }
        };
    },
    computed: {
        isSubmitButtonDisabled() {
            const isPaymentDataComplete = Object.values(this.paymentData).every(({ complete }) => complete);
            return this.fullName === '' || !isPaymentDataComplete || this.hasGenericError;
        }
    },
    mounted() {
        const elements = this.stripe.elements({
            locale: 'en'
        });

        const style = {
            base: {
                color: '#666',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: 'rgb(102, 102, 102, 0.3)'
                }
            }
        };

        const classes = {
            base: 'input is-medium',
            focus: 'is-focused',
            complete: 'is-success',
            invalid: 'is-danger'
        };

        for (const field in this.paymentData) {
            if (Object.prototype.hasOwnProperty.call(this.paymentData, field)) {
                this.paymentData[field].element = elements.create(field, {
                    style,
                    classes
                });
                this.paymentData[field].element.mount(this.$refs[this.paymentData[field].name]);
                this.paymentData[field].element.on('change', data => {
                    this.hasGenericError = false;
                    if (data.error) {
                        this.$set(this.paymentData[field], 'error', data.error.message);
                    } else {
                        this.$set(this.paymentData[field], 'error', '');
                    }
                    this.$set(this.paymentData[field], 'complete', data.complete);
                });
            }
        }
    },
    methods: {
        async submitPayment() {
            this.isLoading = true;
            const token = await this.getStripeToken();
            if (!token) return;
            const stripePlan = this.$store.getters['billing/planByName'](this.plan);
            if (!stripePlan) Log.error(new Error('Stripe plan not found!'));

            const checkoutData = {
                'plan_id': stripePlan.id,
                token: token.id,
                emails: this.emails
            };

            if (this.coupon.id) {
                checkoutData.coupon_id = this.coupon.id; // eslint-disable-line camelcase
            }

            this.$store
                .dispatch('billing/checkout', checkoutData)
                .then(() => {
                    this.$store.dispatch('batch/fetchBatches');
                    this.isLoading = false;
                    this.$emit('error', '');
                    window.dataLayer.push({
                        event: 'checkoutComplete',
                        eventMetadata: { emailsAmount: this.emails.length }
                    });
                    if (this.emails.length > 0) {
                        this.$router.push({
                            path: '/settings/team-management'
                        });
                    } else {
                        this.$router.push({
                            name: PAGE_NAMES.account
                        });
                    }
                })
                .catch(({ resJSON, debug }) => {
                    if (Array.isArray(resJSON) && resJSON.pop() === RESPONSE_3DSECURE_MESSAGE) {
                        this.$emit('error', '3D Secure required but not yet supported.');
                        Log.error(new Error('3D Secure required card used'), { ...debug });
                    } else {
                        this.$emit('error', resJSON.message);
                        Log.error(new Error('Unable to finish checkout'), { ...debug });
                    }
                    this.isLoading = false;
                });
        },
        async getStripeToken() {
            const { token, error } = await this.stripe.createToken(this.paymentData.cardNumber.element, { name: this.fullName });
            if (error) {
                this.hasGenericError = true;
                this.$emit('error', error.message);
                this.isLoading = false;
                return;
            }
            this.$emit('error', '');
            return token;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

.box {
    @include desktop {
        padding: 3rem;
    }
}

.has-generic-error {
    border-color: $danger;
}

.stripe-field::v-deep {
    > div {
        width: 100%;
    }
}

#card-expiry {
    width: 85px;
}

#card-cvc {
    width: 55px;
}

.secure-info {
    display: flex;
    justify-content: center;
    color: #6b7c93;

    > :not(:last-child) {
        margin-right: 0.5rem;
    }
}
</style>
