const PERIOD_SPAN_KINDS = {
    monthly: 'monthly',
    weekly: 'weekly',
    custom: 'custom'
};

const PAGE_PATHS = {
    monthly: '/report/monthly',
    weekly: '/report/weekly',
    custom: '/report/custom',
    team: '/team'
};

const USER_PERMISSIONS = {
    canAccessOldData: 'can_access_old_data',
    canAdvancedFilters: 'can_advanced_filters',
    canBeDeleted: 'can_be_deleted',
    canBusinessHours: 'can_business_hours',
    canCustomReport: 'can_custom_report',
    canExportToCsv: 'can_export_to_csv',
    canLogin: 'can_login',
    canManageTeams: 'can_manage_teams',
    canManageUsers: 'can_manage_users',
    canMyAccountBilling: 'can_my_account_billing',
    canReferralProgram: 'can_referral_program',
    canSeeMyTeam: 'can_see_my_team',
    canTopInteractions: 'can_top_interactions',
    canViewAll: 'can_view_all',
    sync: 'sync'
};

export { PERIOD_SPAN_KINDS, PAGE_PATHS, USER_PERMISSIONS };
