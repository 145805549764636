<template>
    <div>
        <faq question="What can I do with Custom Reports?">
            <p>
                Custom Reports equip you with a higher level of flexibility when analyzing your email stats. In other words, they allow you to see your email stats for any time frame you choose in the
                last 2 years.
            </p>
            <p>Without Custom Reports, you get your email stats on a monthly or weekly basis - you can’t customize the time frame and you can’t see your email stats from the past.</p>
        </faq>
        <faq question="What’s the cancelation policy?">
            Our cancelation policy is simple - you can cancel anytime you want by going to “My Account”. If you do cancel your Premium account, you’ll be able to continue using all the Premium
            features right up until the end of your last billing cycle. The expiry date of your Premium account is displayed clearly in “My Account”.
        </faq>
        <faq question="Do you have any more questions?">
            Reach us at
            <a href="mailto:hello@emailmeter.com">hello@emailmeter.com</a>, or open up a chat by clicking on the bubble in the bottom right hand corner of the screen! We'd be very happy to help.
        </faq>
    </div>
</template>

<script>
import Faq from './faq.vue';

export default {
    name: 'DefaultFaqs',
    components: {
        Faq
    }
};
</script>
