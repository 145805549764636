import METRIC_TYPE from '../../models/metric_type';
import { STATUS } from './bqi_parser';

export default {
    [METRIC_TYPE.ALL.teamStats]: {
        getCsvHeaders() {
            return ['Full Name', 'Email', 'Sent', 'Received', 'Response Rate', 'Avg Response'];
        },
        getCsvData(metric) {
            return metric.status === STATUS.LOADED ? metric.value.map(m => [m.fullName, m.email, m.sent, m.received, m.responseRate, m.avgResponse]) : null;
        },
        get notLoaded() {
            return [new Array(6).fill('')];
        }
    }
};
