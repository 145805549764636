export const INVITATION_STATUS = {
    PENDING: 'P',
    ACCEPTED: 'A',
    CONSUMED: 'C'
};

export const PURCHASES_STATUS = {
    AVAILABLE: 'A',
    CONSUMED: 'C'
};

export const ReferralStatus = class {
    constructor(data) {
        this.invitations = data.invitations;
        this.purchases = data.purchases;
        this.premiumTimestampEnd = data.premiumTimestampEnd;
    }
};
