<template>
    <div>
        <h2 class="has-text-weight-bold is-size-3 is-size-5-touch has-text-centered">
            We help companies to create a data-driven culture with business intelligence
        </h2>
        <p class="mt-5 mb-6 is-size-7 is-size-8-touch has-text-centered">Analyze and monitor complex email workflows with <b> Email Meter Enterprise</b></p>
        <div class="columns">
            <div class="column is-flex items-center">
                <img src="../../../resources/static/images/modals/buildings.png" class="m-auto" />
            </div>
            <div class="column has-text-left">
                <features class="is-size-8 mb-5 is-size-9-touch flex-col" :features="features"></features>
            </div>
        </div>
        <a class="button is-primary is-large is-fullwidth has-text-centered" target="_blank" :href="link" @click="modalAnalitics"><span class="is-size-6">Request a demo</span></a>
    </div>
</template>

<script>
import Features from '../base/features';

export default {
    name: 'EnterpriseBait',
    components: {
        Features
    },
    data() {
        return {
            features: [
                'Get custom insights on Delegated Mailboxes',
                'Monitor your entire G Suite domain',
                'Define and measure your own KPIs',
                'Feed email data directly to your BI stack',
                'Data securely hosted and stored in Google Cloud'
            ],
            link: 'https://emailmeter.com//enterprise-demo?utm_source=product&utm_medium=secure&utm_campaign=delegatedModal'
        };
    },
    methods: {
        modalAnalitics() {
            window.dataLayer.push({
                event: 'delegatedModalClick'
            });
        }
    }
};
</script>

<style scoped>
h2 {
    line-height: normal;
}
</style>
