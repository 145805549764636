<template>
    <div class="filter-section section" :class="{ 'cursor-pointer': !isExpanded }" @click="isExpanded = isExpanded || !isExpanded">
        <div class="relative">
            <p class="has-text-weight-bold">
                <i class="mr-2" :class="icon"></i>
                {{ header }}
            </p>
            <div class="switcher" @click.stop="isExpanded = !isExpanded">
                <i v-if="!isExpanded" class="em-icon chevron-down"></i>
                <i v-else class="em-icon chevron-up"></i>
            </div>
        </div>
        <div v-if="isExpanded" class="columns">
            <div class="column">
                <slot name="description"></slot>
            </div>
            <div class="column is-three-quarters">
                <div v-if="$slots.buttons" class="columns">
                    <div class="column is-narrow">
                        <div class="buttons">
                            <slot name="buttons"></slot>
                        </div>
                    </div>
                    <div class="column">
                        <div class="columns is-centered">
                            <div class="column is-three-fifths">
                                <slot name="filter"></slot>
                            </div>
                        </div>
                    </div>
                </div>
                <slot v-else name="filter"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseFilter',
    props: {
        icon: {
            type: String,
            required: true
        },
        header: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isExpanded: true
        };
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.switcher {
    position: absolute;
    top: 0;
    right: 0;
    user-select: none;
    z-index: 10;
    cursor: pointer;

    i {
        color: $primary;
        font-size: 1.3rem;
    }
}
</style>
