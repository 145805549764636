import querystring from 'querystring';

import Log from '../utils/log';
import BqiApi from './bqi_api';
import { MetricData, PARSER, STATUS } from './parsers/bqi_parser';

export default class MetricsAPI {
    static fetchMetrics(metrics, qsFilters) {
        const filterParams = querystring.parse(qsFilters);

        return Object.keys(metrics).reduce((acc, metricName) => {
            const paramsWithConfig = Object.assign({}, filterParams, PARSER[metricName].config);
            const metricPromise = this._fetchMetric(metricName, paramsWithConfig);
            acc[metricName] = metricPromise;
            return acc;
        }, {});
    }

    static _fetchMetric(metricName, filterParams, retry = 0) {
        return BqiApi.doRequest(PARSER[metricName], filterParams)
            .then(res => {
                return PARSER[metricName].parseMetric(res);
            })
            .catch(({ debug }) => {
                Log.error(new Error('Failed to load metric'), {
                    ...debug,
                    metric: metricName
                });
                if (retry < 1) {
                    return this._fetchMetric(metricName, filterParams, retry + 1);
                } else {
                    return { [metricName]: new MetricData(STATUS.ERROR) };
                }
            });
    }
}
