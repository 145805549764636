<template>
    <div class="box bubble">
        <div class="paywall-content">
            <paywall-screen-header class="mb-16" :illustration="illustration" :features="titleFeatures" :title="title" :go-premium="goPremium">
                <template v-slot:subtitle>
                    Start enjoying the benefits of a Premium user now for just
                    <span class="has-text-primary has-text-weight-bold">${{ STRIPE_PRICING.yearlyJune2019 }}/month</span>
                    billed yearly. You can cancel anytime.
                </template>
            </paywall-screen-header>
            <section>
                <div class="columns">
                    <paywall-screen-feature
                        v-for="(feature, i) in footerFeatures"
                        :key="i"
                        class="column"
                        :illustration="feature.illustration"
                        :title="feature.title"
                        :body="feature.body"
                        :know-more-link="feature.knowMoreLink"
                    ></paywall-screen-feature>
                </div>
                <component :is="faqsComponent"></component>
            </section>
        </div>
    </div>
</template>

<script>
import { STRIPE_PRICING } from '../../models/billing';
import DefaultFaqs from './default_faqs.vue';
import { FOOTER_FEATURES, TITLE_FEATURES } from './paywall_defaults';
import PaywallScreenFeature from './paywall_screen_feature.vue';
import PaywallScreenHeader from './paywall_screen_header.vue';

export default {
    name: 'PaywallScreen',
    components: {
        PaywallScreenHeader,
        DefaultFaqs,
        PaywallScreenFeature
    },
    props: {
        illustration: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        titleFeatures: {
            type: Array,
            default: () => TITLE_FEATURES
        },
        footerFeatures: {
            type: Array,
            default: () => FOOTER_FEATURES
        },
        faqsComponent: {
            type: Object,
            default: () => DefaultFaqs
        },
        goPremium: {
            type: Object,
            default() {
                return {
                    link: '/get-premium/select-product',
                    btnText: 'Get Premium'
                };
            }
        }
    },
    data() {
        return {
            STRIPE_PRICING
        };
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

.paywall-content {
    padding: 2.5rem;
    @include mobile {
        padding: 0;
    }
}

.bubble {
    background-repeat: no-repeat;
    background-image: url('../../../resources/static/images/background_paywall_screen.svg');
    background-position: 102% -15%;
    background-size: 83%;

    @include mobile {
        background-image: url('../../../resources/static/images/background_paywall_screen_mobile.svg');
        background-size: 769px;
        background-position-y: -40rem;
    }
    @include tablet-only {
        background-size: calc(712px + 19vw);
        background-position: calc(379px - 20vw) top;
    }
    @include desktop-only {
        background-size: 970px;
        background-position: right -54px;
    }
    @include widescreen-only {
        background-size: 80%;
        background-position-y: -75px;
    }
}
</style>
