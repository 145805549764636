import ApiBase from './api_base';
import BillingParser from './parsers/billing_parser';

const BILLING_URLS = {
    checkout: '/api/billing/checkout/',
    customer: '/api/billing/customer/',
    invoices: '/api/billing/invoices/',
    subscriptions: '/api/billing/subscriptions/',
    plans: '/api/billing/plans/',
    coupons: '/api/billing/coupons/'
};

export default class BillingApi extends ApiBase {
    static checkout(billingData) {
        return this.postData(BILLING_URLS.checkout, billingData);
    }

    static getCustomer() {
        return this.fetchData(BILLING_URLS.customer).then(data => {
            return BillingParser.parseBillingCustomer(data);
        });
    }

    static updateCustomerInfo(customerData) {
        return this.postData(BILLING_URLS.customer, customerData, 'PUT').then(data => {
            return BillingParser.parseBillingCustomer(data);
        });
    }

    static getInvoices() {
        return this.fetchData(BILLING_URLS.invoices).then(data => {
            return BillingParser.parseBillingInvoices(data.data);
        });
    }

    static getSubscriptions() {
        return this.fetchData(BILLING_URLS.subscriptions);
    }

    static updateSubscription(subscriptionId, action) {
        return this.postData(`${BILLING_URLS.subscriptions}${subscriptionId}/${action}/`);
    }

    static getPlans() {
        return this.fetchData(BILLING_URLS.plans).then(data => {
            return BillingParser.parseBillingPlans(data);
        });
    }

    static getCoupon(couponId) {
        return this.fetchData(`${BILLING_URLS.coupons}${couponId}/`).then(data => {
            return BillingParser.parseCouponCode(data);
        });
    }
}
