<template>
    <div id="discount-code" class="column mx-auto">
        <div class="box has-background-green has-text-white">
            <transition name="fade">
                <img v-if="applied && !couponError" src="../../../resources/static/images/discount_codes_stars_bg.svg" />
            </transition>
            <div class="columns is-mobile">
                <div class="column p-2 is-flex items-center">Discount Code</div>
                <div class="column p-2 is-flex items-center justify-end">
                    <input v-model="internalDiscountCode" type="text" class="input has-text-centered" @input="applied = false" @keyup.enter="applyDiscount" />
                </div>
                <div class="column p-2 is-flex items-center justify-end is-narrow" style="width: 130px">
                    <button v-if="!applied || couponError" class="button is-white is-outlined is-rounded" @click="applyDiscount">Apply</button>
                    <button v-else class="button is-white is-outlined is-rounded is-flex items-center justify-center">
                        <span class="icon mr-2 has-background-white">
                            <i class="em-icon tick-list has-text-green"></i>
                        </span>
                        <span>Applied</span>
                    </button>
                </div>
            </div>
        </div>
        <p v-if="couponError" class="help is-danger has-text-centered">{{ couponError }}</p>
    </div>
</template>

<script>
import BillingApi from '../../api/billing_api';

export default {
    name: 'DiscountCode',
    props: {
        discountCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            internalDiscountCode: '',
            couponError: '',
            applied: false
        };
    },
    watch: {
        discountCode: {
            immediate: true,
            handler(val) {
                this.internalDiscountCode = val;
            }
        }
    },
    methods: {
        applyDiscount() {
            this.couponError = '';
            BillingApi.getCoupon(this.internalDiscountCode.toUpperCase())
                .then(coupon => {
                    this.$emit('applied-coupon', coupon);
                    this.applied = true;
                })
                .catch(() => {
                    this.$emit('applied-coupon', {});
                    this.couponError = 'Coupon not found.';
                    this.applied = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    position: relative;

    img {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
    }

    .input {
        background-color: rgba(227, 231, 238, 0.5);
        color: #fff;
        text-transform: uppercase;
    }

    .button > span {
        margin-top: -0.35rem;

        &.icon {
            height: 2.25rem;
            width: 2.25rem;
            border-radius: 50%;
            margin-left: -1.05rem !important;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
