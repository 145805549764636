<template>
    <nav id="main-menu" :class="{ 'is-fixed-bottom': isMobileOrTablet }">
        <div :class="{ tabs: isMobileOrTablet, menu: !isMobileOrTablet }">
            <ul :class="{ 'menu-list': !isMobileOrTablet }">
                <router-link v-if="!isMobileOrTablet" tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.monthly) }" :class="{ 'is-active': $route.name === 'report' }">
                    <a class="is-flex flex-col">
                        <i class="em-icon menu-monthly-report mb-2"></i>
                        Report
                    </a>
                </router-link>
                <template v-else>
                    <router-link tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.monthly) }" active-class="is-active">
                        <a class="is-flex flex-col">
                            <i class="em-icon menu-monthly-report mb-2"></i>
                            Monthly
                        </a>
                    </router-link>
                    <router-link tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.weekly) }" active-class="is-active">
                        <a class="is-flex flex-col">
                            <i class="em-icon menu-weekly-report mb-2"></i>
                            Weekly
                        </a>
                    </router-link>
                    <router-link tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.custom) }" active-class="is-active">
                        <a class="is-flex flex-col">
                            <i class="em-icon menu-custom-report mb-2"></i>
                            Custom Dates
                        </a>
                    </router-link>
                </template>
                <router-link v-if="canSeeMyTeam" tag="li" :to="{ 'path': pathToFilteredPath($em.constants.PAGE_PATHS.team) }" active-class="is-active">
                    <a class="is-flex flex-col">
                        <i class="em-icon menu-team mb-2"></i>
                        My Team
                    </a>
                </router-link>
                <li v-if="showDelegatedAcounts" @click="showBaitModal">
                    <a class="delegated-accounts is-flex flex-col has-text-centered">
                        <i class="em-icon menu-delegated-accounts mb-2"></i>
                        Delegated Inboxes
                    </a>
                </li>
            </ul>
            <ul v-if="!isMobileOrTablet" class="push-bottom" :class="{ 'menu-list': !isMobileOrTablet }">
                <router-link v-if="$waffle.switch_is_active('referral_program') && canReferralProgram" tag="li" to="/referral" active-class="is-active">
                    <a class="premium">
                        <i class="em-icon menu-gift mb-2"></i>
                        <span class="referral-tag">Get Premium for free</span>
                    </a>
                </router-link>
                <router-link v-else-if="!user.client.hasActiveSubscription && !user.isTeamMember" tag="li" to="/get-premium/paywall" active-class="is-active">
                    <a class="premium">
                        <i class="em-icon menu-premium mb-2"></i>
                        <span class="premium-tag">Go Premium</span>
                    </a>
                </router-link>
                <router-link v-if="canMyAccountBilling" tag="li" to="/account" active-class="is-active">
                    <a aria-label="My Account">
                        <i class="em-icon menu-user"></i>
                    </a>
                </router-link>
                <router-link tag="li" to="/settings" active-class="is-active">
                    <a aria-label="Settings">
                        <i class="em-icon menu-settings"></i>
                    </a>
                </router-link>
            </ul>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import modalsEnum from '../../utils/modals_enum';

export default {
    name: 'MainMenu',
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('filter', ['pathToFilteredPath']),
        ...mapGetters(['isMobileOrTablet']),
        canSeeMyTeam() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canSeeMyTeam);
        },
        canMyAccountBilling() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canMyAccountBilling);
        },
        canReferralProgram() {
            return this.user.permissions(this.$em.constants.USER_PERMISSIONS.canReferralProgram);
        },
        showDelegatedAcounts() {
            return !this.isMobileOrTablet && this.$waffle.switch_is_active('delegated_inboxes');
        }
    },
    methods: {
        showBaitModal() {
            window.dataLayer.push({
                event: 'delegatedModalOpen'
            });
            this.$bus.emit(this.$bus.events.showModal, modalsEnum.enterpriseBait, { isLarge: true });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

$menu-background-color: #212529;

#main-menu {
    background-color: $menu-background-color;
    bottom: 0;
    @include desktop {
        padding: 48px 0;
        width: 95px;
        position: fixed;
        min-height: calc(100vh - #{$navbar-height});
        display: flex;
    }

    @include touch {
        min-width: 100%;
        position: fixed;
        z-index: 15;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    width: 100%;

    .push-bottom {
        margin-top: auto;
    }

    ul {
        li {
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }

            &.is-active {
                a {
                    opacity: 1;
                    border-right: 2px solid $yellow;

                    &.premium {
                        i {
                            opacity: 1;
                        }
                    }
                }
            }
            a {
                border-radius: 0;
                color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: $size-8;
                opacity: 0.4;
                border-right: 2px solid transparent;
                padding: 0.5rem 0.75em;

                &.premium {
                    opacity: 1;

                    &:hover {
                        i {
                            transition: opacity 0.15s ease-in-out;
                            opacity: 1;
                        }
                    }

                    i {
                        opacity: 0.4;
                    }
                }

                &:hover {
                    background-color: transparent;
                    opacity: 1;
                    transition: opacity 0.15s ease-in-out;
                }

                i {
                    font-size: $size-3;
                }
            }
        }
    }
}

.tabs {
    ul {
        li {
            flex: 1;

            &.is-active {
                a {
                    border-bottom: unset;
                    border-top: 2px solid $yellow;
                }
            }

            a {
                padding: 0.75rem 1rem;
                border-top: 2px solid transparent;
                border-bottom: unset;

                i {
                    font-size: $size-5;
                    display: inline-flex;
                    margin-bottom: 0.25rem;
                }
            }
        }
    }
}

.referral-tag {
    background-color: $primary;
    opacity: 1;
    font-size: 0.625rem;
    padding: 0.25rem 0.2rem;
    border-radius: 10px;
    vertical-align: top;
    margin: 0 auto;
    text-align: center;
}

.premium-tag {
    background-color: $yellow;
    opacity: 1;
    font-size: 0.625rem;
    padding: 0 0.2rem;
    border-radius: 5px;
    color: $menu-background-color;
    vertical-align: top;
    margin: 0 auto;
}
</style>
