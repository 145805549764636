<template>
    <div id="team-grow" class="columns is-multiline">
        <div class="column is-full has-text-centered relative">
            <h2 class="title is-3">Let’s grow your Team!</h2>
            <a class="has-text-primary breadcum-back" @click="$router.go(-1)">&lt; Back to Team Management</a>
        </div>
        <div class="column is-6 mx-auto">
            <div class="box">
                <p class="mb-4">
                    Enter the email address of each Team member you’d like to add below. Add as many as you like.
                    <b
                        >You’ll be charged <span class="has-text-primary">{{ pricingString(1) }} per user</span></b
                    >.
                </p>
                <add-members-input @list-change="onListChange"></add-members-input>
                <button class="button is-medium is-primary is-fullwidth" :disabled="!isContinueEnabled" @click="showConfirmModal()">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import addMembersMixin from '../team/mixins/add_members_mixin';
import settingsHelper from './helpers/permissions';

export default {
    name: 'TeamGrow',
    mixins: [addMembersMixin],
    beforeRouteEnter(to, from, next) {
        next(vm => {
            settingsHelper.canNavigateToTeamManagement(vm, next);
        });
    },
    beforeRouteUpdate(to, from, next) {
        settingsHelper.canNavigateToTeamManagement(this, next);
        next();
    },
    computed: {
        ...mapGetters('billing', ['pricingString']),
        subscription() {
            return this.$store.state.billing.customer.subscription;
        }
    }
};
</script>

<style lang="scss" scoped>
.breadcum-back {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.75rem;
}
</style>
