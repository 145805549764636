<template>
    <div class="is-flex flex-1 flex-col is-relative" :class="{ 'padding-500px': !isMobileOrTablet && Boolean(productName) && STEP[$route.params.step] !== 4 }">
        <div v-if="errorMessage" id="checkout-error" class="section has-background-danger has-text-white">
            <h5 class="title has-text-white is-5">Oops! Something went wrong with the payment</h5>
            <p class="is-marginless has-text-white">
                We had an issue with your card and you weren't charged. (Reason: {{ errorMessage }}). You can try again with a different card, or contact us if the problem continues.
            </p>
        </div>
        <section v-if="STEP[$route.params.step] !== 4" id="checkout" class="section">
            <div class="columns flex-col">
                <div class="column mx-auto has-text-centered">
                    <h4 class="title is-4 is-size-5-mobile">Let's make you a Premium user!</h4>
                </div>
                <div class="column mx-auto">
                    <div v-if="!isMobileOrTablet" class="steps">
                        <router-link
                            :to="{ name: 'get-premium', params: { step: 'select-product' } }"
                            class="step-one"
                            :class="{ 'step-complete': STEP[$route.params.step] > 1, 'step-active': STEP[$route.params.step] === 1 }"
                        >
                            Select a product
                        </router-link>
                        <router-link :to="{ name: 'get-premium', params: { step: 'pay' } }" class="step-two" :class="{ 'step-active': STEP[$route.params.step] === 3 }">Pay</router-link>
                        <!-- This one is hacky since we don't have 3 steps in desktop devices -->
                    </div>
                    <div v-else class="steps">
                        <router-link
                            :to="{ name: 'get-premium', params: { step: 'select-product' } }"
                            class="step-one"
                            :class="{ 'step-complete': STEP[$route.params.step] > 1, 'step-active': STEP[$route.params.step] === 1 }"
                        >
                            Select a product
                        </router-link>
                        <router-link
                            :to="{ name: 'get-premium', params: { step: 'confirm' } }"
                            class="step-two"
                            :class="{ 'step-complete': STEP[$route.params.step] > 2, 'step-active': STEP[$route.params.step] === 2 }"
                        >
                            Confirm
                        </router-link>
                        <router-link :to="{ name: 'get-premium', params: { step: 'pay' } }" class="step-three" :class="{ 'step-active': STEP[$route.params.step] === 3 }">Pay</router-link>
                    </div>
                </div>
                <discount-code v-if="discountCode" :discount-code="discountCode" :error="couponError" @applied-coupon="applyDiscount"></discount-code>
                <keep-alive>
                    <select-product
                        v-if="STEP[$route.params.step] === 1"
                        :pre-selected-product="productName"
                        :selected-plan="currentPlan"
                        @select-product="onProductSelect"
                        @emails-updated="onEmailsUpdate"
                        @press-continue="onPressContinue"
                    ></select-product>
                    <div v-else-if="isMobileOrTablet && STEP[$route.params.step] === 2 && Boolean(productName)" class="column mx-auto">
                        <checkout-cart :product-name="productName" :emails="emails.length" :coupon="coupon" @billing-kind="onBillingPlan"></checkout-cart>
                    </div>
                    <stripe-card v-else-if="STEP[$route.params.step] === 3" :plan="currentPlan.name" :emails="emails" :coupon="coupon" @error="errorMessage = $event"></stripe-card>
                </keep-alive>
                <div class="column mx-auto">
                    <div class="is-flex justify-center has-text-grey-dark">
                        <p class="is-size-8 is-flex align-center mr-6">
                            <i class="em-icon telephone is-size-7 mr-2"></i>
                            <a href="tel:+19143481538" class="has-text-grey-dark underline">+1 (914) 348-1538</a>
                        </p>
                        <p class="is-size-8 is-flex align-center">
                            <i class="em-icon email is-size-7 mr-2"></i>
                            <a href="mailto:hello@emailmeter.com" class="has-text-grey-dark underline">hello@emailmeter.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <checkout-cart v-if="!isMobileOrTablet && Boolean(productName)" :product-name="productName" :emails="emails.length" :coupon="coupon" @billing-kind="onBillingPlan"></checkout-cart>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CheckoutCart from '../components/checkout/checkout_cart.vue';
import DiscountCode from '../components/checkout/discount_code.vue';
import SelectProduct from '../components/checkout/select_product.vue';
import StripeCard from '../components/checkout/stripe_card.vue';
import { STRIPE_PLANS } from '../models/billing';
import PAGE_NAMES from '../router/pages';

const STEP = {
    'select-product': 1,
    confirm: 2,
    pay: 3
};

/* eslint-disable complexity */
function handleUrlChange(to, from, next, vm) {
    vm.discountCode = to.query.discountCode;
    const user = vm.$store.state.user.user;
    if (user.client.hasActiveSubscription) {
        next({ name: PAGE_NAMES.account });
    } else if (user.isTeamMember) {
        next({ name: PAGE_NAMES.report });
    } else if ((STEP[to.params.step] > 1 && !vm.productName) || (STEP[to.params.step] > 1 && vm.productName === 'Team' && vm.emails.length === 0)) {
        next({
            name: to.name,
            params: {
                step: 'select-product'
            }
        });
    } else {
        next();
    }
}
/* eslint-enable complexity */

export default {
    name: 'Checkout',
    components: {
        CheckoutCart,
        DiscountCode,
        SelectProduct,
        StripeCard
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            handleUrlChange(to, from, next, vm);
        });
    },
    beforeRouteUpdate(to, from, next) {
        handleUrlChange(to, from, next, this);
    },
    data() {
        return {
            currentStep: 2,
            currentPlan: STRIPE_PLANS.yearlyJune2019,
            productName: null,
            emails: [],
            STEP: STEP,
            errorMessage: '',
            PAGE_NAMES,
            discountCode: '',
            couponError: '',
            coupon: {}
        };
    },
    computed: {
        ...mapGetters([['isMobileOrTablet']])
    },
    beforeMount() {
        if (this.$route.query.kind === 'team') this.productName = 'Team';
    },
    methods: {
        onProductSelect(product) {
            this.productName = product;
        },
        onEmailsUpdate(emails) {
            this.emails = emails.slice(0);
        },
        onPressContinue() {
            window.dataLayer.push({
                event: 'checkoutSelectProduct',
                eventMetadata: { tier: this.productName }
            });
            if (this.isMobileOrTablet) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        step: 'confirm'
                    }
                });
            } else {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        step: 'pay'
                    }
                });
            }
        },
        onBillingPlan(plan) {
            this.currentPlan = plan;
        },
        applyDiscount(coupon) {
            this.coupon = coupon;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';
@import '../styles/mixins';

#checkout {
    & > .columns {
        & > .column {
            max-width: 524px;
            width: 100%;
        }
    }
}

.padding-500px {
    padding-right: 500px;
}

%step-dot {
    color: $white;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.steps {
    font-size: $size-8;
    display: flex;
    justify-content: center;
    color: $grey;

    a {
        display: inline-flex;
        align-items: center;
        color: $grey;
    }

    > :not(:last-child) {
        margin-right: 2.5rem;
    }

    .step-active {
        color: $primary;

        &::before {
            background-color: $primary;
            @extend %step-dot;
        }
    }

    .step-complete {
        color: $text;

        &::before {
            content: '\f129';
            font-family: 'em-icons';
            background-color: $primary;
            @extend %step-dot;
        }
    }

    $step-labels: (
        'one': '1',
        'two': '2',
        'three': '3'
    );
    @each $step-name, $step-content in $step-labels {
        .step-#{$step-name} {
            &::before {
                @extend %step-dot;
            }
        }

        .step-#{$step-name}:not(.step-complete) {
            &::before {
                content: '#{$step-content}';
            }
        }

        .step-#{$step-name}:not(.step-active):not(.step-complete) {
            cursor: default;
            &::before {
                background-color: $grey;
            }
        }
    }
}
</style>
