<template>
    <base-filter id="date-filter" icon="em-icon date" header="DATE RANGE">
        <template #description>
            <p>
                Select any date range between <b>{{ dataSpan.timestampFrom.format('MMM D, YYYY') }}</b> and
                <b>{{
                    dataSpan.timestampTo
                        .clone()
                        .subtract(1, 'day')
                        .format('MMM D, YYYY')
                }}</b
                >.
            </p>
        </template>
        <template #buttons>
            <div class="buttons">
                <button class="button is-small" :class="{ 'is-primary': isNDaysRange(1), 'is-light': !isNDaysRange(1) }" @click="setPeriodLastDays(1)">Yesterday</button>
                <button class="button is-small" :class="{ 'is-primary': isNDaysRange(7), 'is-light': !isNDaysRange(7) }" @click="setPeriodLastDays(7)">Last 7 days</button>
                <button class="button is-small" :class="{ 'is-primary': isNDaysRange(30), 'is-light': !isNDaysRange(30) }" @click="setPeriodLastDays(30)">Last 30 days</button>
                <button class="button is-small" :class="{ 'is-primary': isNDaysRange(90), 'is-light': !isNDaysRange(90) }" @click="setPeriodLastDays(90)">Last 90 days</button>
                <button class="button is-small" :class="{ 'is-primary': isNDaysRange(365), 'is-light': !isNDaysRange(365) }" @click="setPeriodLastDays(365)">Last 365 days</button>
                <button class="button is-small" :class="{ 'is-primary': isLastYear, 'is-light': !isLastYear }" @click="setPeriodLastYear()">Last Year</button>
            </div>
        </template>
        <template #filter>
            <datepicker
                ref="calendar"
                maximum-view="day"
                :inline="true"
                :use-utc="true"
                wrapper-class="calendar"
                :open-date="openDate"
                :disabled-dates="{ customPredictor: disableDate }"
                :highlighted="currentSpan"
                @input="dateSelected"
            ></datepicker>
        </template>
    </base-filter>
</template>

<script>
import dayjs from 'dayjs';
import Datepicker from 'vuejs-datepicker';

import BaseFilter from './base_filter.vue';
import filtersMixin from './mixins/filters_mixin';

export default {
    name: 'DateFilter',
    components: {
        BaseFilter,
        Datepicker
    },
    mixins: [filtersMixin],
    data() {
        return {
            currentSpan: {
                from: null,
                to: null
            }
        };
    },
    computed: {
        isEndDateYesterday() {
            const yesterday = dayjs
                .utc()
                .subtract(1, 'day')
                .startOf('day');
            return dayjs(this.currentSpan.to)
                .utc()
                .isSame(yesterday, 'day');
        },
        isLastYear() {
            const yearStart = dayjs
                .utc()
                .subtract(1, 'year')
                .startOf('year');
            const yearEnd = yearStart.clone().endOf('year');
            return (
                dayjs(this.currentSpan.from)
                    .utc()
                    .isSame(yearStart, 'day') &&
                dayjs(this.currentSpan.to)
                    .utc()
                    .isSame(yearEnd, 'day')
            );
        },
        openDate() {
            return this.dataSpan.timestampTo
                .clone()
                .subtract(1, 'day')
                .toDate();
        }
    },
    mounted() {
        this.$nextTick(() => {
            const dateFilter = this.$store.getters['filter/currentPageFilters'].dateFilter;

            if (dateFilter && dateFilter.timestampFrom) {
                this.currentSpan = {
                    from: dayjs
                        .unix(dateFilter.timestampFrom)
                        .utc()
                        .toDate(),
                    to: dayjs
                        .unix(dateFilter.timestampTo)
                        .utc()
                        .toDate()
                };
            }
        });
    },
    methods: {
        disableDate(pickedDate) {
            return dayjs(pickedDate).isBefore(this.dataSpan.timestampFrom.clone().add(1, 'day')) || dayjs(pickedDate).isAfter(this.dataSpan.timestampTo.clone().subtract(1, 'day'));
        },
        dateSelected(d) {
            if (!this.currentSpan.from || this.currentSpan.from >= d || this.currentSpan.to >= d) {
                this.currentSpan.from = d;
                this.currentSpan.to = d;
            } else {
                this.currentSpan.to = d;
            }
            this.emitToParent(this.currentSpan);
        },
        setPeriodLastDays(days) {
            this.$refs.calendar.clearDate();

            this.currentSpan.from = dayjs
                .utc()
                .subtract(days, 'day')
                .startOf('day')
                .toDate();
            this.currentSpan.to = dayjs
                .utc()
                .subtract(1, 'day')
                .endOf('day')
                .toDate();
            this.emitToParent(this.currentSpan);
        },
        setPeriodLastYear() {
            this.$refs.calendar.clearDate();
            this.currentSpan.from = dayjs
                .utc()
                .subtract(1, 'year')
                .startOf('year')
                .toDate();
            this.currentSpan.to = dayjs
                .utc()
                .subtract(1, 'year')
                .endOf('year')
                .toDate();
            this.emitToParent(this.currentSpan);
        },
        isNDaysRange(numberOfDays) {
            const NDaysDurationAsSeconds = numberOfDays * 24 * 60 * 60 - 1;
            const secondsBetweenDates = dayjs
                .utc(this.currentSpan.to)
                .endOf('day')
                .diff(dayjs.utc(this.currentSpan.from).startOf('day'), 'seconds');
            return this.isEndDateYesterday && NDaysDurationAsSeconds === secondsBetweenDates;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

.buttons {
    @include desktop {
        width: 150px;

        .button {
            display: flex;
            width: 100%;

            &:not(:last-child):not(.is-fullwidth) {
                margin-right: 0;
            }
        }
    }
}

.calendar::v-deep {
    .vdp-datepicker__calendar {
        width: 100%;

        .cell {
            height: 30px;
            line-height: 30px;

            &.selected {
                color: #fff;
                background-color: rgba($primary, 1.2) !important;

                &:hover {
                    background-color: rgba($primary, 1.2) !important;
                }
            }
            &.highlighted {
                color: #fff;
                background-color: rgba($primary, 0.6) !important;

                &.highlight-end,
                &.highlight-start {
                    background-color: rgba($primary, 1.2) !important;
                }
            }
        }
    }
}
</style>
