<template>
    <div>
        <h2 class="title is-3 has-text-centered has-text-weight-normal mb-4">Delete Account?</h2>
        <p>
            There’ll be no going back -- we’ll permanently delete all your data and metrics. You won’t be able to access them again with Email Meter Basic. Are you sure?
        </p>
        <div class="is-flex modal-action-buttons">
            <button class="button is-danger" :class="{ 'is-loading': requestLoading }" :disabled="requestLoading" @click="deleteAccount()">Yes, Delete Account</button>
            <button class="button is-primary is-outlined" @click="closeModal()">No, Cancel</button>
        </div>
    </div>
</template>

<script>
import UserApi from '../../api/user_api';
import ModalMixin from './mixins/modal_mixin';

export default {
    name: 'DeleteAccountConfirm',
    mixins: [ModalMixin],
    data() {
        return {
            requestLoading: false
        };
    },
    methods: {
        deleteAccount() {
            UserApi.deleteAccount()
                .then(() => {
                    window.dataLayer.push({
                        event: 'newBasicDeletion'
                    });
                    window.location = `${window.location.origin}/logout`;
                })
                .catch(() => {
                    this.closeModal();
                    this.$bus.emit(this.$bus.events.notification, {
                        bgColor: 'is-danger',
                        text: 'Oops! Something went wrong. Try carrying out the action again or contact us if the problem continues.',
                        timeLapse: 0
                    });
                });
        }
    }
};
</script>
