<template>
    <div id="team-premium-paywall" class="box">
        <div class="columns">
            <div class="column has-text-centered">
                <h3 class="title is-4">Would you like to see your Team's stats?</h3>
                <p>
                    Create a team, starting at <span class="has-text-primary has-text-weight-bold">{{ pricingString(1) }}</span> for each additional Team Member.
                </p>
            </div>
        </div>
        <div class="columns is-flex is-multiline paywall-cta">
            <div class="column is-flex items-center">
                <figure class="image mx-auto paywall-illustration">
                    <img :src="illustration" width="100%" />
                </figure>
            </div>
            <div class="column">
                <div class="features mb-2">
                    <div v-for="(feature, index) in features" :key="index" class="feature">
                        <h5 class="title is-size-5 has-text-primary mb-1">{{ feature.title }}</h5>
                        <p class="has-text-grey">{{ feature.text }}</p>
                    </div>
                </div>
                <a class="button is-primary is-fullwidth is-medium mb-6 paywall-cta-button" @click="goToPage()">{{ goPremium.btnText }}</a>
            </div>
        </div>
        <faq question="What can I do with Teams?">
            <p>In a nutshell, Teams enables you to conveniently analyze the email stats of your team members. More specifically, you’ll be able to:</p>
            <ul class="ml-8">
                <li>See individual email stats for each team member.</li>
                <li>See email stats for a specific group of team members within the team.</li>
                <li>Access a team dashboard to clearly compare team members’ email stats.</li>
                <li>Grant team members access to their Premium data.</li>
            </ul>
        </faq>
        <faq question="What’s the cancelation policy?">
            Our cancelation policy is simple - you can cancel anytime you want by going to “My Account”. If you do cancel your Premium account, you’ll be able to continue using all the Premium
            features right up until the end of your last billing cycle. The expiry date of your Premium account is displayed clearly in “My Account”.
        </faq>
        <faq question="Do you have any more questions?">
            Reach us at
            <a href="mailto:hello@emailmeter.com">hello@emailmeter.com</a>, or open up a chat by clicking on the bubble in the bottom right hand corner of the screen! We’d be very happy to help.
        </faq>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import illustration from '../../../resources/static/images/illustration_premium_paywall.jpg';
import Faq from '../paywall/faq.vue';

export default {
    name: 'PaywallTeamPremium',
    components: {
        Faq
    },
    data() {
        return {
            illustration: illustration,
            features: [
                {
                    title: 'Compare statistics',
                    text: 'Directly and clearly compare at least 2 years of email stats for any Team members.'
                },
                {
                    title: 'Export to CSV',
                    text: 'Analyze your Team’s data using Excel or Google Sheets with CSV exports.'
                },
                {
                    title: 'Filter by Team member',
                    text: 'See individual email stats for each Team member.'
                }
            ],
            goPremium: {
                link: '/team/create',
                btnText: 'Create Team'
            }
        };
    },
    computed: {
        ...mapGetters('billing', ['pricingString'])
    },
    methods: {
        goToPage() {
            this.$router.push({ path: this.goPremium.link });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

ul {
    list-style: disc;
}

.paywall-cta {
    max-width: 900px;
    margin: 0 auto;

    @include mobile {
        flex-direction: column-reverse;
        .column:first-child {
            flex-basis: auto;
        }
    }

    .paywall-illustration {
        height: auto;
    }

    .features {
        .feature {
            padding-left: 2.5rem;
            position: relative;
            margin-bottom: 1.25rem;

            &::before {
                font-family: 'em-icons';
                font-size: 1.5rem;
                color: $primary;
                content: '\f129';
                position: absolute;
                left: 0;
            }
        }

        &:last-child {
            margin-bottom: 2.5rem;
        }
    }

    .payment-info {
        display: flex;
        justify-content: space-between;

        @include touch {
            flex-direction: column;
        }

        .secure-info {
            display: flex;
            margin: 0 auto;

            @include touch {
                margin-bottom: 1rem;
            }

            .secure-img {
                width: 28px;
                height: auto;
                margin-right: 0.5rem;
            }
            .secure-text {
                color: #6b7c93;
            }
        }
    }
}
</style>
