const TEAM_MEMBER_STATUS = {
    active: 'A',
    inactive: 'I',
    batchLoading: 'L',
    deleted: 'D'
};

class Team {
    constructor(data, members) {
        this.id = data.id;
        this.leader = data.leader;
        this.client = data.client;
        this.name = data.name;
        this.members = members;
        this.invitations = [];
    }

    deleteMember(id) {
        this.members = this.members.filter(m => m.id !== id);
    }

    deleteInvitation(id) {
        this.invitations = this.invitations.filter(m => m.id !== id);
    }

    setInvitations(invitations) {
        this.invitations = invitations;
    }

    updateInvitation(invitationId, status) {
        const invitationIndex = this.invitations.findIndex(i => i.id === invitationId);
        this.invitations[invitationIndex].status = status;
    }
}

class TeamMember {
    constructor(data) {
        this.id = data.id;
        this.email = data.email;
        this.fullName = data.fullName;
        this.googleUserId = data.googleUserId;
        this.status = data.status;
        this.expiresAt = data.expiresAt;
    }
}

export { TEAM_MEMBER_STATUS, Team, TeamMember };
