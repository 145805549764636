<template>
    <div id="batch-loading" :class="{ 'is-menu-visible': $route.name !== 'get-premium' }">
        <div v-if="premiumBatch" class="has-text-centered">
            <span class="tag is-secondary">{{ batchProgressMessage(premiumBatch.progress) }}</span>
            <progress class="progress is-secondary is-small" :value="premiumBatch.progress" max="100">{{ premiumBatch.progress }}</progress>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { BATCH_KINDS } from '../../models/batch';

export default {
    name: 'BatchLoading',
    computed: {
        ...mapGetters('batch', ['premiumBatch'])
    },
    mounted() {
        const user = this.$store.state.user.user;

        let activeBatch = BATCH_KINDS.premium;
        if (!user.permissions(this.$em.constants.USER_PERMISSIONS.canManageTeams)) activeBatch = BATCH_KINDS.premiumTeam;

        this.$store.dispatch('batch/pollBatchProgress', { kind: activeBatch, timelapse: 5000 }).then(() => {
            this.$store.dispatch('user/fetchUser');
        });
    },
    methods: {
        /* eslint-disable complexity */
        batchProgressMessage(progress) {
            if (progress < 1) {
                return "We're going back 2 years in the past. That's a lot of emails! Give us a few moments to get going";
            } else if (progress >= 1 && progress < 20) {
                return "Just getting started! No worries, it's in our hands";
            } else if (progress >= 20 && progress < 40) {
                return 'Processing all your received emails now';
            } else if (progress >= 40 && progress < 60) {
                return "Nearly at the halfway point. We're calculating your response time";
            } else if (progress >= 60 && progress < 80) {
                return 'Almost finished, just a couple months of emails left';
            } else if (progress >= 80 && progress < 99) {
                return 'Adding the finishing touches!';
            } else {
                return "Nice! We're just reviewing that everything is ready for you";
            }
        }
        /* eslint-enable complexity */
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';
@import '../../styles/variables';

#batch-loading {
    z-index: 15;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;

    @include desktop {
        &.is-menu-visible {
            left: $sidemenu-width;
        }
    }

    @include touch {
        &.is-menu-visible {
            bottom: 73px;
        }
    }

    .progress {
        border-radius: 0;
    }

    .tag {
        margin: 0 0.75rem 0.75rem;
        height: auto;
        white-space: normal;
    }
}
</style>
