<template>
    <div id="business-hours-body" class="column is-four-fifths-desktop mx-auto" :class="{ 'cursor-not-allowed': !businessHoursEnabled }">
        <div class="columns is-multiline is-gapless" :class="{ 'business-hours-disabled': !businessHoursEnabled }">
            <template v-if="!$store.getters.isMobile">
                <div class="column is-half mb-2">
                    <span>Work days</span>
                </div>
                <div class="column is-half mb-2">
                    <span>Work hours</span>
                </div>
            </template>
            <div v-for="(elem, index) in elements" :key="uniqueKey(elem)" class="column is-full mb-2">
                <business-hours-item
                    :working-data="elem"
                    :error="Boolean(errorRows[index]) || Boolean(duplicateRows[index])"
                    @business-hours-changed="updateBusinessHours(index, $event)"
                    @delete-item="deleteItem(index)"
                ></business-hours-item>
            </div>
            <div class="column is-full">
                <div class="columns">
                    <div class="column is-full">
                        <div
                            v-if="businessHoursEnabled"
                            class="notification is-primary-light is-flex items-center is-full p-2"
                            :class="{ 'cursor-not-allowed': !canAddElements, 'cursor-pointer': canAddElements }"
                            @click="addNewItem()"
                        >
                            <i class="em-icon add has-text-primary mx-auto"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!canAddElements" class="column is-full">
                <p class="help is-danger mx-auto mt-4">Something went wrong with your business hours selection. Double-check you didn't duplicate a time frame.</p>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import enLocale from 'dayjslib/locale/en-gb';

import BusinessHoursItem from './business_hours_item.vue';

const DEFAULT_BUSINESS_HOUR_ITEM_DATA = {
    workingDays: [false, false, false, false, false, false, false],
    workingHours: {
        fromHour: null,
        toHour: null
    }
};

export default {
    name: 'BusinessHours',
    components: {
        BusinessHoursItem
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        errorRows: {
            type: Array,
            required: true
        },
        businessHoursEnabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            elements: [],
            duplicateRows: []
        };
    },
    computed: {
        convertToInternal() {
            return this.value.map(e => {
                return {
                    workingDays: enLocale.weekdaysShort.map(day => e[0].includes(day)),
                    workingHours: {
                        fromHour: this.formatHourToInternal(e[1]),
                        toHour: this.formatHourToInternal(e[2])
                    }
                };
            });
        },
        convertToExternal() {
            return this.elements.map(elem => {
                const workingDays = elem.workingDays
                    .map((d, i) => {
                        if (d)
                            return dayjs()
                                .day(i)
                                .format('ddd');
                        return false;
                    })
                    .filter(Boolean);

                return [workingDays, this.formatHourToExternal(elem.workingHours.fromHour), this.formatHourToExternal(elem.workingHours.toHour)];
            });
        },
        elementsEditing() {
            return this.elements.map(el => el.workingDays.some(Boolean) && el.workingHours.fromHour !== null && el.workingHours.toHour !== null);
        },
        canAddElements() {
            return !(this.errorRows.some(Boolean) || this.duplicateRows.some(Boolean));
        }
    },
    watch: {
        elementsEditing: {
            immediate: true,
            handler(val) {
                this.$emit('editing', !val.every(Boolean));
            }
        }
    },
    beforeMount() {
        this.elements = this.convertToInternal;
    },
    methods: {
        uniqueKey(key) {
            return btoa(JSON.stringify(key));
        },
        addNewItem() {
            if (this.canAddElements) {
                const newItem = Object.assign({}, DEFAULT_BUSINESS_HOUR_ITEM_DATA, { randomVar: Math.random() });
                this.elements.push(newItem);
            }
        },
        deleteItem(index) {
            this.elements.splice(index, 1);
            this.duplicateRows.splice(index, 1);
            this.elements.forEach((elem, i) => {
                this.updateBusinessHours(i, elem);
            });
        },
        updateBusinessHours(index, val) {
            const newItemUniqueKey = this.uniqueKey(val);
            let error = false;
            this.elements
                .filter((e, i) => i !== index)
                .forEach((el, i) => {
                    if (newItemUniqueKey === this.uniqueKey(el)) {
                        this.$set(this.duplicateRows, i, true);
                        error = true;
                    } else {
                        this.$set(this.duplicateRows, i, false);
                    }
                });
            this.$set(this.duplicateRows, index, error);
            if (!error) {
                this.$set(this.elements, index, val);
                if (this.elementsEditing[index]) {
                    this.$emit('input', this.convertToExternal);
                }
            }
        },
        formatHourToExternal(h) {
            return `${h}:00:00`.padStart(5, '0');
        },
        formatHourToInternal(h) {
            return Number(h.split(':')[0]);
        }
    }
};
</script>

<style lang="scss" scoped>
.business-hours-disabled {
    opacity: 0.3;
    pointer-events: none;
}
</style>
