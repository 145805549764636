import TeamApi from '../api/team_api';

export default {
    namespaced: true,
    state: {
        teams: []
    },
    getters: {
        firstTeam: state => {
            if (state.teams.length > 0) {
                return state.teams[0];
            } else {
                return null;
            }
        },
        getTeamById: state => teamId => {
            const teamIndex = state.teams.findIndex(t => t.id === teamId);
            if (teamIndex !== -1) return state.teams[teamIndex];
        },
        getAllTeamMembers: state => {
            return state.teams.reduce((acc, team) => acc.concat(team.leader, team.members), []);
        }
    },
    mutations: {
        setTeams(state, teams) {
            state.teams = teams;
        },
        setInvitations(state, invitations) {
            state.teams.forEach(t => {
                if (invitations[t.id]) t.setInvitations(invitations[t.id]);
            });
        },
        updateInvitation(state, invitation) {
            const teamIndex = state.teams.findIndex(t => t.id === invitation.teamId);
            state.teams[teamIndex].updateInvitation(invitation.id, status);
        },
        deleteMember(state, { teamId, memberId }) {
            const teamIndex = state.teams.findIndex(t => t.id === teamId);
            state.teams[teamIndex].deleteMember(memberId);
        },
        deleteInvitation(state, { teamId, invitationId }) {
            const teamIndex = state.teams.findIndex(t => t.id === teamId);
            state.teams[teamIndex].deleteInvitation(invitationId);
        }
    },
    actions: {
        fetchTeams(context) {
            return TeamApi.getTeams().then(teams => {
                context.commit('setTeams', teams);
                return teams;
            });
        },
        fetchInvitations(context) {
            return TeamApi.getInvitations(TeamApi.invitationKind.sent).then(invitations => {
                context.commit('setInvitations', invitations);
            });
        },
        createInvitations(context, { teamId, emails }) {
            return TeamApi.sendInvitations(teamId, emails).then(async () => {
                await context.dispatch('user/fetchUser', null, { root: true });
                await context.dispatch('fetchInvitations');
                context.dispatch('billing/fetchCustomer', null, { root: true });
                context.dispatch('billing/fetchInvoices', null, { root: true });
            });
        },
        updateInvitation(context, { invitation, status }) {
            return TeamApi.updateReceivedInvitation(invitation.id, status).then(() => {
                context.commit('updateInvitation', {
                    invitation,
                    status
                });
            });
        },
        deleteMember(context, { teamId, memberId }) {
            return TeamApi.deleteMember(teamId, memberId).then(() => {
                context.dispatch('fetchTeams').then(() => {
                    context.dispatch('fetchInvitations');
                });
                context.dispatch('billing/fetchCustomer', null, { root: true });
                context.dispatch('billing/fetchInvoices', null, { root: true });
            });
        },
        deleteInvitation(context, { teamId, invitationId }) {
            return TeamApi.deleteInvitation(invitationId).then(() => {
                context.dispatch('billing/fetchCustomer', null, { root: true });
                context.dispatch('billing/fetchInvoices', null, { root: true });
                return context.commit('deleteInvitation', {
                    teamId,
                    invitationId
                });
            });
        }
    }
};
