import UserApi from '../api/user_api';

export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        updateConfig(state, config) {
            state.user.config = config;
        }
    },
    actions: {
        fetchUser(context) {
            return UserApi.info()
                .then(user => {
                    context.commit('setUser', user);
                    context.commit(
                        'filter/setDataSpan',
                        {
                            timestampFrom: user.firstLog,
                            timestampTo: Math.floor(new Date().valueOf() / 1000)
                        },
                        { root: true }
                    );
                    return user;
                })
                .catch(() => {
                    const nextURL = encodeURIComponent(window.location.href);
                    UserApi.redirectTo(`${process.env.ENDPOINT_BACKEND}/login/basic?next=${nextURL}`);

                    return Promise.reject();
                });
        },
        updateUserConfig(context, configData) {
            return UserApi.updateUserConfig(configData).then(config => {
                context.commit('updateConfig', config);
            });
        }
    }
};
