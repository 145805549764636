import { INVITATION_STATUS } from '../models/invitation';
import ApiBase from './api_base';
import InvitationParser from './parsers/invitation_parser';
import TeamParser from './parsers/team_parser';

const TEAM_API_PREFIX = '/api/teams/';

export default class TeamApi extends ApiBase {
    static get invitationKind() {
        return {
            sent: '',
            received: '/received'
        };
    }

    static getTeams() {
        return this.fetchData(TEAM_API_PREFIX).then(data => {
            return TeamParser.parseTeams(data);
        });
    }

    static getInvitations(kindPath) {
        return this.fetchData(`${TEAM_API_PREFIX}invitations${kindPath}/`).then(data => {
            return InvitationParser.parseTeamInvitations(data);
        });
    }

    static acceptInvitation(invitationId) {
        return this._updateReceivedInvitation(invitationId, INVITATION_STATUS.accepted);
    }

    static rejectInvitation(invitationId) {
        return this._updateReceivedInvitation(invitationId, INVITATION_STATUS.rejected);
    }

    static deleteMember(teamId, memberId) {
        return this.postData(`${TEAM_API_PREFIX}${teamId}/members/${memberId}/`, {}, 'DELETE');
    }

    static deleteInvitation(invitationId) {
        return this.postData(`${TEAM_API_PREFIX}invitations/${invitationId}/`, {}, 'DELETE');
    }

    static resendInvitation(invitationId) {
        return this.postData(`${TEAM_API_PREFIX}invitations/${invitationId}/resend/`, {});
    }

    static sendInvitations(teamId, emails) {
        return this.postData(`${TEAM_API_PREFIX}invitations/bulk/`, {
            team: teamId,
            emails: emails
        }).then(data => {
            return InvitationParser.parseTeamInvitations(data);
        });
    }

    static canInviteEmail(email) {
        return this.fetchData(`${TEAM_API_PREFIX}invitations/can-invite/?email=${email}`);
    }

    static _updateReceivedInvitation(invitationId, status) {
        return this.postData(`${TEAM_API_PREFIX}invitations/received/${invitationId}/`, { status: status }, 'PATCH');
    }
}
