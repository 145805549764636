<template>
    <div v-if="display" class="notification shadow" :class="bgColor">
        <button class="delete" @click="display = false"></button>
        <span v-if="title" class="has-text-weight-bold">{{ title }}</span>
        <p class="mb-0">{{ text }}</p>
        <a v-if="buttonText" class="button is-transparent has-text-white is-paddingless is-pulled-right underline" @click="buttonFnc">{{ buttonText }}</a>
    </div>
</template>

<script>
export default {
    name: 'Notification',
    data() {
        return {
            display: false,
            bgColor: 'is-primary',
            text: '',
            title: null,
            buttonText: null,
            buttonFnc: null
        };
    },
    beforeMount() {
        this.$bus.on(this.$bus.events.notification, this.onNotification);
    },
    beforeDestroy() {
        this.$bus.off(this.$bus.events.notification, this.onNotification);
    },
    methods: {
        onNotification(data) {
            this.text = data.text;
            this.title = data.title;
            this.bgColor = data.bgColor || this.bgColor;
            this.buttonText = data.buttonText || null;
            this.buttonFnc = data.buttonFnc || null;
            const autoHide = data.timeLapse !== undefined ? data.timeLapse : 5000;
            this.display = true;
            if (autoHide > 0) {
                setTimeout(() => {
                    this.display = false;
                }, autoHide);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.notification {
    position: fixed;
    top: calc(#{$navbar-height} + 1rem);
    right: 1rem;
    z-index: 100;
    color: white;
    max-width: 600px;
    min-width: 200px;
    padding: 24px 40px 24px 24px;
}
</style>
