import Filter from './filter';

export default class UsersFilter extends Filter {
    constructor(users = []) {
        super(users);
    }

    parse(value) {
        super.parse(value);
        if (!Array.isArray(this.value)) this.value = [this.value];
    }

    static get id() {
        return 'user_ids';
    }
}
