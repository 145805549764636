<template>
    <div class="is-flex flex-1 flex-col">
        <settings-navigation></settings-navigation>
        <section id="settings" class="section">
            <router-view></router-view>
        </section>
    </div>
</template>

<script>
import SettingsNavigation from '../components/settings/settings_navigation.vue';

export default {
    name: 'Settings',
    components: {
        SettingsNavigation
    }
};
</script>
