<template>
    <div id="referral-tutorial" class="column is-full">
        <div class="box">
            <section class="section">
                <div class="columns is-multiline">
                    <div class="column is-full has-text-centered">
                        <h3 class="title is-3 is-size-4-mobile">How to get your free Premium</h3>
                        <p class="mb-4">Follow the 2 simple paths below to get Premium for free. All you need to do is help your friends discover Email Meter!</p>
                    </div>
                    <div class="column is-full">
                        <div id="graph" class="columns">
                            <div class="column first-column is-flex flex-col justify-center items-center">
                                <img :class="{ 'w-full': !isMobile }" src="../../../resources/static/images/illustration_referral_tutorial_people.svg" />
                                <h2 class="has-text-weight-bold is-size-6 has-text-centered pt-3">Invite your friends to Email Meter</h2>
                                <p class="is-size-8 pt-3 pl-2">Invite as many friends as you like. You can send your referral link or share it manually.</p>
                            </div>

                            <div class="column arrow-column is-flex justify-center p-0" :class="{ 'flex-col': !isMobile }">
                                <div class="flex-1 is-flex justify-center p-0" :class="{ 'mb-6': !isMobile }">
                                    <img v-if="isMobile" src="../../../resources/static/images/illustration_referral_features_arrow_diag_bl.svg" />
                                    <img v-else src="../../../resources/static/images/illustration_referral_features_arrow_diag_tr.svg" />
                                </div>
                                <div class="flex-1 is-flex justify-center p-0" :class="{ 'mt-6': !isMobile }">
                                    <img src="../../../resources/static/images/illustration_referral_features_arrow_diag_br.svg" />
                                </div>
                            </div>

                            <div class="column content-column columns justify-center">
                                <div class="column is-narrow is-flex" :class="{ 'flex-col': !isMobile }">
                                    <img class="flex-1" :class="{ 'mb-6': !isMobile }" src="../../../resources/static/images/illustration_referral_features_hand.svg" />
                                    <img class="flex-1" :class="{ 'mt-6': !isMobile }" src="../../../resources/static/images/illustration_referral_features_cards.svg" />
                                </div>
                                <div class="column image-footer is-flex" :class="{ 'flex-col': !isMobile }">
                                    <div class="flex-1 is-flex flex-col pl-6 justify-center" :class="{ 'mb-6': !isMobile }">
                                        <h2 class="has-text-weight-bold is-size-6">Ask them to sign up</h2>
                                        <p class="is-size-8 pt-3">You need at least 5 of them to sign up following your referral link.</p>
                                    </div>

                                    <div class="flex-1 flex-col pl-6 justify-center" :class="{ 'mt-6': !isMobile }">
                                        <h2 class="has-text-weight-bold is-size-6">A referral buys Premium</h2>
                                        <p class="is-size-8 pt-3">You only need 1 friend to buy Premium.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="column arrow-column is-flex justify-center p-0" :class="{ 'flex-col': !isMobile }">
                                <div class="flex-1 is-flex justify-center p-0" :class="{ 'mb-6': !isMobile }">
                                    <img v-if="isMobile" class="column is-narrow" src="../../../resources/static/images/illustration_referral_features_arrow_bottom.svg" />
                                    <img v-else src="../../../resources/static/images/illustration_referral_features_arrow_right.svg" />
                                </div>
                                <div class="flex-1 is-flex justify-center p-0" :class="{ 'mt-6': !isMobile }">
                                    <img v-if="isMobile" class="column is-narrow" src="../../../resources/static/images/illustration_referral_features_arrow_bottom.svg" />
                                    <img v-else src="../../../resources/static/images/illustration_referral_features_arrow_right.svg" />
                                </div>
                            </div>

                            <div class="column content-column columns justify-center">
                                <div class="column is-narrow is-flex" :class="{ 'flex-col': !isMobile }">
                                    <img class="flex-1" :class="{ 'mb-6': !isMobile }" src="../../../resources/static/images/illustration_referral_features_stars.svg" />
                                    <img class="flex-1" :class="{ 'mt-6': !isMobile }" src="../../../resources/static/images/illustration_referral_features_star.svg" />
                                </div>
                                <div class="column image-footer is-flex" :class="{ 'flex-col': !isMobile }">
                                    <div class="flex-1 is-flex flex-col pl-6 justify-center" :class="{ 'mb-6': !isMobile }">
                                        <h2 class="has-text-weight-bold is-size-6">Enjoy your free Premium</h2>
                                        <p class="is-size-8 pt-3">We'll give you 1 free month for each 5 friends that sign up using your referral link or invite.</p>
                                    </div>

                                    <div class="flex-1 flex-col pl-6 justify-center" :class="{ 'mt-6': !isMobile }">
                                        <h2 class="has-text-weight-bold is-size-6">Enjoy an extra free month of Premium</h2>
                                        <p class="is-size-8 pt-3">There's no limit. We'll give you a free month for each of your referrals that buys Premium.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ReferralTutorial',
    computed: {
        ...mapGetters(['isMobile'])
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins';

#graph {
    * {
        margin: 0;
        padding: 0;
    }

    h2,
    p {
        line-height: 1.125rem;
    }
}

.first-column {
    flex: 5;
}

.content-column {
    flex: 7;
}

.arrow-column {
    flex: 4;
}

@include mobile {
    .arrow-column {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .image-footer {
        margin-top: 1.5rem !important;
    }
}
</style>
