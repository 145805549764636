<template>
    <section v-if="shouldSeePage" id="account" class="section">
        <my-account></my-account>
        <invoices></invoices>
    </section>
</template>

<script>
import Invoices from '../components/account/invoices.vue';
import MyAccount from '../components/account/my_account.vue';

function handleUrlChange(vm, from, next) {
    const user = vm.$store.state.user.user;

    if (!user.permissions(vm.$em.constants.USER_PERMISSIONS.canMyAccountBilling)) {
        next('/report');
    }

    vm.shouldSeePage = true;
}

export default {
    components: {
        MyAccount,
        Invoices
    },
    data() {
        return {
            shouldSeePage: false
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            handleUrlChange(vm, from, next);
        });
    },
    beforeRouteUpdate(to, from, next) {
        handleUrlChange(this, from, next);
        next();
    }
};
</script>
