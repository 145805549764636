export default {
    props: {
        modalData: {
            type: Object,
            required: true
        }
    },
    methods: {
        closeModal() {
            this.$bus.emit(this.$bus.events.closeModal);
        }
    }
};
