<template>
    <div id="cart" :class="{ 'box': isMobileOrTablet }">
        <p class="has-text-weight-bold mb-6">Summary</p>
        <p class="has-text-grey-darker">
            {{ product }}
            <span v-if="productName !== 'Team'" class="is-pulled-right">${{ subtotal }}</span>
        </p>
        <p v-if="productName === 'Team'" class="has-text-grey-darker ml-4">
            Team Manager
            <span class="is-pulled-right">${{ subtotal }}</span>
        </p>
        <p v-if="emails" class="has-text-grey-darker ml-4">
            Team Members &times;{{ emails }}
            <span class="is-pulled-right">${{ subtotal * emails }}</span>
        </p>
        <div v-if="coupon.id" id="discount-code-notification" class="notification is-green has-text-white p-2 mb-2 is-flex items-center">
            <span class="icon">
                <i class="em-icon star-filled is-size-9"></i>
            </span>
            <span>{{ coupon.name }}</span>
            <span class="ml-auto">-${{ couponDiscount | toFixed }}</span>
        </div>
        <div class="notification is-success has-text-success p-2 mb-2">
            <p v-if="billingPlan === STRIPE_PLANS.yearlyJune2019.id">
                Yearly payments –
                <b>save 21%</b>
                <span class="is-pulled-right has-text-success">-${{ savings }}</span>
            </p>
            <p v-else>
                Do you want to
                <b>save 21%</b>?
            </p>
        </div>
        <a
            v-if="billingPlan === STRIPE_PLANS.yearlyJune2019.id"
            class="button is-transparent underline has-text-success is-paddingless is-small mr-auto"
            @click="billingPlan = STRIPE_PLANS.monthlyJune2019.id"
            >Switch to monthly payments</a
        >
        <a v-else class="button is-transparent underline has-text-success is-paddingless is-small mr-auto" @click="billingPlan = STRIPE_PLANS.yearlyJune2019.id">Switch to yearly payments</a>
        <hr />
        <p class="has-text-primary has-text-weight-bold is-size-5">
            <span class="is-capitalized">{{ STRIPE_PLANS[billingPlan].adj }}</span> payment
            <span class="is-pulled-right">${{ (total - couponDiscount) | toFixed }}</span>
        </p>
        <p v-if="coupon.id && coupon.duration !== 'forever'" class="has-text-grey-darker is-size-8 is-italic">
            <template v-if="billingPlan === STRIPE_PLANS.yearlyJune2019.id">
                After your first year, your will be billed
                <b>${{ total | toFixed }}</b> per year.
            </template>
            <template v-else>
                After {{ coupon.durationInMonths }} {{ 'month' | pluralize(coupon.durationInMonths) }}, you will be billed <b>${{ total | toFixed }}</b> per month.
            </template>
        </p>
        <div v-if="isMobileOrTablet" class="field">
            <div class="control">
                <router-link to="/get-premium/pay" tag="button" class="button is-primary is-fullwidth">Continue</router-link>
            </div>
        </div>
        <div class="has-text-grey-darker is-size-8 is-italic mt-auto">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="extraInfo"></p>
            <p>
                Your subscription will be renewed every {{ STRIPE_PLANS[billingPlan].noun }} unless you cancel first. There is no commitment. Please get in touch with us if you find Premium isn’t for
                you.
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { STRIPE_PLANS, STRIPE_PRICING } from '../../models/billing';

export default {
    name: 'CheckoutCart',
    props: {
        productName: {
            type: String,
            required: true
        },
        emails: {
            type: Number,
            required: true
        },
        coupon: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            billingPlan: STRIPE_PLANS.yearlyJune2019.id,
            STRIPE_PRICING,
            STRIPE_PLANS
        };
    },
    computed: {
        ...mapGetters(['isMobileOrTablet']),
        product() {
            let product = `Email Meter ${this.productName}`;
            if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id) {
                product = `${product} – 1 year`;
            } else {
                product = `${product} – 1 month`;
            }
            return product;
        },
        monthsMultiplier() {
            if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id) return 12;
            return 1;
        },
        subtotal() {
            let subtotal = STRIPE_PRICING.monthlyJune2019;
            if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id) {
                subtotal = subtotal * this.monthsMultiplier;
            }
            return subtotal;
        },
        savings() {
            return (STRIPE_PRICING.monthlyJune2019 - STRIPE_PRICING.yearlyJune2019) * (this.emails + 1) * 12;
        },
        total() {
            if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id) {
                return this.subtotal * (this.emails + 1) - this.savings;
            } else {
                return this.subtotal * (this.emails + 1);
            }
        },
        couponDiscount() {
            if (this.coupon.percentOff) {
                let individualPercentOff = (this.coupon.percentOff / 100) * STRIPE_PRICING[this.billingPlan];
                if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id && this.coupon.durationInMonths) {
                    individualPercentOff = individualPercentOff * this.coupon.durationInMonths;
                }
                if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id && this.coupon.duration === 'forever') {
                    individualPercentOff = individualPercentOff * 12;
                }
                return individualPercentOff * (this.emails + 1);
            } else return 0;
        },
        extraInfo() {
            if (this.emails) {
                if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id) {
                    return `Email Meter Premium is priced at <b class="has-text-primary">$${STRIPE_PRICING[this.billingPlan]} per team member per month</b> with a yearly payment.`;
                } else {
                    return (
                        `Email Meter Premium is priced at <b class="has-text-primary">$${STRIPE_PRICING[this.billingPlan]} per team member per month</b> with a monthly payment. ` +
                        '<b>Save 21% each month by switching to a yearly payment!</b>'
                    );
                }
            } else {
                /* eslint-disable no-lonely-if */
                if (this.billingPlan === STRIPE_PLANS.yearlyJune2019.id) {
                    return `Email Meter Premium is priced at <b class="has-text-primary">$${STRIPE_PRICING[this.billingPlan]} per month</b> with a yearly payment.`;
                } else {
                    return (
                        `Email Meter Premium is priced at <b class="has-text-primary">$${STRIPE_PRICING[this.billingPlan]} per month</b> with a monthly payment. ` +
                        '<b>Save 21% each month by switching to a yearly payment!</b>'
                    );
                }
                /* eslint-enable no-lonely-if */
            }
        }
    },
    watch: {
        billingPlan: {
            immediate: true,
            handler(val) {
                this.$emit('billing-kind', STRIPE_PLANS[val]);
            }
        }
    },
    mounted() {
        this.$emit('billing-kind', STRIPE_PLANS[this.billingPlan]);
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins';

#cart {
    @include desktop {
        position: fixed;
        top: $navbar-height;
        right: 0;
        bottom: 0;
        width: 500px;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: $white;
        padding: 3rem;
    }
}

.notification {
    &.is-success {
        background-color: rgba($success, 0.1);
    }
}

.em-icon.star-filled {
    color: #ffe947;
}
</style>
