const INVITATION_STATUS = {
    accepted: 'A',
    rejected: 'R',
    pending: 'P'
};

class Invitation {
    constructor(data) {
        this.id = data.id;
        this.kind = data.kind;
        this.inviterEmail = data.inviterEmail;
        this.inviterName = data.inviterName;
        this.sentTo = data.sentTo;
        this.status = data.status;
    }
}

class TeamInvitation extends Invitation {
    constructor(data) {
        super(data);
        this.teamId = data.teamId;
    }
}

export { INVITATION_STATUS, Invitation, TeamInvitation };
