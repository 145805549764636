import MetricType from '../../../models/metric_type';

export default {
    [MetricType.REPORT.totalEmailsSent]: MetricType.REPORT.totalEmailsSent,
    [MetricType.REPORT.totalUniqueRecipients]: MetricType.REPORT.totalUniqueRecipients,
    [MetricType.REPORT.averageResponseTime]: MetricType.REPORT.averageResponseTime,
    [MetricType.REPORT.totalEmailsReceived]: MetricType.REPORT.totalEmailsReceived,
    [MetricType.REPORT.totalUniqueSenders]: MetricType.REPORT.totalUniqueSenders,
    [MetricType.REPORT.sentHeatmap]: MetricType.REPORT.sentHeatmap,
    [MetricType.REPORT.receivedHeatmap]: MetricType.REPORT.receivedHeatmap,
    [MetricType.REPORT.sentHistogram]: MetricType.REPORT.sentHistogram,
    [MetricType.REPORT.receivedHistogram]: MetricType.REPORT.receivedHistogram,
    [MetricType.REPORT.timeBeforeFirstResponse]: MetricType.REPORT.timeBeforeFirstResponse,
    [MetricType.REPORT.minReplyTime]: MetricType.REPORT.minReplyTime,
    [MetricType.REPORT.averageFirstResponseTime]: MetricType.REPORT.averageFirstResponseTime,
    emailsReplied: 'emailsReplied',
    internalExternal: 'internalExternal',
    threads: 'threads',
    directMessages: 'directMessages',
    [MetricType.REPORT.topInteractions]: MetricType.REPORT.topInteractions,
    [MetricType.REPORT.topInteractionsByDomain]: MetricType.REPORT.topInteractionsByDomain
};
