import { BatchData } from '../../models/batch';

export default class BatchParser {
    static parseBatchData(data) {
        return new BatchData({
            id: data.id,
            kind: data.kind,
            progress: data.progress,
            days: data.days,
            startDate: data.start_date,
            endDate: data.end_date,
            total: data.total,
            status: data.status
        });
    }
}
