import dayjs from 'dayjs';

import { PERIOD_SPAN_KINDS } from '../constants/em';
import DateFilter from '../models/filters/date_filter';
import Log from '../utils/log';

function parseTime(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;

    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;

    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: Math.floor(seconds)
    };
}

function formatTime(time) {
    const timeStr = String(time);
    const zeroString = '00';

    return zeroString.substring(timeStr.length, 2) + timeStr;
}

/* eslint-disable complexity */
function formatDate(seconds) {
    const parsedDate = this.parseTime(seconds);
    let formattedDate = '';
    let counter = 0;

    if (parsedDate.days) {
        formattedDate = formattedDate.concat(`${parsedDate.days}d `);
        counter += 1;
    }

    if (parsedDate.hours) {
        formattedDate = formattedDate.concat(`${parsedDate.hours}h `);
        counter += 1;
    }

    if (parsedDate.minutes && counter < 2) {
        formattedDate = formattedDate.concat(`${parsedDate.minutes}m `).padStart(2, '0');
        counter += 1;
    }

    if (parsedDate.seconds !== undefined && counter < 2) {
        formattedDate = formattedDate.concat(`${Math.floor(parsedDate.seconds)}s`).padStart(2, '0');
    }

    return formattedDate.trim();
}
/* eslint-enable complexity */

function getPeriodSpan(date, periodName) {
    const tsFrom = dayjs(date)
        .utc()
        .startOf(periodName)
        .unix();
    const tsTo = dayjs(date)
        .utc()
        .endOf(periodName)
        .unix();
    return new DateFilter(tsFrom, tsTo);
}

function getLastPeriod(timestamp, periodName) {
    const preSelectedPeriod = dayjs.unix(timestamp).utc();
    if (preSelectedPeriod < preSelectedPeriod.clone().endOf(periodName)) {
        return preSelectedPeriod.subtract(1, periodName);
    } else {
        return preSelectedPeriod;
    }
}

/* eslint-disable complexity */
function getSpanKind(timestampFrom, timestampTo) {
    if (!timestampFrom && !timestampTo) {
        return PERIOD_SPAN_KINDS.custom;
    }

    const dateFrom = dayjs.unix(timestampFrom).utc();
    const dateTo = dayjs.unix(timestampTo).utc();

    if (dateFrom.isSame(dateFrom.clone().startOf('month'), 'second') && dateTo.isSame(dateFrom.clone().endOf('month'), 'second')) {
        return PERIOD_SPAN_KINDS.monthly;
    } else if (dateFrom.isSame(dateFrom.clone().startOf('week'), 'second') && dateTo.isSame(dateFrom.clone().endOf('week'), 'second')) {
        return PERIOD_SPAN_KINDS.weekly;
    }

    return PERIOD_SPAN_KINDS.custom;
}
/* eslint-enable complexity */

function getIntervalFromHour(hour) {
    const hourFrom = dayjs()
        .hour(hour)
        .minute(0)
        .format('h:mm A');
    const hourTo = dayjs()
        .hour(hour + 1)
        .minute(0)
        .format('h:mm A');
    return `${hourFrom} – ${hourTo}`;
}

function getBrowserTimezone() {
    let timezone = 'UTC';
    try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // eslint-disable-line
    } catch (err) {
        Log.warn('Error on getBrowserTimezone, using UTC', err);
    }
    return timezone;
}

export default {
    parseTime,
    formatTime,
    formatDate,
    getPeriodSpan,
    getLastPeriod,
    getSpanKind,
    getIntervalFromHour,
    getBrowserTimezone
};
